import React, { useMemo } from "react";
import { Button, Dropdown, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { PersonCircle, PlusCircle } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useContact } from "../../context/ContactContext";
import { useSearchAndFilterBox } from "../../context/SearchAndFilterContext";
import { SEARCH_BOX_VISIBLE_PATH } from "../../helpers/constants";
import {
  NEW_CONTACT_DEFAULT_FORM_VALUES,
  getUserFirstName,
} from "../../helpers/global";
import { isRtl } from "../../helpers/localization";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import LanguagePicker from "./LanguagePicker";
import Logo from "./Logo";
import SearchBox from "./searchbox";
import RujumAppIconBlue from "../../assets/RujumAppIconBlue.png";
import { startCase } from "lodash";
import {
  PATH_PERMITTED_PERMISSIONS,
  PERMISSIONS,
} from "../../helpers/permissions";

const allRoutes = [
  { title: "whatsapp_logs", route: "/whatsapp-logs" },
  { title: "logs", route: "/logs" },
  { title: "products", route: "/products" },
  { title: "appConfig", route: "/app-configuration" },
  { title: "users", route: "/users" },
  { title: "contacts", route: "/contacts" },
  { title: "automations", route: "/automation-events" },
  { title: "deals", route: "/deals" },
  { title: "commissions", route: "/commissions" },
  { title: "referrers", route: "/referrers" },
  { title: "dashboard", route: "/dashboard" },
  { title: "meetings", route: "/meetings" },
];

const LinkItem = ({ dropdown = false, title, path }) => {
  const location = useLocation();

  return dropdown ? (
    <NavDropdown.Item
      style={{ color: `${location.pathname}` === path ? "white" : "black" }}
      to={path}
      as={Link}
      active={
        `${location.pathname}` === path || `${location.pathname}/` === path
      }
    >
      {startCase(title)}
    </NavDropdown.Item>
  ) : (
    <Nav.Link
      to={path}
      as={Link}
      style={{ fontSize: 13 }}
      active={
        `${location.pathname}` === path || `${location.pathname}/` === path
      }
    >
      {startCase(title)}
    </Nav.Link>
  );
};

const NavRoutes = () => {
  const { checkNestedPermission } = useAuth();

  return allRoutes
    .filter(({ route }) => {
      //only show routes which are permitted
      const pathRolesAndPermissions = PATH_PERMITTED_PERMISSIONS[route];
      return checkNestedPermission(pathRolesAndPermissions?.permissions);
    })
    .map(({ route, title }) => {
      return <LinkItem path={route} title={title} />;
    });
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAddContactMeta } = useContact();
  const { screenWidth } = useScreenWidth();
  const { user, viewAsUserMode, parentUser, logout, checkNestedPermission } =
    useAuth();
  const { translate, langCode, isRTL } = useLocalization();
  const { isSearchBoxDisabled } = useSearchAndFilterBox();

  const userName = useMemo(
    () => getUserFirstName(parentUser?.name),
    [parentUser]
  );
  const logoutLocal = () => {
    logout();
    navigate("/auth/login");
  };

  return (
    <>
      <Navbar className="px-3" bg="white" expand="lg" sticky="top">
        <Navbar.Brand href="/">
          <Logo noText small dark />
        </Navbar.Brand>
        <LanguagePicker />
        {isRTL && (
          <>
            {screenWidth >= 992 && (
              <>
                {location.pathname === "/contacts" &&
                  checkNestedPermission([
                    PERMISSIONS.contacts.CREATE_ALL_CONTACTS,
                  ]) && (
                    <Button
                      variant={"success"}
                      className={`mx-0 px-2 py-1 text-white`}
                      disabled={isSearchBoxDisabled}
                      size="sm"
                      onClick={() =>
                        setAddContactMeta({
                          contact: NEW_CONTACT_DEFAULT_FORM_VALUES(user),
                        })
                      }
                    >
                      {<PlusCircle className="me-1" />}
                      <span className="smallFont mb-0">{translate("new")}</span>
                    </Button>
                  )}
                {SEARCH_BOX_VISIBLE_PATH.includes(location.pathname) && (
                  <SearchBox
                    containerClass="bg-white rounded me-2"
                    disabled={isSearchBoxDisabled}
                  />
                )}
              </>
            )}
          </>
        )}

        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-3 me-auto" style={{ fontSize: 14 }}>
              <NavRoutes location={location} translate={translate} />
            </Nav>

            <div className="d-flex align-items-center justify-content-end">
              {!isRTL && (
                <>
                  {screenWidth >= 992 && (
                    <>
                      {SEARCH_BOX_VISIBLE_PATH.includes(location.pathname) &&
                        checkNestedPermission(
                          PATH_PERMITTED_PERMISSIONS[location.pathname]
                            ?.permissions
                        ) && (
                          <SearchBox
                            containerClass="bg-white rounded me-2"
                            disabled={isSearchBoxDisabled}
                          />
                        )}
                      {location.pathname === "/contacts" &&
                        checkNestedPermission([
                          PERMISSIONS.contacts.CREATE_ALL_CONTACTS,
                        ]) && (
                          <Button
                            variant={"success"}
                            className={`mx-0 px-2 py-1 text-white`}
                            disabled={isSearchBoxDisabled}
                            size="sm"
                            onClick={() =>
                              setAddContactMeta({
                                contact: NEW_CONTACT_DEFAULT_FORM_VALUES(user),
                              })
                            }
                          >
                            {<PlusCircle className="me-1" />}
                            <span className="smallFont mb-0">
                              {translate("new")}
                            </span>
                          </Button>
                        )}
                    </>
                  )}
                </>
              )}
              {viewAsUserMode && (
                <Button className="mx-2" variant="outline-dark" size="sm">
                  <span className="smallFont mb-0">
                    Viewing as {user?.name}
                  </span>
                </Button>
              )}{" "}
              <a
                className="mx-1"
                size="sm"
                href={"https://app.ethica.finance/"}
                target="_blank"
              >
                <img
                  alt="App"
                  style={{ borderRadius: 5 }}
                  height={30}
                  width={30}
                  src={RujumAppIconBlue}
                />
              </a>
              <Dropdown
                className={`d-none d-md-inline-block ${
                  isRtl(langCode) ? "me-1" : "ms-1"
                }`}
                style={{ zIndex: 10 }}
                dir="rtl"
                drop={isRtl(langCode) ? "end" : "start"}
              >
                <Dropdown.Toggle size="sm" variant="outline-dark ">
                  <PersonCircle size={18} className="ms-1 align-text-top" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="smallFont"
                    onClick={() => navigate("/profile")}
                  >
                    {translate("view_profile")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="smallFont"
                    onClick={() => logoutLocal(navigate)}
                  >
                    {translate("logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                className="d-inline-block d-md-none ms-3 mt-1"
                style={{ zIndex: 10 }}
                dir="rtl"
                drop={isRtl(langCode) ? "end" : "start"}
              >
                <Dropdown.Toggle size="sm" variant="outline-dark ">
                  <PersonCircle size={18} className="ms-2 align-text-top" />
                  <span className="mb-0 smallFont">{userName}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="border-bottom smallFont"
                    as={Link}
                    to={"https://ethica.finance/"}
                  >
                    {translate("ethica_finance")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="smallFont"
                    onClick={() => navigate.push("/profile")}
                  >
                    {translate("view_profile")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="smallFont"
                    onClick={() => logoutLocal(navigate)}
                  >
                    {translate("logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </>
      </Navbar>
      <div
        style={{
          height: 1,
        }}
        className="bg-dark"
      ></div>
    </>
  );
};

export default Header;
