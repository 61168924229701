import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAppointmetnDefaultTypeColumns } from "../../../helpers/dataSheetConstants";
import useLocalization from "../../../hooks/useLocalization";
import { appConfigurationService } from "../../../services/appConfigurationService";
import DataTable from "../../common/data-table/DataTable";
import EditAppointmentModal from "./EditAppointmentModal";
import { updateItemsInArray } from "../../../helpers/global";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const AppointmentDefaultType = ({
  className = "",
  appointmentDefaultTypes,
  setAppointmentDefaultTypes,
}) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  const [editAppointmentTypeMeta, setEditAppointmentTypeMeta] = useState(null);

  const onEditAppointmentTypeClick = (appointmentType) => {
    setEditAppointmentTypeMeta({ initialValues: appointmentType });
  };

  const tableColumns = useMemo(
    () =>
      getAppointmetnDefaultTypeColumns({
        onEditAppointmentTypeClick,
        allowEdit: checkNestedPermission([PERMISSIONS.appointment_defaults.UPDATE_ALL_APPOINTMENT_DEFAULTS]),
      }),
    [onEditAppointmentTypeClick]
  );

  const onAppointDefaultTypeSubmit = async (formData) => {
    setEditAppointmentTypeMeta({
      ...editAppointmentTypeMeta,
      showProgress: true,
    });
    try {
      const { response, error } =
        await appConfigurationService.updateAppointmentDefaultType(formData);
      if (error) {
        return toast.error(error);
      }
      setAppointmentDefaultTypes((prevAppointment) => {
        const updatedAppointments = updateItemsInArray(prevAppointment, [
          response,
        ]);
        return updatedAppointments;
      });
      setEditAppointmentTypeMeta(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card className={className}>
      <Card.Header>{translate("appointment_defaults")}</Card.Header>
      <Card.Body>
        <DataTable
          rowKey={"_id"}
          columns={tableColumns}
          data={appointmentDefaultTypes}
          loadingFirstPageData={false}
          allowFilter={false}
          allowSort={false}
        />
        <EditAppointmentModal
          show={Boolean(editAppointmentTypeMeta)}
          initialValues={editAppointmentTypeMeta?.initialValues}
          editMode={editAppointmentTypeMeta?.editMode}
          onHide={() => setEditAppointmentTypeMeta(null)}
          showProgress={editAppointmentTypeMeta?.showProgress}
          onSubmit={onAppointDefaultTypeSubmit}
        />
      </Card.Body>
    </Card>
  );
};

export default AppointmentDefaultType;
