import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import {
  Button,
  Col,
  FormCheck,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import CustomMultiSelect from "../common/CustomMultiSelect";
import { CAMPAIGN_TRIGGER_OPTIONS } from "../../helpers/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DEFAULT_DATE_TIME_FORMAT_NON_MOMENT,
  INBOUNDS_TO_SHOW,
  WHATSAPP_SOURCE_AUTOMATIONS,
  WHATSAPP_SOURCE_EVENTS,
  WHATSAPP_SOURCE_INBOUNDS,
} from "../../helpers/constants";
import WhatsappTemplateDropdownOption from "../common/WhatsappTemplateDropdownOption";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";
import { snakeCase } from "lodash";

const validationSchema = (editableSource) =>
  Yup.object().shape({
    ...(editableSource
      ? {
          sourceType: Yup.string().required("Source is required"),
          sourceId: Yup.string().required("Source Id is required"),
          exceptionId: Yup.string().nullable(),
        }
      : {}),
    template: Yup.string().required("Template is required"),
    trigger: Yup.string().required("Trigger is required"),
    dateTime: Yup.date()
      .nullable()
      .test(
        "is-required-when-date_time",
        "DateTime is required for 'date_time' trigger",
        function (value) {
          const { trigger } = this.parent;
          if (trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME) {
            return Boolean(value); // Only valid if dateTime is provided when trigger is 'date_time'
          }
          return true; // If trigger is not 'date_time', no need to validate dateTime
        }
      )
      .test(
        "is-not-in-past",
        "DateTime cannot be in the past",
        function (value) {
          const { trigger } = this.parent;
          if (trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME && value) {
            return new Date(value) >= new Date(); // Ensure dateTime is not in the past
          }
          return true; // If trigger is not 'date_time', no need to validate for past dates
        }
      ),
  });

const SendWhatsappCampaignForm = ({
  initialValues = {},
  showProgress,
  onSubmit,
  whatsappTemplates,
  submitButtonText,
  allowOnSignUp,
  editableSource = false,
  events = [],
  automations = [],
  inboundSource = [],
}) => {
  const { translate, isRTL } = useLocalization();
  const { facebookCampaigns } = useActiveTrailAndFacebookCampaign();

  const value = useMemo(
    () => ({
      template: "",
      trigger: CAMPAIGN_TRIGGER_OPTIONS.SEND_NOW,
      dateTime: null,
      ...(editableSource ? { sourceType: "" } : {}),
      ...initialValues,
    }),
    [initialValues, editableSource]
  );

  const getSourceIdOptions = useCallback(
    (source) => {
      if (source === WHATSAPP_SOURCE_EVENTS)
        return events.map((e) => ({ label: e.name, value: e._id }));
      else if (source === WHATSAPP_SOURCE_AUTOMATIONS)
        return automations.map((e) => ({ label: e.name, value: e._id }));
      else if (source === WHATSAPP_SOURCE_INBOUNDS)
        return inboundSource
          .filter((ds) => INBOUNDS_TO_SHOW.includes(ds.key))
          .map((e) => ({ label: e.key, value: e._id }));
      else return [];
    },
    [events, automations, inboundSource]
  );

  const getExceptions = useCallback(
    (sourceId) => {
      const inbound = inboundSource.find((i) => i._id === sourceId);
      return inbound
        ? inbound.exceptions.map((e) => ({
            label:
              facebookCampaigns.find((c) => c.id == e.value)?.name || e.value,
            value: e._id,
          }))
        : [];
    },
    [inboundSource]
  );

  return (
    <Formik
      initialValues={value}
      validationSchema={() => validationSchema(editableSource)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        const { sourceType, sourceId, exceptionId, ...allSchedules } = values;
        onSubmit({
          sourceInfo: { sourceType, sourceId, exceptionId },
          allSchedules,
        });
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form noValidate className="">
            <Row className="m-0">
              {editableSource && (
                <>
                  <Col xs={4} md={4}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("source")}
                      </FormLabel>
                      <CustomMultiSelect
                        items={[
                          WHATSAPP_SOURCE_EVENTS,
                          WHATSAPP_SOURCE_AUTOMATIONS,
                          WHATSAPP_SOURCE_INBOUNDS,
                        ].map((s) => ({
                          label: translate(snakeCase(s)),
                          value: s,
                        }))}
                        closeMenuOnSelect
                        isMulti={false}
                        selectedItems={values?.sourceType || ""}
                        onChange={(sourceType) => {
                          setFieldValue("sourceType", sourceType);
                          setFieldValue("sourceId", null);
                          setFieldValue("exceptionId", null);
                        }}
                      />
                      <ErrorMessage
                        name="sourceType"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={8} md={8}>
                    <FormGroup className="mb-2">
                      <FormLabel className="mid mb-1">
                        {translate("select_one")}
                      </FormLabel>
                      <CustomMultiSelect
                        items={getSourceIdOptions(values?.sourceType)}
                        closeMenuOnSelect
                        isMulti={false}
                        selectedItems={values?.sourceId || ""}
                        onChange={(sourceId) => {
                          setFieldValue("sourceId", sourceId);
                          setFieldValue("exceptionId", null);
                        }}
                      />
                      <ErrorMessage
                        name="sourceId"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>{" "}
                  {values.sourceType === WHATSAPP_SOURCE_INBOUNDS && (
                    <Col xs={12} className="py-0">
                      <FormGroup className="mb-2">
                        <FormLabel className="mid mb-1">
                          {translate("exceptions")}
                        </FormLabel>
                        <CustomMultiSelect
                          items={getExceptions(values?.sourceId)}
                          closeMenuOnSelect
                          isMulti={false}
                          selectedItems={values?.exceptionId || ""}
                          onChange={(exceptionId) =>
                            setFieldValue("exceptionId", exceptionId)
                          }
                          isClearable
                        />
                        <ErrorMessage
                          name="exceptionId"
                          component="div"
                          className="text-danger mid"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </>
              )}
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("whatsapp_template")}
                  </FormLabel>
                  <CustomMultiSelect
                    items={whatsappTemplates.map((t) => ({
                      label: t.templateName,
                      value: t._id,
                    }))}
                    closeMenuOnSelect
                    isMulti={false}
                    selectedItems={values?.template || ""}
                    onChange={(template) => setFieldValue("template", template)}
                    CustomSingleValueComponent={(props) => (
                      <WhatsappTemplateDropdownOption
                        {...props}
                        whatsappTemplates={whatsappTemplates}
                      />
                    )}
                    CustomOption={(props) => (
                      <WhatsappTemplateDropdownOption
                        {...props}
                        isOption
                        whatsappTemplates={whatsappTemplates}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="template"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-2">
                    {translate("trigger")}{" "}
                  </FormLabel>
                  <div className="d-flex align-items-center gap-4 form-control form-control-sm">
                    <FormCheck
                      type="radio"
                      className="large mb-0"
                      label={"Send Now"}
                      checked={values.trigger === "send_now"}
                      onChange={() => {
                        setFieldValue(
                          "trigger",
                          CAMPAIGN_TRIGGER_OPTIONS.SEND_NOW
                        );
                        setFieldValue("dateTime", null);
                      }}
                    />{" "}
                    {allowOnSignUp && (
                      <FormCheck
                        type="radio"
                        className="large mb-0"
                        label={"On Signup"}
                        checked={values.trigger === "on_signup"}
                        onChange={() => {
                          setFieldValue("trigger", "on_signup");
                          setFieldValue("dateTime", null);
                        }}
                      />
                    )}
                    <FormCheck
                      type="radio"
                      className="large mb-0"
                      label={"Schedule Campaign"}
                      checked={values.trigger === "date_time"}
                      onChange={() =>
                        setFieldValue(
                          "trigger",
                          CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME
                        )
                      }
                    />
                  </div>
                  <ErrorMessage
                    name="trigger"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              {values.trigger === "date_time" && (
                <Col xs={12} md={12}>
                  <FormGroup className="mb-0">
                    <FormLabel className="mid mb-1">
                      {translate("campaign_date_time")}:{" "}
                    </FormLabel>
                    <DatePicker
                      wrapperClassName="w-100"
                      className={`${
                        isRTL ? "px-4" : ""
                      }   mb-0 text-dark px-2 py-1 form-control form-control-sm `}
                      selected={
                        values?.dateTime ? new Date(values?.dateTime) : null
                      }
                      dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                      onChange={(e) => {
                        setFieldValue("dateTime", e ? e.toISOString() : null);
                      }}
                      openToDate={new Date()} // Opens the datepicker to the current month and year
                      showYearDropdown
                      showMonthDropdown
                      showTimeInput
                      dropdownMode="scroll"
                      timeFormat="HH:mm" // 24-hour format
                      popperClassName="datepickerPopperClassName"
                      clearButtonClassName="text-dark"
                      portalId="root"
                    />
                    <ErrorMessage
                      name="dateTime"
                      component="div"
                      className="text-danger mid"
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end border-top mt-2">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                <h6 className="smallFont mb-0">
                  {" "}
                  {submitButtonText || translate("send")}
                </h6>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SendWhatsappCampaignForm;
