import React, { useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  Archive,
  BoxArrowUp,
  CalendarDate,
  ChevronLeft,
  ChevronRight,
  FiletypeCsv,
  Funnel,
  Pencil,
  PersonLinesFill,
  Trash,
  Whatsapp,
  XCircle,
  XLg,
} from "react-bootstrap-icons";
import { ADMIN_ROLE, MANAGER_ROLE, SALES_ROLE } from "../../helpers/constants";
import { isAdminOrManager } from "../../helpers/session";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "../common/circular-progress";
import SalesPersonFilter from "./SalesPersonFilter";
import { PERMISSIONS } from "../../helpers/permissions";

const ContactActions = ({
  mobileView,
  users,
  isFetchingContacts,
  isSearchBoxDisabled,
  selectAllMode,
  loadedPageInfo,
  selectedContact,
  unselectedContacts,
  toggleFilter,
  activeFilter,
  customFilter,
  salesPersonFilter,
  setSalesPersonFilter,
  showContactWithFollowUpDue,
  setShowContactWithFollowUpDue,
  shouldRenderClearFilterButton,
  resetFilter,
  showArchivedContact,
  onArchiveSelectedContactClick,
  onDeleteSelectedContactClick,
  onUnArchiveSelectedContactClick,
  onViewArchiedContactClick,
  onSendWhatsappCampaignClick,
  onUpdateManyContactClick,
  isDownloading,
  downloadContractedBuyersCSV,
  openApointmentCalendar,
  openEmployeeRecordModal,
  showAllFields,
  setShowAllFields,
  onViewDuplicateContactsClick,
  showDuplicatesOnly,
  onAutoMergeClick,
  maxLimit,
  setMaxLimit,
  onViewContactWithUnreadWhatsapp,
  showContactWithUnreadWhatsappMessage,
}) => {
  const { translate, isRTL } = useLocalization();
  const [showMaxLimit, setShowMaxLimit] = useState(false);
  const { user, checkNestedPermission } = useAuth();

  const onShowMaxLimitClick = () => {
    setShowMaxLimit(true);
  };
  const onCloseMaxLimit = () => {
    setShowMaxLimit(false);
    setMaxLimit(null);
  };

  const showAutomergeButton = useMemo(() => {
    const canSeeAsAdminOrManager =
      (selectAllMode || selectedContact?.length > 0) &&
      isAdminOrManager(user.role);

    const canSeeAsRegularUser =
      !isAdminOrManager(user.role) &&
      !selectAllMode &&
      selectedContact?.length > 0;

    return (
      (canSeeAsAdminOrManager || canSeeAsRegularUser) && showDuplicatesOnly
    );
  }, [selectAllMode, selectedContact, user, showDuplicatesOnly]);

  const handleMaxLimitChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "") {
      setMaxLimit(null);
      return;
    }

    const numericValue = Number(inputValue);
    if (!isNaN(numericValue)) {
      setMaxLimit(numericValue);
    }
  };
  return (
    <div
      className={`d-flex py-2 ${
        mobileView
          ? "flex-column gap-1 justify-content-start"
          : "flex-row justify-content-between"
      }`}
    >
      <div className="d-flex align-items-center flex-wrap gap-1">
        {/* Show selected contact count */}
        <Button
          className={`d-flex justify-content-between align-items-center py-0 px-0`}
          variant="dark"
          size="sm"
        >
          <span style={{ width: 90 }} className="text-white smallFont">
            {selectAllMode
              ? loadedPageInfo?.resultCount - unselectedContacts?.length
              : selectedContact?.length}{" "}
            / {loadedPageInfo?.resultCount ?? 0}{" "}
          </span>{" "}
          <div className="d-flex align-items-center gap-1 px-1">
            {" "}
            {showMaxLimit && (
              <input
                placeholder="Limit"
                className="max-limit-input-field"
                autoFocus
                value={maxLimit}
                onChange={handleMaxLimitChange}
              />
            )}{" "}
            {showMaxLimit ? (
              <XLg className="" size={15} onClick={onCloseMaxLimit} />
            ) : isRTL ? (
              <ChevronLeft
                className=""
                size={15}
                onClick={onShowMaxLimitClick}
              />
            ) : (
              <ChevronRight
                className=""
                size={15}
                onClick={onShowMaxLimitClick}
              />
            )}
          </div>
        </Button>

        {/* Max limit */}
        {checkNestedPermission([PERMISSIONS.filters.VIEW_ALL_FILTERS]) && (
          <Button
            className="py-0 px-0 d-flex align-items-center "
            variant="dark"
            size="sm"
            disabled={isSearchBoxDisabled}
            onClick={toggleFilter}
          >
            <Funnel size={18} className="mx-1" />{" "}
            <span className="smallFont mb-0">
              {activeFilter?.name
                ? `(${activeFilter?.name})`
                : customFilter
                ? "(Custom)"
                : ""}
            </span>
          </Button>
        )}

        <SalesPersonFilter
          className={`text-white border d-flex align-items-center gap-1 bg-dark rounded-sm border-dark`}
          disabled={isSearchBoxDisabled}
          onChange={(users) => {
            setSalesPersonFilter(users);
          }}
          value={salesPersonFilter}
          showSelectAll
          translate={translate}
        />
        {(showContactWithFollowUpDue ||
          loadedPageInfo?.contactFollowUpDueCount > 0) && (
          <Button
            className="py-0 px-1 d-flex align-items-center"
            variant="danger-light"
            size="sm"
            onClick={() =>
              setShowContactWithFollowUpDue(!showContactWithFollowUpDue)
            }
          >
            <span className="mx-1 mb-0 smallFont">
              {!showContactWithFollowUpDue && (
                <span className="fw-bold">
                  {loadedPageInfo?.contactFollowUpDueCount}{" "}
                </span>
              )}
              {translate(
                showContactWithFollowUpDue
                  ? "clear_followup_filter"
                  : "follow_up_due"
              )}
            </span>

            {showContactWithFollowUpDue && <XCircle className="mx-1" />}
          </Button>
        )}
        {(showDuplicatesOnly || loadedPageInfo?.duplicatesCount > 0) && (
          <Button
            className="py-0 px-1 bg-primary-light d-flex align-items-center"
            variant="warning"
            size="sm"
            onClick={onViewDuplicateContactsClick}
          >
            <span className="mx-1 mb-0 smallFont">
              {" "}
              {!showDuplicatesOnly && (
                <span className="fw-bold">
                  {loadedPageInfo?.duplicatesCount}{" "}
                </span>
              )}
              {translate(
                showDuplicatesOnly ? "clear_duplicate_filter" : "duplicates"
              )}
            </span>{" "}
            {showDuplicatesOnly && <XCircle className="mx-1" />}
          </Button>
        )}

        {(loadedPageInfo?.whatsappUnreadCount > 0 ||
          showContactWithUnreadWhatsappMessage) && (
          <Button
            className="py-0 px-1 bg-light-green d-flex align-items-center"
            variant="success"
            size="sm"
            onClick={onViewContactWithUnreadWhatsapp}
          >
            <span className="mx-1 mb-0 smallFont">
              {!showContactWithUnreadWhatsappMessage && (
                <span className="fw-bold">
                  {loadedPageInfo?.whatsappUnreadCount}{" "}
                </span>
              )}
              {translate(
                showContactWithUnreadWhatsappMessage
                  ? "clear_whatsapp_filter"
                  : "unread_message"
              )}
            </span>
            {showContactWithUnreadWhatsappMessage && (
              <XCircle className="mx-1" />
            )}
          </Button>
        )}

        {shouldRenderClearFilterButton && (
          <Button
            className="py-0  d-flex align-items-center"
            variant="primary"
            size="sm"
            onClick={resetFilter}
          >
            <span className="smallFont">{translate("clear_filters")}</span>

            <XCircle className="mx-1" />
          </Button>
        )}

        {(selectedContact.length > 0 || selectAllMode) && (
          <>
            <div
              className="border-start bg-gray "
              style={{ width: 2, height: 20 }}
            />
            {/* Delete selected contact button */}
            {!selectAllMode &&
              checkNestedPermission(
                showArchivedContact
                  ? [
                      PERMISSIONS.contacts.DELETE_ALL_CONTACTS,
                      PERMISSIONS.contacts.DELETE_ASSIGNED_CONTACTS,
                      PERMISSIONS.contacts.DELETE_SPECIFICUSERS_CONTACTS,
                    ]
                  : [
                      PERMISSIONS.contacts.UPDATE_ALL_CONTACTS,
                      PERMISSIONS.contacts.UPDATE_ASSIGNED_CONTACTS,
                      PERMISSIONS.contacts.UPDATE_SPECIFICUSERS_CONTACTS,
                    ]
              ) && (
                <>
                  <Button
                    variant="outline-dark"
                    title={
                      showArchivedContact ? "Delete Permanently" : "Archive"
                    }
                    size="sm"
                    className="py-0 "
                    onClick={
                      showArchivedContact
                        ? onDeleteSelectedContactClick
                        : onArchiveSelectedContactClick
                    }
                  >
                    <Trash className="mb-1" />
                  </Button>
                  {showArchivedContact && (
                    <Button
                      title="Restore"
                      variant="outline-dark"
                      size="sm"
                      className="py-0 "
                      onClick={onUnArchiveSelectedContactClick}
                    >
                      <BoxArrowUp className="mb-1" />
                    </Button>
                  )}
                </>
              )}
            {/* Edit selected contact button */}
            {(selectAllMode || selectedContact?.length > 0) &&
              [ADMIN_ROLE, MANAGER_ROLE, SALES_ROLE].includes(user?.role) &&
              checkNestedPermission([
                PERMISSIONS.contacts.UPDATE_ALL_CONTACTS,
                PERMISSIONS.contacts.UPDATE_ASSIGNED_CONTACTS,
                PERMISSIONS.contacts.UPDATE_SPECIFICUSERS_CONTACTS,
              ]) && (
                <Button
                  variant="outline-dark"
                  size="sm"
                  title="Edit"
                  className="py-0 "
                  onClick={() => onUpdateManyContactClick()}
                >
                  <Pencil className="mb-1" />
                </Button>
              )}
            {isAdminOrManager(user.role) && (
              <Button
                className="py-1 d-flex align-items-center"
                variant={"outline-success"}
                size="sm"
                onClick={onSendWhatsappCampaignClick}
              >
                <Whatsapp />
              </Button>
            )}
            {showAutomergeButton && (
              <Button
                variant="outline-dark"
                size="sm"
                title="Edit"
                className="py-0"
                onClick={() => onAutoMergeClick()}
              >
                {translate("merge")}
              </Button>
            )}{" "}
            <div
              className="border-start bg-gray "
              style={{ width: 2, height: 20 }}
            />
          </>
        )}

        <Form.Check
          id={"show-all-fields"}
          size={"sm"}
          checked={showAllFields}
          onChange={(e) => setShowAllFields(e.target.checked)}
          label={
            <h6 id="show-all-fields" className="smallFont mb-0">
              {translate("show_all_fields")}
            </h6>
          }
        />
      </div>
      <div className="d-flex align-items-center gap-1">
        {" "}
        {isAdminOrManager(user?.role) && (
          <>
            {" "}
            <Button
              className="py-1 d-flex align-items-center"
              variant={
                showArchivedContact ? "outline-danger" : "outline-danger"
              }
              size="sm"
              onClick={onViewArchiedContactClick}
            >
              {showArchivedContact ? (
                <>
                  <span className="smallFont">
                    {translate("close_archive")}
                  </span>{" "}
                  <XCircle className="mx-1" />
                </>
              ) : (
                <Archive />
              )}
            </Button>
          </>
        )}
        <Button
          className="py-1 d-flex align-items-center"
          variant="outline-dark"
          size="sm"
          onClick={openEmployeeRecordModal}
        >
          <PersonLinesFill />{" "}
        </Button>
        {checkNestedPermission([
          PERMISSIONS.appointments.VIEW_ALL_APPOINTMENTS,
        ]) && (
          <Button
            className="py-1 d-flex align-items-center"
            variant="outline-info"
            size="sm"
            onClick={() => openApointmentCalendar({ newTab: false })}
          >
            <CalendarDate />{" "}
          </Button>
        )}
        <Button
          className="py-1 d-flex align-items-center"
          variant="outline-success"
          size="sm"
          disabled={isSearchBoxDisabled || isDownloading}
          onClick={downloadContractedBuyersCSV}
        >
          {isDownloading ? (
            <CircularProgressBar size={12} className="mx-2" />
          ) : (
            <FiletypeCsv />
          )}
        </Button>
      </div>
    </div>
  );
};

export default ContactActions;
