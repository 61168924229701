import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Send, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { BROADCAST_TYPES, useWebSocket } from "../../context/WebSocketContext";
import { getDealsTableColumns } from "../../helpers/dataSheetConstants";
import { isAdminOrManager } from "../../helpers/session";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import useLocalization from "../../hooks/useLocalization";
import useSelection from "../../hooks/useSelection";
import { contractService } from "../../services/contractService";
import AlertModal from "../common/AlertModal";
import DataTable from "../common/data-table/DataTable";
import CreateDealModal from "./CreateDealModal";
import ProsperoContractEditAndSendSidebar from "./ProsperoContractEditSidebar";
import { updateItemsInArray } from "../../helpers/global";
import { PERMISSIONS } from "../../helpers/permissions";

const columnToHide = ["client", "productionStage", "signedOn", "tags"];

const MiniBrowseDeals = ({ contact, editable = true, onDealsChange }) => {
  const { message, clearMessage } = useWebSocket();
  const users = useAppChoices("users");

  const products = useAppChoices("products");

  const { translate } = useLocalization();
  const { user, checkNestedPermission } = useAuth();

  const { openDealPopup, updatedDeal, updatedContact } =
    useContactAndDealPopup();

  const [tableDeals, setTableDeals] = useState([]);
  const [deleteContractModalMeta, setDeleteContractModalMeta] = useState(null);

  // new deal
  const [newDealModalMeta, setNewDealModalMeta] = useState(null);
  const [prosperoContractEditMeta, setProsperoContractEditMeta] =
    useState(null);

  const {
    selectAllMode,
    setSelectAllMode,
    selectedItems,
    setSelectedItems,
    unselectedItems,
    setUnselectedItems,
    isItemSelected,
    onToggleSelectItem,
    onToggleSelectAll,
    clearSelection,
  } = useSelection(tableDeals);

  const tableColumns = useMemo(
    () =>
      getDealsTableColumns({
        user,
        columnToHide,
        users,
        products,
        onToggleSelectAll,
        onToggleSelectItem,
        isItemSelected,
        selectAllMode,
        translate,
      }),
    [
      user,
      users,
      products,
      onToggleSelectAll,
      isItemSelected,
      onToggleSelectItem,
      selectAllMode,
      columnToHide,
    ]
  );

  const onDealClick = useCallback(
    (contract) => {
      if (editable) {
        openDealPopup(contract?._id);
      }
    },
    [editable]
  );

  const onDeleteSelectedContractClick = () => {
    setDeleteContractModalMeta({
      contractToBeDeleted: selectedItems,
      showProgress: false,
    });
  };

  const deleteSelectedDeals = async () => {
    const { contractToBeDeleted } = deleteContractModalMeta;

    setDeleteContractModalMeta((deleteModalMeta) => ({
      ...deleteModalMeta,
      showProgress: true,
    }));

    try {
      const { response, error } = await contractService.deleteSelectedContract({
        ids: contractToBeDeleted?.map((deal) => deal._id),
      });
      if (response) {
        let updatedDeals = tableDeals.filter(
          (deal) => !contractToBeDeleted.find((c) => c._id === deal?._id)
        );

        if (onDealsChange) {
          onDealsChange(updatedDeals);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteContractModalMeta(null);
    }
  };

  const onCreateNewDealClick = () => {
    setNewDealModalMeta({});
  };

  const onNewDealSubmit = async (contractDetails) => {
    setNewDealModalMeta({ showProgress: true });
    const { sendImmediately } = contractDetails;

    const { response, error } = await contractService.createContract(
      contractDetails
    );

    if (error) {
      setNewDealModalMeta({ showProgress: false });
      return toast.error(error);
    }

    setNewDealModalMeta(null);
    if (response) {
      let updatedDeals = [response, ...tableDeals];
      if (onDealsChange) {
        onDealsChange(updatedDeals);
      }
    }

    if (sendImmediately) {
      toast.success("Deal Sent Successfully");
      return;
    }

    setProsperoContractEditMeta({ contract: response });
  };

  const onContractEditAndSendClick = async () => {
    const { contract } = prosperoContractEditMeta;
    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: true,
    });

    const { response, error } = await contractService.sendContractViaEmail({
      contractId: contract._id,
    });

    setProsperoContractEditMeta({
      ...prosperoContractEditMeta,
      showProgress: false,
    });

    if (error) {
      return toast.error(error);
    }

    toast.success("Deal Sent Successfully");
    if (onDealsChange) {
      onDealsChange(
        tableDeals.map((d) =>
          d._id === contract._id ? { ...d, status: "Sent" } : d
        )
      );
    }

    setProsperoContractEditMeta(null);
  };

  useEffect(() => {
    if (contact) {
      setTableDeals(
        contact?.contracts.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    }
  }, [contact]);

  useEffect(() => {
    if (message) {
      let { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTRACT_UPDATE:
          if (payload.contact?._id === contact._id) {
            onDealsChange(
              contact?.contracts.map((d) =>
                d._id === payload._id ? payload : d
              )
            );
          }
          break;

        case BROADCAST_TYPES.CONTRACT_CREATE:
          if (payload.contact?._id === contact._id) {
            onDealsChange(updateItemsInArray(contact?.contracts, payload));
          }
          break;

        case BROADCAST_TYPES.CONTRACT_DELETE:
          const filterDeals = (dealsList) =>
            dealsList.filter((deal) => deal._id !== payload._id);
          onDealsChange(filterDeals(contact?.contracts));
          break;

        default:
          console.log("Unknown message type");
          break;
      }
    }
  }, [message]);

  const totalPageDate = useMemo(() => tableDeals?.length, [tableDeals]);

  return (
    <>
      <Container fluid className={`d-flex flex-column p-0 m-0 h-100`}>
        <div className={`flex-grow-1`}>
          <DataTable
            maxTableHeight={"100vh"}
            rowKey={"_id"}
            columns={tableColumns}
            data={tableDeals}
            onRowClick={onDealClick}
            allowFilter={false}
            allowSort={false}
          />
        </div>

        <div
          className="d-flex align-items-center py-1 w-100 bg-white border-top"
          style={{ position: "absolute", bottom: 0, left: 0 }}
        >
          <div className="flex-grow-1 d-flex align-items-center">
            {/* Show selected contact count */}
            {editable &&
              checkNestedPermission(
                PERMISSIONS.contracts.CREATE_ALL_CONTRACTS
              ) && (
                <Button
                  size="sm"
                  onClick={onCreateNewDealClick}
                  variant="success"
                  className="align-items-center d-flex text-white py-0 mx-1"
                >
                  <Send size={10} className="mx-1" />
                  {translate("create_new_deal")}
                </Button>
              )}

            {(selectedItems.length > 0 || selectAllMode) && (
              <>
                <Button
                  className="smallFont py-0 mx-1"
                  variant="dark"
                  size="sm"
                >
                  <span className="text-white smallFont mx-1">
                    {selectAllMode
                      ? totalPageDate - unselectedItems?.length
                      : selectedItems?.length}{" "}
                    / {totalPageDate} Selected
                  </span>
                </Button>
                {/* Delete selected contact button */}
                {isAdminOrManager(user?.role) && !selectAllMode && (
                  <Button
                    variant="outline-dark"
                    size="sm"
                    className="px-1 py-0 mx-1"
                    onClick={onDeleteSelectedContractClick}
                  >
                    <Trash className="mb-1" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Container>
      {selectedItems.length > 0 && (
        <AlertModal
          show={Boolean(deleteContractModalMeta)}
          onHide={() => setDeleteContractModalMeta(false)}
          onDismissClick={() => setDeleteContractModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_contracts")}
          showProgress={deleteContractModalMeta?.showProgress}
          onContinueClick={deleteSelectedDeals}
        />
      )}

      <CreateDealModal
        show={Boolean(newDealModalMeta)}
        showProgress={newDealModalMeta?.showProgress}
        contact={contact}
        onHide={() => setNewDealModalMeta(null)}
        onSubmit={onNewDealSubmit}
      />
      <ProsperoContractEditAndSendSidebar
        contract={prosperoContractEditMeta?.contract}
        show={Boolean(prosperoContractEditMeta)}
        onHide={() => setProsperoContractEditMeta(false)}
        onContractEditAndSendClick={onContractEditAndSendClick}
        showProgress={prosperoContractEditMeta?.showProgress}
      />
    </>
  );
};

export default MiniBrowseDeals;
