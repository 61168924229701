import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { whatsappCampaignService } from "../services/whatsappCampaignService";
import useAuth from "./useAuth";
import { PERMISSIONS } from "../helpers/permissions";

const useWhatsappCampaigns = ({
  source,
  sourceId,
  exceptionId,
  refetch = null,
}) => {
  const { checkNestedPermission } = useAuth();
  const [isLoadingWhatsappCampaign, setIsLoadingWhatsappCampaign] =
    useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(null);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const requestBody = {
          filter: [
            {
              key: "sourceType",
              value: source,
            },
            {
              key: "sourceId",
              value: sourceId,
            },
            {
              key: "exceptionId",
              value: exceptionId || null,
            },
          ],
        };

        setIsLoadingWhatsappCampaign(true);
        const { response, error } =
          await whatsappCampaignService.getWhatsappCampaigns(requestBody);

        if (error) {
          toast.error(error);
          return;
        }

        const { results } = response;
        setCampaigns(
          results.flatMap((r) =>
            r.schedules.map((schedule) => ({
              ...schedule,
              campaignId: r._id,
            }))
          )
        );
      } catch (error) {
        console.error(error);
        toast.error(
          "An unexpected error occurred while fetching WhatsApp campaigns."
        );
      } finally {
        setIsLoadingWhatsappCampaign(false);
      }
    };
    if (
      checkNestedPermission([PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS])
    ) {
      fetchCampaigns();
    }
  }, [source, sourceId, exceptionId, refetch]);

  return { campaigns, isLoadingWhatsappCampaign };
};

export default useWhatsappCampaigns;
