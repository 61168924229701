import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import {
  CALENDAR_QS,
  CONTACT_QS,
  DEFAULT_DATE_TIME_FORMAT,
} from "../helpers/constants";
import useNotification from "../hooks/useNotification";
import { BROADCAST_TYPES, useWebSocket } from "./WebSocketContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryParams } from "../hooks/useQueryParams";
import AppointmentModal from "../components/contacts/contact-details/AppointmentModal";

export const ApointmentCalendarContext = createContext();

export const ApointmentCalendarProvider = ({ children }) => {
  const { view, apointment } = useQueryParams();
  const { message } = useWebSocket();
  const showNotification = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);

  const openApointmentCalendar = ({ apointmentId = null, newTab = true }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("view");
    if (apointmentId) {
      searchParams.set("apointment", apointmentId);
    }
    searchParams.set("view", CALENDAR_QS);
    if (newTab) {
      window.open(`${location.pathname}?${searchParams.toString()}`, "_blank");
    } else {
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  };

  const closeApointmentCalendar = () => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(CONTACT_QS)) {
      searchParams.set("view", CONTACT_QS);
    } else {
      searchParams.delete("view");
    }
    searchParams.delete("apointment");
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (!message) return;
    let { type, payload } = message;

    if (type === BROADCAST_TYPES.APPOINTMENT_NOTIFICATION) {
      const title = `Meeting Reminder: ${payload.title}`;

      const notificationOptions = {
        body: `Your meeting starts in ${moment(payload.startDateTime).diff(
          moment(),
          "minutes"
        )} minutes at ${moment(payload.startDateTime).format(
          DEFAULT_DATE_TIME_FORMAT
        )}.`,
        requireInteraction: true, // Notification stays until the user interacts with it
        timestamp: new Date(payload.startDateTime).getTime(), // Timestamp for the notification
        onClick: () => openApointmentCalendar({ apointmentId: payload._id }),
      };
      showNotification(title, notificationOptions);
    }
  }, [message]);

  useEffect(() => {
    if (view === CALENDAR_QS) {
      setAppointmentModalMeta(true);
    } else {
      setAppointmentModalMeta(false);
    }
  }, [apointment, view]);

  const contextObj = { openApointmentCalendar, closeApointmentCalendar };

  return (
    <ApointmentCalendarContext.Provider value={contextObj}>
      {children}
      <AppointmentModal
        apointmentToOpen={apointment}
        show={appointmentModalMeta}
        onHide={closeApointmentCalendar}
        specificContactMode={false}
      />
    </ApointmentCalendarContext.Provider>
  );
};
