import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CurrencyDollar,
  GraphUpArrow,
  PersonCheckFill,
  PersonPlusFill,
  PersonVideo3,
  TelephoneFill,
} from "react-bootstrap-icons";
import {
  getDashboardIncomeColumns,
  getDashboardNewClientColumns,
  getDashboardNewMeetingColumns,
  getDashboardSalesWonColumns,
  getPhoneCallTableColumns,
} from "../../../helpers/dataSheetConstants";
import { getDashboardLineCartData } from "../../../helpers/global";
import { PERMISSIONS } from "../../../helpers/permissions";
import useApointmetnCalendar from "../../../hooks/useApointmetnCalendar";
import useAuth from "../../../hooks/useAuth";
import DashboardDataNavigator from "../../common/DashboardDataNavigator";
import Linechart from "../../common/graph/LineChart";
import CallDetailModal from "../../contacts/phoneCalls/CallDetailModal";
import StatCard from "../StatCard";
import DashboardTable from "./DashboardTable";
import InboundTable from "./InboundTable";
import useContactAndDealPopup from "../../../hooks/useContactAndDealPopup";

const GeneralStatBoard = ({
  data,
  dateRange,
  selectedUser,
  fetching,
  onDealClick,
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  navigating,
}) => {
  const { openContactPopup } = useContactAndDealPopup();
  const { checkNestedPermission } = useAuth();
  const { openApointmentCalendar } = useApointmetnCalendar();
  const [activeStat, setActiveStat] = useState(null);
  const salesWonColumns = useMemo(
    () => getDashboardSalesWonColumns({ openContactPopup }),
    []
  );
  const newMeetingColumns = useMemo(
    () => getDashboardNewMeetingColumns({}),
    []
  );
  const incomColumns = useMemo(
    () => getDashboardIncomeColumns({ openContactPopup }),
    []
  );
  const newClientColumns = useMemo(
    () => getDashboardNewClientColumns({ openContactPopup }),
    []
  );

  const [callDetailModalMeta, setCallDetailModalMeta] = useState(null);

  const onPlayClick = (callInfo) => {
    setCallDetailModalMeta(callInfo);
  };

  const phoneCallColumns = useMemo(
    () => getPhoneCallTableColumns(onPlayClick),
    [onPlayClick]
  );

  const generalStats = useMemo(() => {
    return [
      {
        name: "inbounds_no_count",
        data: data?.newLeads,
        variant: "primary",
        icon: PersonPlusFill,
        chartData: getDashboardLineCartData(
          data?.newLeads?.chartData,
          "Inbound",
          data?.newLeads?.chartInterval
        ),
      },
      {
        name: "phone calls",
        data: data?.newPhoneCalls,
        variant: "secondary",
        icon: TelephoneFill,
        tableColumns: phoneCallColumns,
        tableData: data?.newPhoneCalls?.tableData,
        chartData: getDashboardLineCartData(
          data?.newPhoneCalls?.chartData,
          "Phone Call",
          data?.newPhoneCalls?.chartInterval
        ),
      },
      {
        name: "meetings",
        data: data?.newMeetings,
        variant: "info",
        icon: PersonVideo3,
        tableColumns: newMeetingColumns,
        tableData: data?.newMeetings?.tableData,
        chartData: getDashboardLineCartData(
          data?.newMeetings?.chartData,
          "Meeting",
          data?.newMeetings?.chartInterval
        ),
      },
      {
        name: "monthly sales",
        data: data?.sales,
        variant: "dark",
        icon: GraphUpArrow,
        tableColumns: salesWonColumns,
        tableData: data?.sales?.tableData,
        chartData: getDashboardLineCartData(
          data?.sales?.chartData,
          "Sale",
          data?.sales?.chartInterval
        ),
      },
      {
        name: "monthly income",
        data: data?.income,
        variant: "danger",
        icon: CurrencyDollar,
        tableColumns: incomColumns,
        tableData: data?.income?.tableData,
        chartData: getDashboardLineCartData(
          data?.income?.chartData,
          "Income",
          data?.income?.chartInterval
        ),
      },
      {
        name: "number of new clients",
        data: data?.newClients,
        variant: "purple",
        icon: PersonCheckFill,
        tableColumns: newClientColumns,
        tableData: data?.newClients?.tableData,
        chartData: getDashboardLineCartData(
          data?.newClients?.chartData,
          "New Client",
          data?.newClients?.chartInterval
        ),
      },
    ];
  }, [
    data,
    newMeetingColumns,
    salesWonColumns,
    incomColumns,
    newClientColumns,
  ]);

  const onTableRowClick = (row) => {
    if (activeStat?.name === "monthly sales") {
      onDealClick(row);
    } else if (
      activeStat?.name === "monthly income" ||
      activeStat?.name === "number of new clients"
    ) {
      onDealClick(row?.contract);
    } else if (activeStat?.name === "meetings") {
      openApointmentCalendar({
        apointmentId: row._id,
        newTab: false,
      });
    } else return;
  };

  useEffect(() => {
    setActiveStat(
      !activeStat
        ? generalStats?.[0]
        : generalStats?.find((s) => s?.name === activeStat?.name)
    );
  }, [generalStats]);

  const handleCardClick = (stat) => {
    setActiveStat(stat);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Row className="my-2 mx-1">
        {generalStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={4} md={4} sm={4} xs={6}>
            <StatCard
              stat={stat}
              active={activeStat?.name === stat?.name}
              fetching={fetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
        {}
      </Row>
      <Row className="my-2 mx-1 flex-grow-1">
        {activeStat?.chartData && (
          <Col className="p-1" lg={12} md={12} sm={12} xs={12}>
            {" "}
            <Linechart
              chartData={activeStat?.chartData}
              chartName={activeStat?.name}
              loading={fetching}
              randerExtraInfo={() => (
                <DashboardDataNavigator
                  rangeType={rangeType}
                  dateRangeLabel={dateRangeLabel}
                  handleNavigateDate={handleNavigateDate}
                  loading={navigating}
                />
              )}
              hideContiniousFalsyDataFromLast
            />
          </Col>
        )}
        {activeStat?.name === "inbounds_no_count" &&
          checkNestedPermission([
            PERMISSIONS.contacts.VIEW_ALL_CONTACTS,
            PERMISSIONS.contacts.VIEW_ASSIGNED_CONTACTS,
          ]) && <InboundTable dateRange={dateRange} users={selectedUser} />}
        {activeStat?.tableData && activeStat?.name !== "inbounds_no_count" && (
          <Col className="p-1" lg={12} md={12} sm={12} xs={12}>
            <DashboardTable
              loading={fetching}
              tableColumns={activeStat?.tableColumns}
              tableData={activeStat?.tableData}
              tableName={activeStat?.name}
              onRowClick={onTableRowClick}
              maxTableHeight="calc(100vh - 200px)"
            />
          </Col>
        )}
      </Row>
      <CallDetailModal
        show={Boolean(callDetailModalMeta)}
        onHide={() => setCallDetailModalMeta(null)}
        callInfo={callDetailModalMeta}
      />{" "}
    </div>
  );
};

export default GeneralStatBoard;
