import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({}) => {
  const { isUserLoggedIn } = useAuth();
  const { pathname, search } = useLocation();

  if (!isUserLoggedIn) {
    const redirectTo = pathname + search;
    return <Navigate to={`/auth/login?redirect=${redirectTo}`} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
