import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  BROADCAST_TYPES,
  useWebSocket,
} from "../../../context/WebSocketContext";
import { mergeAppAndGoogleAppointments } from "../../../helpers/global";
import useApointmetnCalendar from "../../../hooks/useApointmetnCalendar";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import { availabilityService } from "../../../services/availabilityService";
import { CustomEvent } from "../../common/CustomEvent";
import Loader from "../../common/Loader";

const Activity = ({ contact }) => {
  const { message } = useWebSocket();
  const { openApointmentCalendar } = useApointmetnCalendar();
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);


  const fetchAppointments = async ({ noLoader = false }) => {
    try {
      if (!noLoader) {
        setIsLoading(true);
      }

      const reqBody = {
        filter: {
          contact: contact?._id,
        },
        sort: { startDateTime: -1 },
        returnGoogleEvents: false,
      };

      const { response, error } = await availabilityService.search(reqBody);

      if (error) {
        toast.error(error);
        return;
      }

      const { results } = response;
      const app = mergeAppAndGoogleAppointments(results);

      let pastAppointments = [];
      let upcomingAppointments = [];
      const now = new Date();

      app?.forEach((appointment) => {
        if (new Date(appointment.startDateTime) < now) {
          pastAppointments.push(appointment);
        } else if (new Date(appointment.startDateTime) > now) {
          upcomingAppointments.push(appointment);
        }
      });

      setAppointments({ pastAppointments, upcomingAppointments });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (contact) {
      fetchAppointments({ noLoader: false });
    }
  }, [contact?._id]);

  useDebouncedEffect(
    () => {
      if (!message) return;

      const { type, payload } = message;
      const isAppointmentType = [
        BROADCAST_TYPES.APPOINTMENT_CREATE,
        BROADCAST_TYPES.APPOINTMENT_UPDATE,
        BROADCAST_TYPES.APPOINTMENT_DELETE,
      ].includes(type);

      if (isAppointmentType && payload?.contact?._id === contact._id) {
        fetchAppointments({ noLoader: true });
      }
    },
    [message],
    500
  );

  return isLoading ? (
    <Loader />
  ) : (
    <div className="">
      <div className="bg-primary-light p-1 mb-2 mid fw-bold">
        Upcoming Events{" "}
        {appointments?.upcomingAppointments?.length > 0
          ? `(${appointments?.upcomingAppointments?.length})`
          : ""}
      </div>
      {appointments?.upcomingAppointments?.length > 0 ? (
        appointments?.upcomingAppointments?.map((app) => (
          <div
            key={app?._id}
            className="mb-1 hover"
            onClick={() => {
              openApointmentCalendar({
                apointmentId: app._id,
                newTab: false,
              });
            }}
          >
            <CustomEvent event={app} showFullDate showLinkToVideo />
          </div>
        ))
      ) : (
        <div className="p-2 d-flex justify-content-center">
          <h6 className="smallFont">No upcoming events!</h6>
        </div>
      )}
      <div className="bg-primary-light p-1 my-2 mid fw-bold">
        Past Events{" "}
        {appointments?.pastAppointments?.length > 0
          ? `(${appointments?.pastAppointments?.length})`
          : ""}
      </div>
      {appointments?.pastAppointments?.length > 0 ? (
        appointments?.pastAppointments?.map((app) => (
          <div
            key={app?._id}
            className="mb-1 hover"
            onClick={() => {
              openApointmentCalendar({
                apointmentId: app._id,
                newTab: false,
              });
            }}
          >
            <CustomEvent event={app} showFullDate showLinkToVideo />
          </div>
        ))
      ) : (
        <div className="p-2 d-flex justify-content-center">
          <h6 className="smallFont">No past events!</h6>
        </div>
      )}
    </div>
  );
};

export default Activity;
