import React, { useEffect, useMemo, useRef, useState } from "react";
import { GripVertical, XLg } from "react-bootstrap-icons";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { calculateModalPosition } from "../../helpers/global";

const DraggableModal = ({
  show,
  onHide,
  hideCloseButton = false,
  title,
  width = { sm: 400, md: 600, lg: 600 },
  height,
  popupOffset = null,
  children,
  HeaderExtraComponent,
  LeftHeaderExtraComponent,
  renderHeader,
  hasOverlay = false,
}) => {
  const { screenSize, screenWidth } = useScreenWidth();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const modalRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setInitialPosition({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
    document.body.classList.add("no-select");
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      modalRef.current.classList.remove("draggable-modal-content");
      setPosition({
        x: e.clientX - initialPosition.x,
        y:
          e.clientY - initialPosition.y > 0 ? e.clientY - initialPosition.y : 0,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.body.classList.remove("no-select");
    modalRef.current.classList.add("draggable-modal-content");
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const positionAtCenter = () => {
    const { innerWidth, innerHeight } = window;
    const { offsetWidth, offsetHeight } = modalRef.current;
    setPosition({
      x: (innerWidth - offsetWidth) / 2,
      y: (innerHeight - offsetHeight) / 2,
    });
  };
  const modalWidth = useMemo(() => width[screenSize], [width, screenSize]);

  useEffect(() => {
    if (show) {
      if (Boolean(popupOffset)) {
        // opens in custom position

        let modalPosition = calculateModalPosition({
          x: popupOffset?.x,
          y: popupOffset?.y,
          xOffSet: popupOffset?.xOffSet,
          yOffSet: popupOffset?.yOffSet,
          modalWidth: modalWidth,
          modalHeight: height,
        });
        let { x, y } = modalPosition;

        if (x < 0 || x + modalWidth >= screenWidth) {
          return positionAtCenter();
        }

        setPosition(modalPosition);
      } else {
        positionAtCenter();
        // opens in center
      }
    }
  }, [show, popupOffset, modalWidth, height, screenSize]);

  return (
    <div
      onClick={(e) => {
        onHide && onHide();
      }}
      className={`draggable-modal-container ${
        show ? "draggable-modal-visible" : "draggable-modal-collapsed"
      }`}
      style={{
        background: hasOverlay ? "rgba(0, 0, 0, 0.1)" : "transparent",
        height: hasOverlay ? "100vh" : "",
      }}
    >
      <div
        ref={modalRef}
        className="draggable-modal draggable-modal-content border bg-white "
        style={{
          width: width[screenSize],
          height: height || "auto",
          position: "absolute",
          top: `${position.y}px`,
          left: `${position.x}px`,
          cursor: isDragging ? "grabbing" : "default",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {renderHeader ? (
          renderHeader({ handleMouseDown, onHide })
        ) : (
          <div
            className="d-flex justify-content-between bg-gray align-items-center py-2 px-1 border-bottom mb-2"
            onMouseDown={handleMouseDown}
            style={{ cursor: "grab" }}
          >
            <h6 className="mb-0 d-flex align-items-center">
              {" "}
              {LeftHeaderExtraComponent && <LeftHeaderExtraComponent />}
              <GripVertical className="" />{" "}
              <span className="mid mx-1 fw-bold">{title}</span>
            </h6>{" "}
            <div className="d-flex align-items-center">
              {HeaderExtraComponent && <HeaderExtraComponent />}
              {!hideCloseButton && <XLg onClick={onHide} className="hover" />}
            </div>
          </div>
        )}

        <div className="overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default DraggableModal;
