import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { SendArrowUp } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { WHATSAPP_SOURCE_QUICK_SEND } from "../../../helpers/constants";
import { templateUrl } from "../../../helpers/global";
import { isAdminOrManager } from "../../../helpers/session";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import { whatsappCampaignService } from "../../../services/whatsappCampaignService";
import AlertModal from "../../common/AlertModal";
import OverviewWhatsappTemplate from "../../common/OverviewWhatsappTemplate";

const SalesPersonWhatsappTemplates = ({ contact }) => {
  const { user } = useAuth();
  const whatsappTemplates = useAppChoices("whatsappTemplates");
  const salespersonWhatsappTemplates = useMemo(
    () => (whatsappTemplates || []).filter((t) => t.category === "salesperson"),
    [whatsappTemplates]
  );

  const [confirmSentWhatsappMessage, setConfirmSentWhatsappMessage] =
    useState(null);

  const sendTemplate = (template) => {
    if (!template || !contact) return;

    setConfirmSentWhatsappMessage({ template, contact });
  };

  const sendWhatsappMessage = async () => {
    const { template } = confirmSentWhatsappMessage;

    setConfirmSentWhatsappMessage({
      ...confirmSentWhatsappMessage,
      showProgress: true,
    });
    let requestBody = {
      selectedContacts: [contact?._id],
      allSchedules: [
        {
          template: template._id,
          trigger: "send_now",
        },
      ],
      sourceType: WHATSAPP_SOURCE_QUICK_SEND,
    };

    let { response, error } =
      await whatsappCampaignService.createWhatsappCampaign(requestBody);
    if (error) {
      toast.error(error);
      setConfirmSentWhatsappMessage({
        ...confirmSentWhatsappMessage,
        showProgress: false,
      });
      return;
    }
    toast.success("Whatsapp message sent succesfully");
    setConfirmSentWhatsappMessage(null);
  };

  return (
    <>
      {salespersonWhatsappTemplates.length > 0 ? (
        <div className="d-flex align-items-center gap-1">
          {salespersonWhatsappTemplates.map((template) => (
            <div
              key={template._id}
              className="border border-dark rounded p-1 d-flex gap-2 align-items-center"
            >
              <OverviewWhatsappTemplate
                message={template?.messageBody}
                Element={(props) =>
                  isAdminOrManager(user.role) ? (
                    <a
                      target="_blank"
                      className="smallFont"
                      {...props}
                      href={templateUrl(template)}
                    >
                      {template?.templateName}
                    </a>
                  ) : (
                    <h6 {...props} className="smallFont mb-0">
                      {template?.templateName}
                    </h6>
                  )
                }
              />

              <Button
                className="py-0"
                variant="success text-white"
                size="sm"
                onClick={() => sendTemplate(template)}
              >
                <SendArrowUp className="" />
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <h6 className="mx-auto mt-2 mb-0 text-muted">No templates found!</h6>
      )}

      <AlertModal
        show={Boolean(confirmSentWhatsappMessage)}
        onHide={() => setConfirmSentWhatsappMessage(null)}
        onDismissClick={() => setConfirmSentWhatsappMessage(null)}
        showProgress={confirmSentWhatsappMessage?.showProgress}
        onContinueClick={sendWhatsappMessage}
        alertText={`Are you sure to send ${confirmSentWhatsappMessage?.template?.templateName} message to ${contact?.firstName}?`}
      />
    </>
  );
};

export default SalesPersonWhatsappTemplates;
