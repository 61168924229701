import React, { useEffect, useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getTagsColumns } from "../../../helpers/dataSheetConstants";
import { updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { tagsServices } from "../../../services/tagsServices";
import DataTable from "../../common/data-table/DataTable";
import AddEditTagModal from "./AddEditTagModal";
import DeleteTagModal from "./DeleteTagModal";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const Tags = ({ className = "", tags, setTags }) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  const [addEditTagMeta, setAddEditTagMeta] = useState(null);
  const [deleteTagMeta, setDeleteTagMeta] = useState(null);

  const onEditTagClick = (tag) => {
    setAddEditTagMeta({ initialValues: tag, editMode: true });
  };
  const onDeleteTagClick = (tag) => {
    setDeleteTagMeta({ tagToDelete: tag });
  };
  const tableColumns = useMemo(
    () =>
      getTagsColumns({
        onEditTagClick,
        onDeleteTagClick,
        allowEdit: checkNestedPermission([PERMISSIONS.tags.UPDATE_ALL_TAGS]),
        allowDelete: checkNestedPermission([PERMISSIONS.tags.DELETE_ALL_TAGS]),
      }),
    [onEditTagClick, onDeleteTagClick]
  );

  const deleteTag = async (deleteInfo) => {
    let { tagToDelete, alternativeTag } = deleteInfo;
    try {
      setDeleteTagMeta({ ...deleteTagMeta, showProgress: true });
      const { response, error } = await tagsServices.deleteTag({
        name: tagToDelete?.name,
        ...(alternativeTag === "unassign" ? {} : { alternativeTag }),
      });
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success(response.message);
      }
      setTags((tag) => tag.filter((t) => t._id !== tagToDelete._id));
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteTagMeta(null);
    }
  };

  const onSubmitTag = async (formData) => {
    setAddEditTagMeta({
      ...addEditTagMeta,
      showProgress: true,
    });
    const { initialValues, editMode } = addEditTagMeta;
    const { name } = formData;
    try {
      let apiResponse;
      if (editMode) {
        apiResponse = await tagsServices.updateTag({
          oldName: initialValues?.name,
          newName: name,
        });
      } else {
        apiResponse = await tagsServices.createTag(formData);
      }
      const { response, error } = apiResponse;
      if (error) {
        return toast.error(error);
      }
      setTags((prevAppointment) => {
        const updatedAppointments = updateItemsInArray(prevAppointment, [
          response,
        ]);
        return updatedAppointments;
      });
      toast.success(
        editMode ? "Tag updated successfully" : "Tag added successfully"
      );
    } catch (error) {
      console.log(error);
    } finally {
      setAddEditTagMeta(null);
    }
  };

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between">
        {translate("tags")}{" "}
        {checkNestedPermission([PERMISSIONS.tags.CREATE_ALL_TAGS]) && (
          <Button
            size="sm"
            className="d-flex align-items-center px-1 py-0"
            variant="success text-white"
            onClick={() => setAddEditTagMeta({})}
          >
            <Plus />
            <span className="smallFont">{translate("add")}</span>
          </Button>
        )}
      </Card.Header>
      <Card.Body>
        <DataTable
          rowKey={"_id"}
          columns={tableColumns}
          data={tags}
          loadingFirstPageData={false}
          allowFilter={false}
          allowSort={false}
        />

        <AddEditTagModal
          show={Boolean(addEditTagMeta)}
          initialValues={addEditTagMeta?.initialValues}
          editMode={addEditTagMeta?.editMode}
          onHide={() => setAddEditTagMeta(null)}
          showProgress={addEditTagMeta?.showProgress}
          onSubmit={onSubmitTag}
        />
        <DeleteTagModal
          tagDeleteModalMeta={deleteTagMeta}
          onHide={() => setDeleteTagMeta(null)}
          setDeleteTagMeta={setDeleteTagMeta}
          showProgress={deleteTagMeta?.showProgress}
          tags={tags}
          onContinueClick={deleteTag}
        />
      </Card.Body>
    </Card>
  );
};

export default Tags;
