import { useEffect, useMemo, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../helpers/api";
import {
  getProductTableColumns,
  getContractsTableColumns,
} from "../../helpers/dataSheetConstants";
import { reorder, updateItemsInArray } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import { productService } from "../../services/productService";
import FloatingButton from "../common/FloatingButton";
import DataTable from "../common/data-table/DataTable";
import AddEditContractModal from "./AddEditContractModal";
import DeleteProductModal from "./DeleteProductModal";
import DeleteProductionStepModal from "./DeleteProductionStepModal";
import ProductStageModal from "./ProductStageModal";
import AddEditProductsModal from "./AddEditProductsModal";
import DeleteContractModal from "./DeleteContractModal";
import { swap } from "react-grid-dnd";
import useAuth from "../../hooks/useAuth";
import { PERMISSIONS } from "../../helpers/permissions";
import ArchiveContract from "./ArchiveContract";
import ArchiveProduct from "./ArchiveProduct";
import useAppChoices from "../../hooks/useAppChoices";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseProducts = () => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  //original products array
  const [fetchingContracts, setFetchingContracts] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contractModalMeta, setContractModalMeta] = useState(null);
  const [contractDeleteModalMeta, setContractDeleteModalMeta] = useState(null);

  const [productStageModalMeta, setProductStageModalMeta] = useState(null);
  const [productStageDeleteModalMeta, setProductStageDeleteModalMeta] =
    useState(null);

  const [editingProductionStage, setEditingProductionStage] = useState(null);
  const [newProductionStage, setNewProductionStage] = useState(null);

  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [productModalMeta, setProductModalMeta] = useState(null);
  const [productDeleteModalMeta, setProductDeleteModalMeta] = useState(null);
  const [archivedProducts, setArchivedProducts] = useState([]);
  const [archivedContracts, setArchivedContracts] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const users = useAppChoices("users");

  const onAddNewContractClick = () => {
    setContractModalMeta({});
  };

  const onEditContractClick = (contract) => {
    setContractModalMeta({
      initialValues: contract,
      editMode: true,
      editingContract: contract,
    });
  };

  const onDeleteContractClick = (contract) => {
    let contractsToChooseFrom = contracts
      ?.map((contract) => ({
        label: contract?.description,
        value: contract?._id,
      }))
      ?.filter((p) => p?.value !== contract?._id);

    setContractDeleteModalMeta({
      contract,
      contracts: contracts,
      contractsToChooseFrom,
      anotherContractToAssign:
        contractsToChooseFrom?.[0]?.value || contractsToChooseFrom?.[1]?.value,
    });
  };

  const onProductChange = (product) => {
    setProducts((prevProducts) => {
      const updatedProducts = updateItemsInArray(prevProducts, [product]);
      return updatedProducts;
    });
  };

  const onAddNewProductClick = () => {
    setProductModalMeta({ price: 0 });
  };

  const onEditProductClick = (product) => {
    setProductModalMeta({
      initialValues: product,
      editMode: true,
      editingProduct: product,
    });
  };

  const onDeleteProductClick = (product) => {
    let productsToChooseFrom = products
      ?.map((product) => ({
        label: product?.description,
        value: product?._id,
      }))
      ?.filter((p) => p?.value !== product?._id);

    setProductDeleteModalMeta({
      product,
      products: products,
      productsToChooseFrom,
      anotherProductToAssign: null,
    });
    setRefetch((prev) => !prev);
  };

  const onEditProductStageClick = (product) => {
    setProductStageModalMeta({ product });
  };

  const contractTableColumns = useMemo(
    () =>
      getContractsTableColumns({
        onEditContractClick,
        products: [...products, ...archivedProducts],
        allowEdit: checkNestedPermission([
          PERMISSIONS.products.UPDATE_ALL_PRODUCTS,
        ]),
        allowDelete: checkNestedPermission([
          PERMISSIONS.products.DELETE_ALL_PRODUCTS,
        ]),
      }),
    [onEditContractClick, products]
  );

  const productsTableColumns = useMemo(
    () =>
      getProductTableColumns({
        onEditProductClick,
        onDeleteProductClick,
        onEditProductStageClick,
        users,
        allowEdit: checkNestedPermission([
          PERMISSIONS.products.UPDATE_ALL_PRODUCTS,
        ]),
        allowDelete: checkNestedPermission([
          PERMISSIONS.products.DELETE_ALL_PRODUCTS,
        ]),
      }),
    [onEditProductClick, onDeleteProductClick, onEditProductStageClick]
  );

  const fetchContracts = async () => {
    try {
      setFetchingContracts(true);

      const requestBody = {
        pageSize: 10000,
        pageNumber: 1,
      };
      const { response, error } = await productService.getProducts(requestBody);

      if (error === ABORT_ERROR) return;

      setFetchingContracts(false);

      if (error) {
        return toast.error(error);
      }
      const { results = [] } = response;
      const archivedContracts = results.filter((r) => r.archived);
      const nonArchivedContracts = results.filter((r) => !r.archived);
      setArchivedContracts(archivedContracts);
      setContracts(nonArchivedContracts);
    } catch (error) {}
  };

  const onContractChange = (product) => {
    setContracts((prevProducts) => {
      const updatedProducts = updateItemsInArray(prevProducts, [product]);
      return updatedProducts;
    });
  };

  const onContractSubmit = async (contract) => {
    const { editMode, editingContract } = contractModalMeta;
    setContractModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = editMode
      ? await productService.updateProduct(editingContract._id, contract)
      : await productService.createProduct(contract);
    setContractModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setContractModalMeta(null);
    onContractChange(response);
    toast.success(
      editMode ? "Successfully updated contract" : "Successfully added contract"
    );
  };

  const deleteContract = async () => {
    const { contract, anotherContractToAssign } = contractDeleteModalMeta;
    setContractDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = await productService.deleteProduct(contract, {
      anotherProductToAssign: anotherContractToAssign,
    });
    setContractDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setContractDeleteModalMeta(null);
    setContracts(contracts.filter((p) => p._id !== contract._id));
    toast.success("Successfully deleted product");
  };

  const onEditProductionStageSubmit = async (step) => {
    setProductStageModalMeta({
      ...productStageModalMeta,
      updatingFieldId: step?._id,
    });
    let { product } = productStageModalMeta;
    try {
      const { response, error } = await productService.updateProductStage(
        step?._id,
        { step: step.step }
      );

      if (error) {
        return toast.error(error);
      }
      if (response) {
        product.productionStages = updateItemsInArray(
          product?.productionStages,
          response
        );
        setContracts(updateItemsInArray(contracts, product));
        setProductStageModalMeta({
          ...productStageModalMeta,
          product,
        });
        setEditingProductionStage(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProductStageModalMeta({
        ...productStageModalMeta,
        updatingFieldId: null,
      });
    }
  };

  const onNewProductionStageSubmit = async (step) => {
    setProductStageModalMeta({
      ...productStageModalMeta,
      addingNewStep: true,
    });
    let { product } = productStageModalMeta;
    try {
      let reqBody = {
        step,
        unit: product?._id,
      };
      const { response, error } = await productService.createProductStage(
        reqBody
      );

      if (error) {
        return toast.error(error);
      }
      if (response) {
        product.productionStages = [...product?.productionStages, response];
        setProducts(updateItemsInArray(products, product));
        setProductStageModalMeta({
          ...productStageModalMeta,
          product,
        });
        setNewProductionStage(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProductStageModalMeta({
        ...productStageModalMeta,
        addingNewStep: false,
      });
    }
  };

  const onDeleteProductionStepClick = (step) => {
    let { product } = productStageModalMeta;
    setProductStageDeleteModalMeta({
      step,
      product,
      anotherProductionStageToAssign: null,
    });
  };

  const deleteProductStep = async () => {
    setProductStageDeleteModalMeta({
      ...productStageDeleteModalMeta,
      showProgress: true,
    });
    let { product, anotherProductionStageToAssign } =
      productStageDeleteModalMeta;

    try {
      const { step } = productStageDeleteModalMeta;
      const { response, error } = await productService.deleteProductStage(
        step?._id,
        { anotherProductionStageToAssign }
      );

      if (error) {
        return toast.error(error);
      }
      if (response) {
        let updatedProductSteps = product?.productionStages?.filter(
          (step) => step?._id !== response?.id
        );
        product.productionStages = updatedProductSteps;
        setContracts(updateItemsInArray(contracts, product));
        setProductStageModalMeta({
          ...productStageModalMeta,
          product,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProductStageDeleteModalMeta(null);
    }
  };

  const onProductStepDragAndDrop = async (result) => {
    let { product } = productStageModalMeta;
    if (!result.destination) {
      return;
    }
    const oldData = [...product?.productionStages];
    const updatedProductSteps = reorder(
      oldData,
      result.source.index,
      result.destination.index
    );
    product.productionStages = updatedProductSteps;
    setProducts(updateItemsInArray(products, product));
    setProductStageModalMeta({
      ...productStageModalMeta,
      product,
    });
    try {
      const { response, error } = await productService.updateUnit(product._id, {
        productionStages: updatedProductSteps?.map((pS) => pS?._id),
      });

      if (error) {
        return toast.error(error);
      }
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      setFetchingProducts(true);

      const requestBody = {
        pageSize: 10000,
        pageNumber: 1,
      };
      const { response, error } = await productService.getUnits(requestBody);

      if (error === ABORT_ERROR) return;

      setFetchingProducts(false);

      if (error) {
        return toast.error(error);
      }
      const { results = [] } = response;
      const archivedProducts = results.filter((r) => r.archived);
      const nonArchivedProducts = results.filter((r) => !r.archived);
      setArchivedProducts(archivedProducts);
      setProducts(nonArchivedProducts);
    } catch (error) {}
  };

  const onProductSubmit = async (product) => {
    const { editMode, editingProduct } = productModalMeta;
    setProductModalMeta((meta) => ({ ...meta, showProgress: true }));
    const { response, error } = editMode
      ? await productService.updateUnit(editingProduct._id, product)
      : await productService.createUnit(product);
    setProductModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setProductModalMeta(null);
    onProductChange(response);
    toast.success(
      editMode ? "Successfully updated product" : "Successfully added product"
    );
  };

  const deleteProduct = async () => {
    const { product, anotherProductToAssign } = productDeleteModalMeta;
    setProductDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = await productService.deleteUnit(product, {
      anotherUnitToAssign: anotherProductToAssign,
    });
    setProductDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setProductDeleteModalMeta(null);
    setProducts(products.filter((p) => p._id !== product._id));
    toast.success("Successfully deleted product");
  };
  const [updatingContractOrders, setUpdatingContractOrders] = useState(false);

  const onDragEnd = async ({ destination, source }) => {
    let sourceIndex = source?.index;
    let targetIndex = destination?.index;

    let oldContracts = [...contracts];
    const newContracts = swap(contracts, sourceIndex, targetIndex);
    setContracts(newContracts);

    try {
      setUpdatingContractOrders(true);
      const { response, error } = await productService.updateMultiple(
        newContracts.map((o, index) => ({
          _id: o._id,
          productUpdates: { index: index },
        }))
      );
      if (error) {
        setContracts(oldContracts);
        return toast.error(error);
      }
      if (response) {
        toast.success("Successfully updated contracts");
      }
      setUpdatingContractOrders(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onArchiveProductClick = async (product) => {
    await onProductSubmit(product);
    setRefetch((prev) => !prev);
  };

  const onArchiveContractClick = async (contract) => {
    await onContractSubmit(contract);
    setRefetch((prev) => !prev);
  };

  useEffect(() => {
    fetchContracts();
    fetchProducts();
  }, [refetch]);

  return (
    <>
      <Container fluid className={"px-2 py-3 h-100"}>
        <div className="p-2 mb-5" style={{ marginBottom: 100 }}>
          <Accordion defaultActiveKey={"0"} className="mb-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {translate("contracts")} ({contracts.length})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  rowKey={"_id"}
                  columns={contractTableColumns}
                  data={contracts}
                  loadingFirstPageData={fetchingContracts}
                  allowFilter={false}
                  allowSort={false}
                  draggable={checkNestedPermission([
                    PERMISSIONS.products.UPDATE_ALL_PRODUCTS,
                  ])}
                  onDragEnd={onDragEnd}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {translate("archived_contracts")} ({archivedContracts.length})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  rowKey={"_id"}
                  columns={contractTableColumns}
                  data={archivedContracts}
                  loadingFirstPageData={fetchingContracts}
                  allowFilter={false}
                  allowSort={false}
                  draggable={checkNestedPermission([
                    PERMISSIONS.products.UPDATE_ALL_PRODUCTS,
                  ])}
                  onDragEnd={onDragEnd}
                />
                <hr className="my-2" />
                <div className="d-flex">
                  <ArchiveContract />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey={"0"}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {translate("products")} ({products.length})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  rowKey={"_id"}
                  columns={productsTableColumns}
                  data={products}
                  loadingFirstPageData={fetchingProducts}
                  allowFilter={false}
                  allowSort={false}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {translate("archived_products")} ({archivedProducts.length})
              </Accordion.Header>
              <Accordion.Body>
                <DataTable
                  rowKey={"_id"}
                  columns={productsTableColumns}
                  data={archivedProducts}
                  loadingFirstPageData={fetchingProducts}
                  allowFilter={false}
                  allowSort={false}
                />
                <hr className="my-2" />
                <div className="d-flex">
                  <ArchiveProduct />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div style={{ height: 80 }}></div>
        </div>
      </Container>
      {checkNestedPermission([PERMISSIONS.products.CREATE_ALL_PRODUCTS]) && (
        <>
          <FloatingButton
            className="text-white mx-2"
            Icon={PlusCircleFill}
            variant="success"
            text={translate("add_new_contract")}
            onClick={onAddNewContractClick}
          />
          <FloatingButton
            className="text-white"
            Icon={PlusCircleFill}
            variant="success"
            text={translate("add_new_product")}
            onClick={onAddNewProductClick}
            style={{ right: 160 }}
          />
        </>
      )}
      <AddEditContractModal
        show={Boolean(contractModalMeta)}
        initialValues={contractModalMeta?.initialValues}
        editMode={contractModalMeta?.editMode}
        productOption={[...products, ...archivedProducts]}
        onHide={() => setContractModalMeta(null)}
        showProgress={contractModalMeta?.showProgress}
        onSubmit={onContractSubmit}
        archiveContract={onArchiveContractClick}
        allowDelete={checkNestedPermission([
          PERMISSIONS.products.DELETE_ALL_PRODUCTS,
        ])}
        onDeleteContractClick={onDeleteContractClick}
      />
      <AddEditProductsModal
        show={Boolean(productModalMeta)}
        initialValues={productModalMeta?.initialValues}
        editMode={productModalMeta?.editMode}
        onHide={() => setProductModalMeta(null)}
        showProgress={productModalMeta?.showProgress}
        onSubmit={onProductSubmit}
        archiveProduct={onArchiveProductClick}
        allowDelete={checkNestedPermission([
          PERMISSIONS.products.DELETE_ALL_PRODUCTS,
        ])}
        onDeleteProductClick={onDeleteProductClick}
      />
      <ProductStageModal
        show={Boolean(productStageModalMeta)}
        onHide={() => setProductStageModalMeta(null)}
        productStageModalMeta={productStageModalMeta}
        productionStages={productStageModalMeta?.product?.productionStages}
        onEditProductionStageSubmit={onEditProductionStageSubmit}
        onNewProductionStageSubmit={onNewProductionStageSubmit}
        onDeleteProductionStepClick={onDeleteProductionStepClick}
        onProductStepDragAndDrop={onProductStepDragAndDrop}
        newProductionStage={newProductionStage}
        setNewProductionStage={setNewProductionStage}
        setEditingProductionStage={setEditingProductionStage}
        editingProductionStage={editingProductionStage}
      />
      {contracts.length > 0 && (
        <DeleteContractModal
          show={Boolean(contractDeleteModalMeta)}
          onHide={() => setContractDeleteModalMeta(false)}
          onDismissClick={() => setContractDeleteModalMeta(false)}
          contractDeleteModalMeta={contractDeleteModalMeta}
          setContractDeleteModalMeta={setContractDeleteModalMeta}
          showProgress={contractDeleteModalMeta?.showProgress}
          onContinueClick={deleteContract}
        />
      )}
      {products.length > 0 && (
        <DeleteProductModal
          show={Boolean(productDeleteModalMeta)}
          onHide={() => setProductDeleteModalMeta(false)}
          onDismissClick={() => setProductDeleteModalMeta(false)}
          productDeleteModalMeta={productDeleteModalMeta}
          setProductDeleteModalMeta={setProductDeleteModalMeta}
          showProgress={productDeleteModalMeta?.showProgress}
          onContinueClick={deleteProduct}
        />
      )}
      <DeleteProductionStepModal
        show={Boolean(productStageDeleteModalMeta)}
        onHide={() => setProductStageDeleteModalMeta(null)}
        onDismissClick={() => setProductStageDeleteModalMeta(null)}
        productStageDeleteModalMeta={productStageDeleteModalMeta}
        setProductStageDeleteModalMeta={setProductStageDeleteModalMeta}
        showProgress={productStageDeleteModalMeta?.showProgress}
        onContinueClick={deleteProductStep}
      />
    </>
  );
};

export default BrowseProducts;
