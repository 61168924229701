import React, { useCallback, useMemo } from "react";
import useAppChoices from "../../../hooks/useAppChoices";
import { FormCheck } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import useLocalization from "../../../hooks/useLocalization";
import { startCase } from "lodash";

const AppointmentTypeFilter = ({
  appointmentTypeFilter,
  setAppointmentTypeFilter,
  openedFilters,
  setOpenedFilters,
}) => {
  const { translate } = useLocalization();

  const appointmentDefaultsToShow = useAppChoices("appointmentDefaults");

  const isFilterOpen = useCallback(
    (filter) => openedFilters.includes(filter),
    [openedFilters]
  );

  const handleSelectAll = () => {
    const newFilter = appointmentDefaultsToShow.map((a) => a.key);

    if (appointmentTypeFilter.length === newFilter.length) {
      setAppointmentTypeFilter([]);
    } else {
      setAppointmentTypeFilter(newFilter);
    }
  };

  const handleCheckboxToggle = (key) => {
    if (appointmentTypeFilter.includes(key)) {
      setAppointmentTypeFilter(
        appointmentTypeFilter.filter((item) => item !== key)
      );
    } else {
      setAppointmentTypeFilter([...appointmentTypeFilter, key]);
    }
  };

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between align-items-center mx-1 mb-1">
        <h6 className="large fw-bold mb-0">{translate("type")}</h6>
        <div className="d-flex align-items-center gap-2">
          <div
            className="d-flex align-items-center hover"
            onClick={handleSelectAll}
          >
            <FormCheck
              checked={
                appointmentDefaultsToShow.length ===
                appointmentTypeFilter.length
              }
              onChange={handleSelectAll}
              className=""
              style={{
                fontSize: 16,
              }}
              id={"select-all"}
            />
            <h6 className={`mb-0 mx-1 mid truncate`}>
              {translate("select_all")}
            </h6>
          </div>
          {isFilterOpen("appointment_type") ? (
            <ChevronUp
              className="hover"
              onClick={() =>
                setOpenedFilters(
                  openedFilters.filter((f) => f !== "appointment_type")
                )
              }
            />
          ) : (
            <ChevronDown
              className="hover"
              onClick={() =>
                setOpenedFilters([...openedFilters, "appointment_type"])
              }
            />
          )}
        </div>
      </div>
      {isFilterOpen("appointment_type") && (
        <>
          {appointmentDefaultsToShow.map(({ key, title }) => (
            <div
              style={{
                margin: 2,
              }}
              key={key}
              className="hover p-1 d-flex align-items-center border"
              onClick={() => handleCheckboxToggle(key)}
            >
              <FormCheck
                style={{
                  height: "10px",
                  marginBottom: 2,
                }}
                className="hover d-flex align-items-center py-0"
                type="checkbox"
                checked={appointmentTypeFilter.includes(key)}
                onChange={() => handleCheckboxToggle(key)}
              />
              <h6 className="my-0 mx-2 mid truncate">{startCase(key)}</h6>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default AppointmentTypeFilter;
