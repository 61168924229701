import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import AddEditContractForm from "../forms/AddEditContractForm";

const AddEditContractModal = ({
  show,
  initialValues,
  editMode,
  productOption,
  onHide,
  showProgress,
  onSubmit,
  archiveContract,
  allowDelete,
  onDeleteContractClick,
}) => {
  const { translate } = useLocalization();

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={
        // editMode ? translate(`update_product`) : translate(`add_new_product`)
        editMode ? translate(`update_contract`) : translate(`add_new_contract`)
      }
      showCloseButton={!showProgress}
    >
      <AddEditContractForm
        initialValues={initialValues}
        showProgress={showProgress}
        productOption={productOption}
        editMode={editMode}
        onSubmit={onSubmit}
        archiveContract={archiveContract}
        allowDelete={allowDelete}
        onDeleteContractClick={onDeleteContractClick}
      />
    </AppModal>
  );
};

export default AddEditContractModal;
