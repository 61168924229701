import React from "react";
import { Link } from "react-router-dom";
import { CRM_APP_URL, RUJUM_APP_URL } from "../../../helpers/constants";
import {
  EnvelopeAt,
  Eye,
  EyeSlash,
  PersonGear,
  Trash,
} from "react-bootstrap-icons";
import RujumIcon from "../../../assets/RujumAppIcon.png";
import { Badge } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";

const ViewActions = ({
  allowEdit,
  allowDelete,
  sendPasswordResetLink,
  onAddEditPermissionButtonClick,
  onArchiveUserClick,
  onUserDeleteClick,
  user,
  allowMessageLink = false,
  showPerson = true,
  showUrl = true,
  showEye = true,
}) => {
  const { translate } = useLocalization();
  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      {showUrl && (
        <>
          <Link
            onClick={(e) => e.stopPropagation()}
            title="View CRM"
            to={`${CRM_APP_URL}/contacts?as=${user._id}`}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className="mx-1 bg-dark text-white d-flex align-items-center justify-content-center tiny px-1 rounded"
              style={{ height: 15, width: "fit-content" }}
            >
              CRM
            </div>
          </Link>

          <Link
            onClick={(e) => e.stopPropagation()}
            title="View Rujum"
            to={`${RUJUM_APP_URL}/admin/manage-users/customer?as=${user.email}`}
            target="_blank"
            className="mx-1"
            style={{ textDecoration: "none" }}
          >
            <img height={15} src={RujumIcon} alt="Rujum" />
          </Link>
        </>
      )}
      {allowMessageLink && (
        <Badge
          role="button"
          bg="info"
          onClick={(e) => {
            sendPasswordResetLink(user);
            e.stopPropagation();
          }}
        >
          <div className="d-flex gap-2 align-items-center">
            <span>Send Reset Password Link</span>
            <EnvelopeAt size={15} />
          </div>
        </Badge>
      )}
      {allowEdit && (
        <>
          {showPerson && (
            <PersonGear
              className="text-success hover-light mx-1"
              size={15}
              onClick={(e) => {
                onAddEditPermissionButtonClick(user);
                e.stopPropagation();
              }}
            />
          )}
          {showEye && (
            <>
              {user?.archived ? (
                <Badge
                  role="button"
                  bg="primary"
                  onClick={(e) => {
                    onArchiveUserClick(user);
                    e.stopPropagation();
                  }}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <span>{translate("unarchive")}</span>
                    <Eye size={15} />
                  </div>
                </Badge>
              ) : (
                <Badge
                  role="button"
                  bg="purple"
                  onClick={(e) => {
                    onArchiveUserClick(user);
                    e.stopPropagation();
                  }}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <span>{translate("archive")}</span>
                    <EyeSlash size={15} />
                  </div>
                </Badge>
              )}
            </>
          )}
        </>
      )}
      {allowDelete && (
        <Badge
          role="button"
          bg="danger"
          onClick={(e) => {
            onUserDeleteClick(user);
            e.stopPropagation();
          }}
        >
          <div className="d-flex gap-2 align-items-center">
            <span>{translate("delete")}</span>
            <Trash size={15} />
          </div>
        </Badge>
      )}
    </div>
  );
};

export default ViewActions;
