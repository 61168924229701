import React, { useMemo, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getStatusesColumns } from "../../../helpers/dataSheetConstants";
import { updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { statusServices } from "../../../services/statusService";
import DataTable from "../../common/data-table/DataTable";
import AddEditStatusModal from "./AddEditStatusModal";
import DeleteStatusModal from "./DeleteStatusModal";
import { swap } from "react-grid-dnd";
import { STATUS_CATEGORIES } from "../../../helpers/constants";
import CircularProgressBar from "../../common/circular-progress";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const Statuses = ({ className = "", statuses, setStatuses }) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  const [addEditStatusMeta, setAddEditStatusMeta] = useState(null);
  const [deleteStatusMeta, setDeleteStatusMeta] = useState(null);
  const [disableDragging, setDisableDragging] = useState(false);

  const statusAccordingToCategory = useMemo(() => {
    const groups = STATUS_CATEGORIES.map((c) => ({
      category: c.category,
      status: statuses
        .filter((s) => s.category === c.category)
        .map((s, index) => ({ ...s, index })),
    }));
    return groups;
  }, [statuses]);

  const onEditStatusClick = (status) => {
    setAddEditStatusMeta({ initialValues: status, editMode: true });
  };

  const onDeleteStatusClick = (statusToDelete) => {
    setDeleteStatusMeta({
      statusToDelete,
      alternativeStatus: statuses?.filter(
        (status) => status._id !== statusToDelete?._id
      )[0]?.value,
    });
  };
  const tableColumns = useMemo(
    () =>
      getStatusesColumns({
        onEditStatusClick,
        onDeleteStatusClick,
        allowEdit: checkNestedPermission([
          PERMISSIONS.status.UPDATE_ALL_STATUS,
        ]),
        allowDelete: checkNestedPermission([
          PERMISSIONS.status.DELETE_ALL_STATUS,
        ]),
      }),
    [onEditStatusClick, onDeleteStatusClick]
  );

  const deleteStatus = async (deleteInfo) => {
    let { statusToDelete, alternativeStatus } = deleteInfo;

    try {
      setDeleteStatusMeta({ ...deleteStatusMeta, showProgress: true });
      const { response, error } = await statusServices.deleteStatus({
        _id: statusToDelete?._id,
        ...(alternativeStatus === "unassign" ? {} : { alternativeStatus }),
      });
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success(response.message);
      }
      const newStatuses = statuses.filter((t) => t._id !== statusToDelete._id);
      setStatuses(newStatuses);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteStatusMeta(null);
    }
  };

  const onSubmitStatus = async (formData) => {
    setAddEditStatusMeta({
      ...addEditStatusMeta,
      showProgress: true,
    });
    const { editMode } = addEditStatusMeta;
    try {
      let apiResponse;
      if (editMode) {
        apiResponse = await statusServices.updateStatus({
          _id: formData?._id,
          english: formData?.english,
          hebrew: formData?.hebrew,
          category: formData?.category,
        });
      } else {
        apiResponse = await statusServices.createStatus(formData);
      }

      const { response, error } = apiResponse;

      if (error) {
        return toast.error(error);
      }

      let updatedStatus;

      if (editMode) {
        updatedStatus = updateItemsInArray(statuses, [response]);
      } else {
        let destinationStatusArray = statusAccordingToCategory.find(
          (s) => s.category === response.category
        ).status;

        destinationStatusArray.push(response);

        const updatedCategory = {
          category: response.category,
          status: destinationStatusArray,
        };

        updatedStatus = updateItemsInArray(
          statusAccordingToCategory,
          updatedCategory,
          "category"
        ).flatMap((s) => s.status);
      }

      setStatuses(updatedStatus);

      toast.success(
        editMode ? "Status updated successfully" : "Status added successfully"
      );
    } catch (error) {
      console.log(error);
    } finally {
      setAddEditStatusMeta(null);
    }
  };

  const onStatusDragEnd = async ({ destination, source }) => {
    try {
      let sourceIndex = source?.index;
      let targetIndex = destination?.index;
      let updatedStatus;

      if (destination.droppableId === source.droppableId) {
        let destinationStatusArray = statusAccordingToCategory.find(
          (s) => s.category === destination.droppableId
        ).status;

        const newStatuses = swap(
          destinationStatusArray,
          sourceIndex,
          targetIndex
        );

        const updatedCategory = {
          category: destination.droppableId,
          status: newStatuses,
        };

        updatedStatus = updateItemsInArray(
          statusAccordingToCategory,
          updatedCategory,
          "category"
        ).flatMap((s) => s.status);
      } else {
        let sourceStatusArray = statusAccordingToCategory.find(
          (s) => s.category === source.droppableId
        ).status;

        let destinationStatusArray = statusAccordingToCategory.find(
          (s) => s.category === destination.droppableId
        ).status;

        const deletedSourceStatus = sourceStatusArray.splice(sourceIndex, 1)[0];
        deletedSourceStatus.category = destination.droppableId;

        destinationStatusArray.splice(targetIndex, 0, deletedSourceStatus);

        const sourceStatusCategory = {
          category: source.droppableId,
          status: sourceStatusArray,
        };
        const destinationStatusCategory = {
          category: destination.droppableId,
          status: destinationStatusArray,
        };

        updatedStatus = updateItemsInArray(
          statusAccordingToCategory,
          [sourceStatusCategory, destinationStatusCategory],
          "category"
        ).flatMap((s) => s.status);
      }

      setStatuses(updatedStatus);
      setDisableDragging(true);
      let apiResponse = await statusServices.updateMultiple(
        updatedStatus.map((o, index) => ({
          _id: o._id,
          statusUpdated: { index, category: o.category },
        }))
      );

      const { response, error } = apiResponse;

      if (error) {
        setStatuses(statusAccordingToCategory.flatMap((s) => s.status));
        return toast.error(error);
      }

      toast.success("Status updated successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setDisableDragging(false);
    }
  };

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between">
        {translate("status")}{" "}
        <div className="d-flex gap-2 align-items-center">
          {disableDragging && <CircularProgressBar />}
          {checkNestedPermission([PERMISSIONS.status.CREATE_ALL_STATUS]) && (
            <Button
              size="sm"
              className="d-flex align-items-center px-1 py-0"
              variant="success text-white"
              disabled={disableDragging}
              onClick={() => setAddEditStatusMeta({})}
            >
              <Plus />
              <span className="smallFont">{translate("add")}</span>
            </Button>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <h6 className="smallFont">
          {translate("status_groups_lowest_to_highest")}
        </h6>
        <DataTable
          maxTableHeight="1000px"
          rowKey={"_id"}
          columns={tableColumns}
          data={statuses}
          loadingFirstPageData={false}
          allowFilter={false}
          allowSort={false}
          draggable
          disableDragging={
            disableDragging ||
            !checkNestedPermission([PERMISSIONS.status.UPDATE_ALL_STATUS])
          }
          onDragEnd={onStatusDragEnd}
          groups={STATUS_CATEGORIES}
          groupBy={"category"}
        />

        <AddEditStatusModal
          show={Boolean(addEditStatusMeta)}
          initialValues={addEditStatusMeta?.initialValues}
          editMode={addEditStatusMeta?.editMode}
          onHide={() => setAddEditStatusMeta(null)}
          showProgress={addEditStatusMeta?.showProgress}
          onSubmit={onSubmitStatus}
        />
        <DeleteStatusModal
          statusDeleteModalMeta={deleteStatusMeta}
          onHide={() => setDeleteStatusMeta(null)}
          setDeleteStatusMeta={setDeleteStatusMeta}
          showProgress={deleteStatusMeta?.showProgress}
          statuses={statuses}
          onContinueClick={deleteStatus}
        />
      </Card.Body>
    </Card>
  );
};

export default Statuses;
