import { useContext } from "react";
import { ContactAndDealPopupContext } from "../context/ContactAndDealPopupContext";

const useContactAndDealPopup = () => {
  const {
    openContactPopup,
    activeContact,
    hideContactPopup,
    mergeContactModalMeta,
    setMergeContactModalMeta,
    openDealPopup,
    hideDealPopup,
    activeDeal,
    updatedContact,
    updatedDeal,
    referrals,
    onCreateNewReferralClick,
    createdReferral,
  } = useContext(ContactAndDealPopupContext);

  return {
    openContactPopup,
    activeContact,
    hideContactPopup,
    mergeContactModalMeta,
    setMergeContactModalMeta,
    openDealPopup,
    hideDealPopup,
    activeDeal,
    updatedContact,
    updatedDeal,
    referrals,
    onCreateNewReferralClick,
    createdReferral,
  };
};

export default useContactAndDealPopup;
