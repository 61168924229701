import AppModal from "../common/AppModal";
import useLocalization from "../../hooks/useLocalization";
import AddEditUserPermissionForm from "../forms/AddEditUserPermissionForm";
import HorizontalProgress from "../common/HorizontalProgress";
import { Button, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { snakeCase } from "lodash";
import { specialRolesEnum } from "../../helpers/constants";

export default function AddEditUserPermissionModal({
  show,
  user,
  onHide,
  showProgress,
  onSubmit,
  onRevertPermissionClick,
}) {
  const { translate } = useLocalization();
  const [permissions, setPermissions] = useState(user?.permissions || []);
  const [specialRoles, setSpecialRoles] = useState(user?.specialRoles || null);

  useEffect(() => {
    setPermissions(user?.permissions?.permissions || []);
    setSpecialRoles(user?.specialRoles || []);
  }, [user]);

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={translate(`edit_permissions`, { name: user?.name })}
      showCloseButton={!showProgress}
      modalDialogClassName="h-100 my-0"
      modalContentClassName="d-flex flex-column h-100 justify-self-center "
      modalBodyClassName="flex-grow-1 px-2"
      footerContent={() => (
        <>
          <hr className="my-2" />
          <div className="text-end">
            <Button
              variant="success text-white"
              onClick={() => onSubmit({ permissions, specialRoles })}
              size="sm"
              disabled={showProgress}
            >
              {translate(showProgress ? "updating" : "update")}
            </Button>
          </div>
        </>
      )}
    >
      <Card>
        <Card.Header>CRM permissions</Card.Header>
        <Card.Body>
          <AddEditUserPermissionForm
            role={user?.role}
            permissions={permissions}
            onPermissionsChange={setPermissions}
            onRevertPermissionClick={onRevertPermissionClick}
          />
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Header>Rujum permissions</Card.Header>
        <Card.Body>
          <select
            className={`form-control form-control-sm truncate w-25`}
            style={{ fontSize: 12 }}
            onChange={(e) =>
              setSpecialRoles({
                ...specialRoles,
                app: e.target.value,
              })
            }
            value={specialRoles?.app}
          >
            <option value="" disabled>
              {translate(snakeCase("please_select_one"))}
            </option>
            {specialRolesEnum.map((option) => {
              return (
                <option value={option} key={option}>
                  {option}
                </option>
              );
            })}
          </select>
        </Card.Body>
      </Card>
    </AppModal>
  );
}
