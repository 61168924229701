import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import useLocalization from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import { APP_CHOICES_KEY } from "../../helpers/constants";
import { appChoiceService } from "../../services/appChoiceService";
import CircularProgressBar from "../common/circular-progress";
import AlertModal from "../common/AlertModal";
import { PERMISSIONS } from "../../helpers/permissions";
import { toast } from "react-toastify";

const ArchiveProduct = () => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  const [showArchivedProducts, setShowArchivedProducts] = useState(false);
  const [confirmationModalMeta, setConfirmationModalMeta] = useState(null);
  const [fetching, setFetching] = useState(false);

  const toggleSwitch = (checked) => {
    setConfirmationModalMeta({
      ...showArchivedProducts,
      values: [
        {
          value: checked,
        },
      ],
    });
  };

  const getArchivedProductSetting = async () => {
    try {
      setFetching(true);
      const reqBody = {
        keys: "appChoices",
        appChoiceFilter: {
          key: APP_CHOICES_KEY.SHOW_ARCHIVED_UNITS,
        },
      };
      const { response, error } = await appChoiceService.getAppchoices(reqBody);

      if (error) {
        toast.error(error);
        return;
      }
      const appChoices = response;
      setShowArchivedProducts(
        appChoices.find((a) => a.key === APP_CHOICES_KEY.SHOW_ARCHIVED_UNITS)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const handleArchivedProduct = async () => {
    try {
      setConfirmationModalMeta({
        ...confirmationModalMeta,
        showProgress: true,
      });

      const { response, error } = await appChoiceService.updateAppchoice(
        confirmationModalMeta
      );

      if (error) {
        setConfirmationModalMeta({
          ...confirmationModalMeta,
          showProgress: false,
        });
        toast.error(error);
        return;
      }
      toast.success("Archive user setting changed succesfully");
      setConfirmationModalMeta(null);
      setShowArchivedProducts(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (checkNestedPermission([PERMISSIONS.app_choice.VIEW_ALL_APP_CHOICE])) {
      getArchivedProductSetting();
    }
  }, []);

  if (!checkNestedPermission([PERMISSIONS.app_choice.VIEW_ALL_APP_CHOICE])) {
    return null;
  }

  return (
    <>
      <div className="d-flex align-items-center my-2 gap-2">
        <h6 className="smallFont flex-grow-1 mb-0">
          {translate("show_archived_products")}
        </h6>
        {fetching ? (
          <CircularProgressBar />
        ) : (
          <Switch
            checked={showArchivedProducts?.values?.[0]?.value}
            className="ps-1"
            onChange={toggleSwitch}
            height={15}
            width={40}
            offHandleColor="#d1e6cc"
            onHandleColor="#d1e6cc"
            disabled={
              !checkNestedPermission([
                PERMISSIONS.app_choice.UPDATE_ALL_APP_CHOICE,
              ])
            }
          />
        )}
      </div>
      <AlertModal
        show={confirmationModalMeta}
        onContinueClick={handleArchivedProduct}
        onDismissClick={() => setConfirmationModalMeta(null)}
        onHide={() => setConfirmationModalMeta(null)}
        alertText={translate("confirm_app_config_archive_product", {
          show: showArchivedProducts?.values?.[0]?.value
            ? translate("hide_small")
            : translate("show_small"),
        })}
      />
    </>
  );
};

export default ArchiveProduct;
