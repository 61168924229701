import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Col,
  FormCheck,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import * as Yup from "yup";
import useContacts from "../../hooks/useContacts";
import useLocalization from "../../hooks/useLocalization";
import ContactOverviewCard from "../common/ContactOverviewCard";
import HorizontalProgress from "../common/HorizontalProgress";
import SelectContactModal from "../common/SelectContactModal";
import ProductStructureTable from "../common/ProductStructureTable";
import useAppChoices from "../../hooks/useAppChoices";

const validationSchema = Yup.object().shape({
  productId: Yup.string().required("Please select a contract"),
});

const ContractSendForm = ({ products, contact, onSubmit, showProgress }) => {
  const units = useAppChoices("units");
  const { translate } = useLocalization();
  const [initialValues, setInititalValues] = useState(null);
  const [openSelectContactModal, setOpenSelectContactModal] = useState(false);

  const [selectedContact, setSelectedContact] = useState(null);

  const { contacts, loadingContacts, setContactQuery } = useContacts();

  const findIfProductHasLink = useCallback(
    (productId) => {
      let product = products?.find((p) => p._id === productId);
      return Boolean(product?.templateLink);
    },
    [products]
  );

  const onContactSelect = (contact) => {
    setSelectedContact(contact);
    setOpenSelectContactModal(false);
  };

  useEffect(() => {
    setInititalValues({
      productId: products[0]?._id ?? "",
      units: products[0]?.units ?? [],
      proposalQuote: products[0]?.total,
      discount: products[0]?.discount,
      price: products[0]?.total,
      sendImmediately: false,
      sendContract: true,
      ...(contact && { contactId: contact._id }),
    });
  }, [products, contact]);

  const contactHasNoEmail = useMemo(() => {
    const emails = contact?.emails || selectedContact?.emails;
    return emails ? !emails.length : false;
  }, [contact, selectedContact]);

  return (
    initialValues && (
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            if (!contact && !selectedContact) {
              return setFieldError("contact", "Please select a contact");
            }

            onSubmit({
              ...values,
              ...(!contact && { contactId: selectedContact?._id }),
            });

            setSubmitting(false); // Ensure form is no longer submitting
          }}
        >
          {({ setFieldValue, handleSubmit, values, errors }) => {
            const populatedUnits = units.map((u) => {
              const unit = initialValues?.units.find(
                (newUnit) => newUnit.unit === u._id
              );
              return {
                ...u,
                assignedTo: unit?.assignedTo || [],
              };
            });
            return (
              <Form className="p-1" onSubmit={handleSubmit}>
                <Row className="my-0">
                  {!contact && (
                    <Col xs={12}>
                      <FormGroup className="mb-1">
                        <FormLabel className="mb-0 mid">
                          {translate("contact")}
                        </FormLabel>
                        <div>
                          {selectedContact && (
                            <ContactOverviewCard contact={selectedContact} />
                          )}

                          <Button
                            className="px-2 py-0 text-white"
                            variant="dark"
                            size="sm"
                            onClick={() => setOpenSelectContactModal(true)}
                          >
                            {translate("select_contact")}
                          </Button>
                        </div>

                        {errors?.contact && (
                          <h6 className="smallFont text-danger mb-0">
                            {errors?.contact}
                          </h6>
                        )}
                      </FormGroup>
                      <SelectContactModal
                        show={openSelectContactModal}
                        onHide={() => setOpenSelectContactModal(false)}
                        contacts={contacts}
                        showProgress={loadingContacts}
                        setContactQuery={setContactQuery}
                        onContactSelect={onContactSelect}
                        selectedContact={selectedContact}
                      />
                    </Col>
                  )}
                  {contactHasNoEmail && (
                    <Col xs={12} className="mt-1">
                      {" "}
                      <Alert variant="danger" className="p-1 smallFont m-0">
                        {translate("Please_add_an_email_to_this_contact")}
                      </Alert>
                    </Col>
                  )}

                  <Col xs={12}>
                    <FormGroup className="mb-1">
                      <FormLabel className="mb-1 mid">
                        {translate("contracts")}
                      </FormLabel>
                      <Field
                        name="productId"
                        as="select"
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          const selectedProduct = products.find(
                            (product) => product._id === e.currentTarget.value
                          );
                          setFieldValue(
                            "productId",
                            selectedProduct ? selectedProduct._id : ""
                          );
                          if (selectedProduct) {
                            setFieldValue("units", selectedProduct.units);
                            setFieldValue(
                              "proposalQuote",
                              selectedProduct.total
                            );
                            setFieldValue("price", selectedProduct.total);
                            setFieldValue("discount", selectedProduct.discount);
                          }
                        }}
                      >
                        {products.map((product) => (
                          <option key={product._id} value={product._id}>
                            {product.description}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="productId"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12}>
                    <FormGroup className="mb-1">
                      <FormLabel className="mb-1 mid">
                        {translate("products")}
                      </FormLabel>
                      <ProductStructureTable
                        products={values?.units}
                        onProductChange={(units) =>
                          setFieldValue("units", units)
                        }
                        productOptions={populatedUnits}
                        discount={values?.discount}
                        onDiscountChange={(discount) =>
                          setFieldValue("discount", discount)
                        }
                        totalPrice={values?.proposalQuote}
                        onTotalPriceChange={(price) => {
                          setFieldValue("price", price);
                          setFieldValue("proposalQuote", price);
                        }}
                        isAssignedTo={true}
                      />
                      <ErrorMessage
                        name="quote"
                        component="div"
                        className="text-danger mid"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {showProgress && (
                  <HorizontalProgress text={`${translate("please_wait")}`} />
                )}
                <div className="d-flex align-items-center justify-content-between mt-2">
                  {values?.productId ? (
                    findIfProductHasLink(values?.productId) ? (
                      <FormCheck
                        checked={values?.sendContract}
                        label={translate("send_contract")}
                        onChange={(e) => {
                          setFieldValue("sendContract", e.target.checked);
                        }}
                        type="checkbox"
                        className=""
                      />
                    ) : (
                      <h6 className="mb-0 smallFont fw-bold">
                        {translate("this_product_doesnot_have_link")}
                      </h6>
                    )
                  ) : (
                    ""
                  )}

                  <div className="d-flex ">
                    <Button
                      disabled={showProgress || contactHasNoEmail}
                      variant="success text-white"
                      size="sm"
                      type="submit"
                      onClick={() => setFieldValue("sendImmediately", true)}
                    >
                      {translate(
                        values?.sendContract &&
                          findIfProductHasLink(values?.productId)
                          ? "send_contract"
                          : "create_new_deal"
                      )}
                    </Button>
                    <div className="mx-2" />
                    {findIfProductHasLink(values?.productId) &&
                      values?.sendContract && (
                        <Button
                          disabled={showProgress || contactHasNoEmail}
                          variant="secondary"
                          size="sm"
                          type="button"
                          onClick={() => {
                            setFieldValue("sendImmediately", false);
                            handleSubmit();
                          }}
                        >
                          {translate("edit_contract")}
                        </Button>
                      )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    )
  );
};

export default ContractSendForm;
