import { snakeCase } from "lodash";
import React, { useMemo } from "react";
import { Form, FormControl, FormSelect } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../../helpers/constants";
import { getDateWithTimezone } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import CustomMultiSelect from "../CustomMultiSelect";
import EditableTagsInput from "../EditableTagsInput";
import TimeIntervalInWeek from "../TimeIntervalInWeek";
import Title from "./Title";

const getFormControlType = ({ type, isMultiple }) => {
  if (isMultiple) {
    return "tags-input";
  }

  const typeToFormControlTypeMap = {
    url: "text",
    currency: "number",
    datetime: "datetime-local",
  };

  return typeToFormControlTypeMap[type] || type || "text";
};

const getFormControlValue = (field, data, formControlType) => {
  const key = field.key;
  const value = data[key];
  switch (formControlType) {
    case "date":
    case "datetime-local":
      return value ? new Date(value) : null;
    case "dropdown":
      return value ? value?._id : null; // TODO need to handle other cases
    case "multi-select":
      return value ? value : null;
    case "number":
    case "price":
    case "email":
    case "text":
    default:
      return value || (field.isMultiple ? [] : "");
  }
};

const getNewValue = (field, value, formControlType) => {
  let valueUpdated;
  switch (formControlType) {
    case "date":
      valueUpdated = value ? getDateWithTimezone(value) : null;
      break;
    default:
      valueUpdated = value;
      break;
  }

  return valueUpdated;
};

const InputCell = ({
  data,
  onChange,
  field,
  className = "",
  disabled,
  style,
}) => {
  const { isRTL, translate } = useLocalization();
  const formControlType = useMemo(() => getFormControlType(field), [field]);
  const { options = [] } = field;
  const handleTagChange = (tags) => {
    onChange(field, getNewValue(field, tags, formControlType));
  };
  const currentValue = useMemo(
    () => getFormControlValue(field, data, formControlType),
    [field, data, formControlType]
  );

  const valuesInSingleArray = field["valuesInSingleArray"] ?? false;

  return (
    <div className={className}>
      {field?.customField ? (
        field?.customField(data, onChange)
      ) : formControlType === "dropdown" ? (
        <FormSelect
          size="sm"
          style={{
            fontSize: "12px",
          }}
          value={getFormControlValue(field, data, formControlType)}
          disabled={disabled}
          onChange={(e) => {
            let value = e.target.value;
            let newVal = value === "unassign" ? null : value;
            onChange(field, newVal);
          }}
        >
          {options.map(({ id, name }) => (
            <option className="smallFont" key={id} value={id}>
              {name}
            </option>
          ))}
        </FormSelect>
      ) : formControlType === "grouped-dropdown" ? (
        <CustomMultiSelect
          disabled={disabled}
          items={field.options}
          selectedItems={getFormControlValue(field, data, formControlType)}
          onChange={(option) => onChange(field, option)}
          isMulti={Boolean(field.isMultiSelect)}
          isGroupped
          placeholder={field?.placeholder || "Select"}
          fieldColors={field?.options
            .flatMap((o) => o?.options)
            ?.find(
              (option) =>
                option?.value ===
                getFormControlValue(field, data, formControlType)
            )}
          closeMenuOnSelect={!Boolean(field.isMultiSelect)}
        />
      ) : formControlType === "multi-select" ? (
        <CustomMultiSelect
          items={field.options}
          disabled={disabled}
          selectedItems={
            getFormControlValue(field, data, formControlType) || []
          }
          onChange={(selectedOptions) => onChange(field, selectedOptions)}
          isClearable={field.isClearable ?? true}
          isMulti={Boolean(field.isMultiSelect)}
          closeMenuOnSelect={!Boolean(field.isMultiSelect)}
        />
      ) : formControlType === "tags-input" ? (
        <>
          <EditableTagsInput
            tags={getFormControlValue(field, data, formControlType)}
            onTagsChange={handleTagChange}
            disabled={disabled}
            fullWidthTag
            autoLowerCase={field?.key === "emails"}
          />
        </>
      ) : formControlType === "followUpDate" ? (
        <div className="">
          <Title field={field} editMode style={{ fontSize: "14px" }} />
          <div className="d-flex my-2">
            <TimeIntervalInWeek
              disabled={disabled}
              className=""
              style={{ marginTop: "-8px" }}
              initialDate={
                // getFormControlValue(field, data, "date") ??
                new Date()
              }
              onChange={(newDate) => onChange(field, newDate)}
            />
          </div>
          <DatePicker
            wrapperClassName="w-100"
            className={`${
              isRTL ? "px-4" : ""
            }   mb-0 text-dark px-2 py-1 form-control form-control-sm ${className}`}
            selected={getFormControlValue(field, data, "datetime-local")}
            dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
            onChange={(e) => {
              onChange(
                field,
                e ? getNewValue(field, e.toISOString(), "datetime-local") : null
              );
            }}
            openToDate={new Date()} // Opens the datepicker to the current month and year
            showYearDropdown
            showMonthDropdown
            showTimeInput
            dropdownMode="scroll"
            timeFormat="HH:mm" // 24-hour format
            popperClassName="datepickerPopperClassName"
            style={style}
            disabled={disabled}
            clearButtonClassName="text-dark"
            portalId="root"
            isClearable
          />
        </div>
      ) : formControlType === "boolean" ? (
        <Form.Check
          disabled={disabled}
          type="checkbox"
          checked={getFormControlValue(field, data, formControlType)}
          onChange={(e) =>
            onChange(
              field,
              getNewValue(field, e.target.checked, formControlType)
            )
          }
          label={translate(snakeCase(field.label)) || field.label}
          size="sm"
        />
      ) : formControlType === "radio" ? (
        <div
          className={`row row-cols-${field.col} mx-1 px-3 py-1 border rounded bg-white`}
        >
          {options.map(({ value, label }) => (
            <Form.Check
              key={value}
              disabled={disabled}
              type="radio"
              label={label}
              name={field.key}
              checked={currentValue === value}
              onClick={(e) => {
                // If the new value is the same as the current value, reset it
                const updatedValue = currentValue === value ? null : value;

                onChange(field, updatedValue);
              }}
              className={isRTL ? "form-check form-check-reverse" : ""}
            />
          ))}
        </div>
      ) : formControlType === "checkbox-group" ? (
        <div
          className={`row row-cols-${field.col} mx-1 px-3 py-1 border rounded bg-white`}
        >
          {options.map(({ value, label }) => {
            return (
              <Form.Check
                key={value}
                disabled={disabled}
                type="checkbox"
                label={label}
                value={value}
                checked={
                  valuesInSingleArray
                    ? (currentValue[0] || "")
                        .split(",")
                        .map((item) => item.trim())
                        .includes(value)
                    : currentValue?.includes(value)
                }
                onChange={(e) => {
                  let updatedValue;
                  if (e.target.checked) {
                    updatedValue = valuesInSingleArray
                      ? [
                          ...new Set([
                            currentValue[0] ? `${currentValue[0]}, ${value}` : value
                          ]),
                        ]
                      : [...new Set([...currentValue, value])];
                  } else {
                    updatedValue = valuesInSingleArray
                      ? [ 
                        currentValue[0]
                        ?.split(",")
                        .map((item) => item.trim())
                        .filter((v) => v !== value)
                        .join(", ")
                        ]
                      : currentValue.filter((v) => v !== value);
                  }
                  onChange(
                    field,
                    getNewValue(field, updatedValue, formControlType)
                  );
                }}
                className={isRTL ? "form-check form-check-reverse" : ""}
                style={{ cursor: "pointer" }}
              />
            );
          })}
        </div>
      ) : (
        <FormControl
          className={`mb-0 text-dark px-2 py-0 ${className}`}
          as={field.as || "input"}
          style={{
            fontSize: "12px",
          }}
          rows={4}
          disabled={disabled}
          type={formControlType}
          value={getFormControlValue(field, data, formControlType)}
          onChange={(e) =>
            onChange(field, getNewValue(field, e.target.value, formControlType))
          }
          size="sm"
        />
      )}
    </div>
  );
};

export default InputCell;
