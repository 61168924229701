import { useState, useEffect } from "react";
import { contactService } from "../services/contactService";
import { availabilityService } from "../services/availabilityService";
import { toast } from "react-toastify";
import useAuth from "./useAuth";
import { PERMISSIONS } from "../helpers/permissions";

const useContacts = (defaultQuery = "") => {
  const { user, checkNestedPermission } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [contactQuery, setContactQuery] = useState(defaultQuery);

  const getContacts = async () => {
    try {
      setLoadingContacts(true);
      const requestBody = {
        pageSize: 50,
        pageNumber: 1,
        query: contactQuery,
        sort: {
          updatedAt: -1,
        },
      };
      const { response, error } = await contactService.getContacts(requestBody);
      setLoadingContacts(false);
      if (error) {
        toast.error(error);
        return;
      }
      setContacts(response.results);
    } catch (error) {
      setLoadingContacts(false);
      console.error(error);
      toast.error("An error occurred while fetching contacts.");
    }
  };

  const getRecentContactsFromAppointments = async () => {
    if (
      !checkNestedPermission([PERMISSIONS.appointments.VIEW_ALL_APPOINTMENTS])
    ) {
      return;
    }
    try {
      setLoadingContacts(true);
      const reqBody = {
        pageNumber: 1,
        pageSize: 20,
        withContacts: true,
        sort: { createdAt: -1 },
        filter: {
          createdBy: user._id,
        },
        returnGoogleEvents: false,
      };

      const { response, error } = await availabilityService.search(reqBody);
      setLoadingContacts(false);
      if (error) {
        toast.error(error);
        return;
      }

      let appointments = response?.results?.appEvents || [];

      let contactMap = new Map();

      // Iterate through the appointments to populate the map with unique contacts
      appointments.forEach((a) => {
        if (a?.contact?._id) {
          contactMap.set(a.contact._id, a.contact);
        }
      });

      // Convert the map back to an array of unique contact objects
      let uniqueContacts = Array.from(contactMap.values());

      setContacts(uniqueContacts);
    } catch (error) {
      setLoadingContacts(false);
      console.error(error);
      toast.error("An error occurred while fetching contacts.");
    }
  };

  useEffect(() => {
    if (contactQuery) {
      getContacts();
    } else {
      getRecentContactsFromAppointments();
    }
  }, [contactQuery]);

  return {
    contacts,
    loadingContacts,
    contactQuery,
    setContactQuery,
  };
};

export default useContacts;
