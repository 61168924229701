import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import AddEditUserForm from "../forms/AddEditUserForm";

const AddEditUserModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  onSubmit,
  onUserDeleteClick,
  sendPasswordResetLink,
  refreshUser,
  onAddEditPermissionButtonClick,
  archiveFilter,
  onArchiveUserClick,
  allowEdit,
  allowDelete,
}) => {
  const { translate } = useLocalization();

  const onFormSubmit = (user) => {
    const userToSubmit = {
      ...user,
    };

    onSubmit && onSubmit(userToSubmit);
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={editMode ? translate(`update_user`) : translate(`add_new_user`)}
      showCloseButton={!showProgress}
    >
      <AddEditUserForm
        initialValues={initialValues}
        showProgress={showProgress}
        editMode={editMode}
        onSubmit={onFormSubmit}
        onUserDeleteClick={onUserDeleteClick}
        sendPasswordResetLink={sendPasswordResetLink}
        refreshUser={refreshUser}
        onAddEditPermissionButtonClick={onAddEditPermissionButtonClick}
        archiveFilter={archiveFilter}
        onArchiveUserClick={onArchiveUserClick}
        allowEdit={allowEdit}
        allowDelete={allowDelete}
      />
    </AppModal>
  );
};

export default AddEditUserModal;
