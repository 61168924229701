import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  Button,
  ButtonGroup,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import {
  ArrowLeftShort,
  ArrowRightShort,
  Cake2Fill,
} from "react-bootstrap-icons";
import {
  convertToScrollToTime,
  getTextColor,
  isPreviousMonth,
  isSameDate,
  isToday,
  isWeekend,
} from "../../../helpers/global";
import useAppChoices from "../../../hooks/useAppChoices";
import useLocalization from "../../../hooks/useLocalization";
import { CustomEvent } from "../../common/CustomEvent";
import CircularProgressBar from "../../common/circular-progress";

const DateHeader = ({ cellDate, birthDayBoys, label, onDrillDown }) => {
  const today = moment(); // Get today's date
  const calendarCellDate = moment(cellDate);
  function getBirthdayMessage(name, birthdayDate) {
    const birthday = moment(birthdayDate); // Get the birthday date

    // Create a moment object with the birthday's month and day but using the current year
    const birthdayThisYear = moment({
      year: today.year(),
      month: birthday.month(),
      day: birthday.date(),
    });

    if (calendarCellDate.isAfter(today, "day")) {
      return `${name}'s Birthday is coming up on ${birthday.format(
        "MMMM Do"
      )}. Get ready to celebrate! 🎉`;
    } else if (calendarCellDate.isBefore(today, "day")) {
      return `${name} celebrated their birthday on ${birthday.format(
        "MMMM Do"
      )}. We hope it was a fantastic day! 🎉`;
    } else if (birthdayThisYear.isSame(today, "day")) {
      return `It's ${name}'s Birthday today! Let's celebrate with best wishes and joy! 🎉`;
    }
  }

  return (
    <div className="w-100 py-1 pe-0 ps-2 d-flex align-items-center justify-content-between flex-wrap">
      <h5 className="smallFont mb-0 hover" /* onClick={onDrillDown} */>
        {label}
      </h5>
      {birthDayBoys.length > 0 && (
        <div className="d-flex gap-1 align-items-center">
          {birthDayBoys.map((b) => {
            let backgroundColor = b?.color || "#007f7f";
            let textColor = getTextColor(backgroundColor);
            return (
              <OverlayTrigger
                key={b._id}
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={
                  <Popover
                    id="popover-trigger-hover-focus"
                    title="Popover bottom"
                    style={{ width: 500 }}
                  >
                    <Popover.Header className="bg-white ">
                      <h6 className="mb-0 large fw-bold">
                        {b.name}`s Birthday! 🥳{" "}
                      </h6>
                    </Popover.Header>
                    <Popover.Body>
                      <h6 className="large">
                        {getBirthdayMessage(b.name, b.birthDate)}
                      </h6>
                    </Popover.Body>
                  </Popover>
                }
              >
                <div
                  key={b._id}
                  style={{
                    background: backgroundColor,
                    color: textColor,
                    borderRadius: 10,
                    height: 15,
                    width: 15,
                    zIndex: 2000,
                  }}
                  className="hover d-flex align-items-center justify-content-center tiny"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {b.name[0].toUpperCase()}
                </div>
              </OverlayTrigger>
            );
          })}
          <Cake2Fill className="text-warning" />
        </div>
      )}
    </div>
  );
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const calculateEventWidth = (eventCount, containerWidth) => {
  let eventWidth = containerWidth / eventCount;
  if (eventWidth < 50) {
    eventWidth = 50; // Minimum width for readability
  }
  return eventWidth;
};

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const smallScreenWidth = 576;
const mediumScreenWidth = 860;

function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1);
}

const getMessages = ({ activeView, screenWidth, locale, translate }) => ({
  next:
    activeView !== "day"
      ? activeView === "week"
        ? `Next ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `Next ${capitalizeFirstLetter(activeView)}`
      : "Next",
  previous:
    activeView !== "day"
      ? activeView === "week"
        ? `Previous ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `Previous ${capitalizeFirstLetter(activeView)}`
      : "Previous",
  today:
    activeView !== "day"
      ? activeView === "week"
        ? `This ${screenWidth <= mediumScreenWidth ? "3 days" : "Week"}`
        : `This ${capitalizeFirstLetter(activeView)}`
      : "Today",
  week: screenWidth <= mediumScreenWidth ? "3 days" : "Week",
  showMore: (total, events) => {
    return (
      <Button
        variant="info"
        size="sm"
        className="mx-1 px-1 py-0 d-flex align-items-center"
      >
        <span className="tiny">
          +{total} {translate("more")}{" "}
        </span>
      </Button>
    );
  },
});

const AppointmentCalendar = ({
  onSlotSelect,
  events = [],
  mode = "picker",
  allowEditing,
  onPlanDateTimeChange,
  customActiveView,
  customStep,
  onSessionDragAndDrop,
  onCurrentlyActiveViewOnCalender,
  onAppointmentClick,
  timeAndDateToScroll,
  initialSmallScreenView,
  initialLargeScreenView,
  calendarStyles,
  locale = "en",
  activeEvent,
  onShowMoreClick,
  renderMoreInToolbar,
  onDateChange,
  fetchingEvents = false,
  calendarDate,
  setCalendarDate,
  compactMode,
}) => {
  const calendarRef = useRef(null);
  const dayRefs = useRef({});
  const users = useAppChoices("users");
  const { translate, isRTL } = useLocalization();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectable, setSelectable] = useState(false);
  const [resizable, setResizable] = useState(false);
  const [activeView, setActiveView] = useState(
    screenWidth >= smallScreenWidth
      ? initialLargeScreenView || "week"
      : initialSmallScreenView || "day"
  );
  const [multiTouch, setMultiTouch] = useState(false);
  const [scrollToTime, setScrollToTime] = useState(new Date());

  const SHOW_ARCHIVE_USERS = useAppChoices("SHOW_ARCHIVE_USERS");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (SHOW_ARCHIVE_USERS?.[0]?.value === false) {
      setFilteredUsers(users.filter((u) => !u.archived));
    } else {
      setFilteredUsers(users);
    }
  }, [SHOW_ARCHIVE_USERS, users]);

  const preventTouchZoom = (e) => {
    if (e.touches.length > 1) {
      setMultiTouch(true);
      e.stopPropagation();
    }
  };

  const handleTouchEnd = (e) => {
    if (e.touches.length <= 1) {
      setTimeout(() => {
        setMultiTouch(false);
      }, 500);
    }
  };

  useEffect(() => {
    const calendarElement = calendarRef.current;

    if (calendarElement) {
      calendarElement.addEventListener("touchmove", preventTouchZoom, {
        passive: false,
      });

      calendarElement.addEventListener("touchend", handleTouchEnd, {
        passive: true,
      });
    }

    return () => {
      if (calendarElement) {
        calendarElement.removeEventListener("touchmove", preventTouchZoom);
        calendarElement.removeEventListener("touchend", handleTouchEnd);
      }
    };
  }, []);

  const handleEventClick = (event) => {
    if (multiTouch) return;
    const { start } = event;

    const dayKey = moment(start).format("YYYY-MM-DD");
    const dayRef = dayRefs.current[dayKey];

    let boxInfo;
    if (dayRef) {
      const rect = dayRef.getBoundingClientRect();
      // Calculate whether to position the popup on the left or right
      boxInfo = {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        yOffSet: rect.height,
        xOffSet: rect.width,
      };
    }
    onAppointmentClick && onAppointmentClick(event, boxInfo);
  };

  const handleShowMoreClick = (events) => {
    const date = events[events.length - 1]?.start;

    const dayKey = moment(date).format("YYYY-MM-DD");
    const dayRef = dayRefs.current[dayKey];

    let boxInfo;
    if (dayRef) {
      const rect = dayRef.getBoundingClientRect();
      // Calculate whether to position the popup on the left or right
      boxInfo = {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        yOffSet: rect.height,
        xOffSet: rect.width,
      };
    }
    onShowMoreClick && onShowMoreClick(events, boxInfo);
  };

  const onSlotSelectInternal = (slot) => {
    if (multiTouch) return;
    const { start } = slot;

    const dayKey = moment(start).format("YYYY-MM-DD");
    const dayRef = dayRefs.current[dayKey];

    let boxInfo;
    if (dayRef) {
      const rect = dayRef.getBoundingClientRect();
      // Calculate whether to position the popup on the left or right
      boxInfo = {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        yOffSet: rect.height,
        xOffSet: rect.width,
      };
    }
    onSlotSelect && onSlotSelect(slot, boxInfo);
  };

  const handleNavigate = (newDate) => {
    setCalendarDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  const slotPropGetter = (date) => ({
    style: {
      fontSize: 11,
      zIndex: mode === "picker" ? 1 : undefined,
      display: "flex",
    },
    className: `${selectable ? "hover hover-light" : ""} ${
      slotHasMultipleAppointments(date, events) ? "red-border-right" : ""
    }`,
  });

  const eventPropGetter = useCallback(
    (event) => {
      return {
        style: {
          fontSize: 11,
          zIndex: 10,
          cursor: "pointer",
          width: "95%",
          padding: "0px",
          marginLeft: 5,
          marginBottom: 2,
          borderRadius: 5,
          background: "white",
        },
      };
    },
    [activeEvent]
  );

  const dayPropGetter = useCallback(
    (day) => {
      const isActiveDay =
        !activeEvent?._id && isSameDate(activeEvent?.date, day);

      const isPastDay = moment(day).isBefore(moment(), "day");

      const isDashedBackground =
        isWeekend(day) || isPreviousMonth(day, calendarDate) || isPastDay;

      return {
        style: {
          margin: 0,
          zIndex: 9,
          height: activeView === "month" ? "100%" : null,
          fontSize: 11,
          border: isActiveDay
            ? "2px solid #28a745"
            : isToday(day)
            ? "2px solid red"
            : null,
          padding: "3px",
          backgroundColor: isDashedBackground ? "#f7f7f7" : "",
          backgroundImage: isDashedBackground
            ? "repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 10px, #f7f7f7 10px, #f7f7f7 20px)"
            : "",
        },
      };
    },
    [activeEvent, calendarDate, activeView]
  );

  const onEventDropInternal = (dropEvent) => {
    dropEvent.end.setTime(dropEvent.end.getTime() + 1);

    if (
      slotHasOverlappingAppointments(
        dropEvent.start,
        dropEvent.end,
        events,
        dropEvent.event
      )
    )
      return;
    onSessionDragAndDrop && onSessionDragAndDrop(dropEvent);
    onPlanDateTimeChange && onPlanDateTimeChange(dropEvent);
  };

  const onEventResizeInternal = (resizeEvent) => {
    if (resizeEvent.end.getTime() - resizeEvent.start.getTime() < 15 * 60000) {
      return;
    }
    onSessionDragAndDrop && onSessionDragAndDrop(resizeEvent);
    if (
      slotHasOverlappingAppointments(
        resizeEvent.start,
        resizeEvent.end,
        events,
        resizeEvent.event
      )
    )
      return;
    onPlanDateTimeChange && onPlanDateTimeChange(resizeEvent);
  };

  const onViewInternal = (view) => {
    setActiveView(view);
    customActiveView && onCurrentlyActiveViewOnCalender(view);
  };

  const messages = useMemo(
    () => getMessages({ activeView, screenWidth, locale, translate }),
    [activeView, screenWidth, locale, translate]
  );

  useEffect(() => {
    if (timeAndDateToScroll) {
      setActiveView("week");
      setCalendarDate(timeAndDateToScroll);
      setScrollToTime(convertToScrollToTime(timeAndDateToScroll));
    }
  }, [timeAndDateToScroll]);

  useEffect(() => {
    setSelectable(allowEditing);
    setResizable(allowEditing);
  }, [mode, allowEditing, activeView]);

  const ToolBar = ({ date, label, onNavigate, onView, view, views }) => {
    const getLabel = () => {
      if (view === "day") {
        // day month date
        const formattedDate = moment(date).format("ddd MMMM DD");
        const terms = formattedDate.split(" ");
        const day = translate(terms[0].toLowerCase()) || terms[0];
        const month = translate(terms[1].toLowerCase()) || terms[1];
        return `${day} ${month} ${terms[2]}`;
      }

      const [month, rest] = label.split(" ");
      return `${translate(month.toLowerCase()) || month} ${rest}`;
    };
    return (
      <Row
        className={`border-start border-end d-flex  flex-wrap  align-items-center m-0`}
      >
        <Col
          xs={5}
          md={2}
          className="d-flex justify-content-between align-items-center gap-1 p-2"
        >
          {renderMoreInToolbar && renderMoreInToolbar()}
          <Button
            size="sm"
            variant={"dark text-white"}
            className="px-1 py-0"
            style={{ width: "150px" }}
            onClick={(e) => onNavigate("TODAY")}
          >
            <span className="smallFont">{translate("today")}</span>
          </Button>
          <div />
        </Col>

        <Col
          xs={7}
          md={6}
          className="d-flex justify-content-center align-items-center p-2"
        >
          <Button
            size="sm"
            variant={"outline-dark"}
            className="px-1 py-0 d-flex justify-content-center align-items-center"
            style={{ width: "50px" }}
            onClick={(e) => onNavigate("PREV")}
          >
            {isRTL ? (
              <ArrowRightShort size={20} />
            ) : (
              <ArrowLeftShort size={20} />
            )}
          </Button>
          <div
            className="d-flex align-items-center justify-content-center smallFont"
            style={{ width: 120 }}
          >
            {getLabel(label, date, view)}
          </div>
          <Button
            size="sm"
            variant={"outline-dark"}
            className="px-1 py-0 d-flex justify-content-center align-items-center"
            style={{ width: "50px" }}
            onClick={(e) => onNavigate("NEXT")}
          >
            {isRTL ? (
              <ArrowLeftShort size={20} />
            ) : (
              <ArrowRightShort size={20} />
            )}
          </Button>
        </Col>

        <Col md={4} className="p-2">
          <ButtonGroup className={`d-flex  ${isRTL && "flex-row-reverse"}`}>
            {views.map((v) => (
              <Button
                size="sm"
                variant={v === view ? "dark" : "outline-dark"}
                className="px-1 py-0"
                style={{ width: "100px" }}
                onClick={(e) => onView(v)}
                key={v}
              >
                <span className="smallFont">{translate(v)}</span>
              </Button>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    );
  };

  const DayWrapper = ({ value, children }) => {
    const dayKey = moment(value).format("YYYY-MM-DD");
    return (
      <div
        ref={(el) => {
          dayRefs.current[dayKey] = el;
        }}
        className="w-100 border-end"
      >
        {children}
      </div>
    );
  };

  return (
    <div ref={calendarRef} className="position-relative">
      {fetchingEvents && (
        <div
          className="position-absolute  start-0 w-100 d-flex justify-content-center align-items-center bg-white bg-opacity-50"
          style={{ top: 50, zIndex: 10, height: "calc(100% - 50px" }}
        >
          <CircularProgressBar size={20} />
        </div>
      )}
      {resizable ? (
        <DragAndDropCalendar
          views={["month", "week", "day"]}
          startAccessor="start"
          endAccessor="end"
          drilldownView="day"
          localizer={localizer}
          style={calendarStyles}
          messages={messages}
          events={events}
          step={customStep ?? 15}
          timeslots={1}
          scrollToTime={scrollToTime}
          view={activeView}
          selectable={selectable}
          resizable={resizable}
          date={calendarDate}
          dayPropGetter={dayPropGetter}
          slotPropGetter={slotPropGetter}
          eventPropGetter={eventPropGetter}
          formats={formats}
          longPressThreshold={20}
          elementProps={{
            style: {
              fontSize: 10,
            },
          }}
          onSelectEvent={handleEventClick}
          onView={onViewInternal}
          onSelectSlot={onSlotSelectInternal}
          onShowMore={handleShowMoreClick}
          onEventDrop={onEventDropInternal}
          onNavigate={handleNavigate}
          components={{
            event: (props) => {
              return (
                <CustomEvent
                  {...props}
                  activeEvent={activeEvent}
                  view={activeView}
                  compactMode={compactMode}
                  // markPastEvents
                  // pastEventOpacity="opacity-75"
                />
              );
            },
            toolbar: ToolBar,
            dateCellWrapper: DayWrapper,
            header: ({ date, label }) => {
              return <h5 className="smallFont fw-bold mb-0">{label}</h5>;
            },
            month: {
              header: ({ date, label }) => {
                return (
                  <h5 className="smallFont fw-bold mb-0">
                    {translate(label.toLowerCase()) || label}
                  </h5>
                );
              },
              dateHeader: ({ date, label, onDrillDown }) => {
                const birthDayBoys = filteredUsers.filter((u) => {
                  if (!u.birthDate) return false;

                  const birthDate = moment(u.birthDate);
                  const momentDate = moment(date);
                  return (
                    birthDate.isValid() &&
                    birthDate.date() === momentDate.date() &&
                    birthDate.month() === momentDate.month()
                  );
                });

                return (
                  <DateHeader
                    birthDayBoys={birthDayBoys}
                    label={label}
                    onDrillDown={onDrillDown}
                    cellDate={date}
                  />
                );
              },
            },
            week: {
              header: ({ label }) => {
                const [date, day] = label.split(" ");
                return (
                  <h5 className="smallFont fw-bold mb-0">
                    {date} {translate(day.toLowerCase()) || day}
                  </h5>
                );
              },
            },
          }}
          popup={false}
          doShowMoreDrillDown={false}
          onEventResize={onEventResizeInternal}
          culture={locale}
          rtl={isRTL}
        />
      ) : (
        <Calendar
          style={calendarStyles}
          defaultView={activeView}
          views={["day", "week", "month"]}
          step={customStep || 15}
          events={events}
          defaultDate={calendarDate}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                timeAndDateToScroll={timeAndDateToScroll}
                view={activeView}
              />
            ),

            toolbar: ToolBar,
          }}
          messages={messages}
          localizer={localizer}
          selectable={selectable}
          scrollToTime={scrollToTime}
          dayPropGetter={dayPropGetter}
          eventPropGetter={eventPropGetter}
          slotPropGetter={slotPropGetter}
          onSelectSlot={onSlotSelectInternal}
          onNavigate={handleNavigate}
          onView={onViewInternal}
        />
      )}
    </div>
  );
};

const slotFallsOutsideAllowedHours = (start, end, dayStartHour, dayEndHour) => {
  return (
    start.getHours() * 60 + start.getMinutes() < dayStartHour * 60 ||
    end.getHours() * 60 + end.getMinutes() < dayStartHour * 60 ||
    start.getHours() * 60 + start.getMinutes() > dayStartHour * 60 ||
    end.getHours() * 60 + end.getMinutes() > dayEndHour * 60
  );
};

const slotHasOverlappingAppointments = (
  start,
  end,
  appointments,
  currentPlan
) => {
  let comparingappointments = currentPlan
    ? appointments.filter((p) => p !== currentPlan)
    : appointments;
  return comparingappointments.some(
    (p) =>
      end.getTime() > p.start.getTime() && p.end.getTime() > start.getTime()
  );
};

const slotHasMultipleAppointments = (slotDate, appointments) => {
  const slotStart = slotDate;
  const slotEnd = new Date(slotDate);
  slotEnd.setMinutes(slotEnd.getMinutes() + 14, 59, 999);

  return (
    appointments.filter(
      (p) =>
        p.start.getTime() >= slotStart.getTime() &&
        p.start.getTime() <= slotEnd.getTime()
    ).length > 1
  );
};

export default AppointmentCalendar;
