import React from "react";
import FormSection from "../common/FormSection";
import DealComissionForm from "../forms/DealCommissionForm";
import { Button, Col, Row } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import DealPaymentForm from "../forms/DealPaymentForm";
import { Cash, ChatQuote } from "react-bootstrap-icons";
import { commissionTypes } from "../../helpers/constants";
import CircularProgressBar from "../common/circular-progress";
import useAuth from "../../hooks/useAuth";
import { PERMISSIONS } from "../../helpers/permissions";

const EditCommissionsForm = ({
  onClose,
  onSaveClick,
  updatingContract,
  contract,
  onDealChange,
  referrals = [],
  referredBy = null,
}) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();

  return (
    <div className="p-2">
      {" "}
      <Row className="">
        <Col md={5}>
          <FormSection Icon={ChatQuote} title={translate("payments")}>
            <DealPaymentForm
              disabled={
                updatingContract ||
                !contract?.editable ||
                !checkNestedPermission([
                  PERMISSIONS.contract_advanced_info
                    .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                ])
              }
              contract={contract}
              onChange={onDealChange}
              onAddNewPayment={() => {
                let refferedBy =
                  contract?.contact?.referredBy?._id ||
                  contract?.contact?.referredBy;

                let newCommission;

                if (refferedBy) {
                  newCommission = {
                    type: commissionTypes[1],
                    assignedTo: refferedBy,
                    commission: 0,
                  };
                  onDealChange({
                    commissions: [...contract?.commissions, newCommission],
                  });
                }
              }}
            />
          </FormSection>
        </Col>
        <Col md={7}>
          <FormSection Icon={Cash} title={translate("commissions")}>
            <DealComissionForm
              disabled={
                updatingContract ||
                !contract?.editable ||
                !checkNestedPermission([
                  PERMISSIONS.contract_advanced_info
                    .UPDATE_ALL_CONTRACT_ADVANCED_INFO,
                ])
              }
              contract={contract}
              onChange={onDealChange}
              referrals={referrals}
              referredBy={referredBy}
            />
          </FormSection>
        </Col>
        <Col className="d-flex pt-2 gap-2 justify-content-end">
          {" "}
          <Button
            className="text-white"
            disabled={updatingContract}
            variant="danger"
            size="sm"
            onClick={onClose}
          >
            <h6 className="mb-0 smallFont">{translate("close")}</h6>
          </Button>
          {contract?.editable && (
            <Button
              className="text-white d-flex gap-2 align-items-center"
              disabled={updatingContract}
              variant="success"
              size="sm"
              onClick={onSaveClick}
            >
              {updatingContract && (
                <CircularProgressBar size={12} className="m-0 p-0" />
              )}
              <h6 className="mb-0 smallFont">
                {translate(updatingContract ? "saving" : "save")}
              </h6>
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditCommissionsForm;
