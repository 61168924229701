import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please provide name"),
  email: Yup.string(),
});

const defaultValues = {
  name: "",
  email: "",
  phone: null,
  type: "payable",
};

const AddReferralForm = ({
  initialValues = defaultValues,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form noValidate className="p-2">
            <Row>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("name")}
                  </FormLabel>
                  <Field
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("type")}
                  </FormLabel>
                  <Field
                    as="select"
                    name="type"
                    className="form-control form-control-sm"
                    size="sm"
                  >
                    <option value="payable">{translate("payable")}</option>
                    <option value="non-payable">
                      {translate("nonpayable")}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("email")}
                  </FormLabel>
                  <Field
                    name="email"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("phone_number")}
                  </FormLabel>
                  <Field
                    name="phone"
                    type="text"
                    className="form-control form-control-sm"
                    size="sm"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <div className="text-end">
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                <h6 className="smallFont mb-0"> {translate("add")}</h6>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddReferralForm;
