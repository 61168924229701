import { Badge } from "react-bootstrap";
import AsyncCreatableSelect from "../components/common/AsyncCreatableSelect";
import PersonSelectOption from "../components/common/PersonSelectOption";
import PersonSelectValue from "../components/common/PersonSelectValue";
import { GROUPPED_USER_BASED_ON_ROLE } from "./global";
import { isAdmin, isAdminOrManager } from "./session";

export const APP_URL = process.env.PUBLIC_URL;

export const BRAND_NAME = "Ethica CRM";
export const RUJUM_APP_URL = process.env.REACT_APP_MAIN_APP_URL;
export const CRM_APP_URL = process.env.PUBLIC_URL;

export const CONTACT_QS = "contact";
export const DEAL_QS = "deal";
export const CALENDAR_QS = "calendar";
export const REFERER_QS = "referrer";
export const USER_RECORD_QS = "user-record";
export const ENDPOINTS = {
  //Users
  LOGIN: "/users/login",
  REGISTER: "/users/register",
  LOGOUT: "/users/logout",
  FORGOT_PASSWORD: "/users/send-password-reset-link",
  RESET_PASSWORD: "/users/reset-password",
  CHANGE_PASSWORD: "/users/change-password",
  GENERATE_REGISTRATION_OTP: "/users/generate-registration-otp",
  GET_ME: "/users/me",
  GET_USER_BY_ID: (id) => `/users/${id}`,
  GET_USER_BY_USER_ID: (id) => `/users/user/${id}`,
  UPDATE_USER_DETAILS: "/users/update-user-details",
  CREATE_USER: "/users",
  DELETE_USER: "/users",
  UPDATE_MULTIPLE_USERS: "/users",
  USERS_LIST: "/users/search",
  RESET_USER_PERMISSIONS: (id) => `/users/reset-permissions-by-role/${id}`,

  // whatsapp templates
  GET_WHATSAPP_TEMPLATES: "/whatsapp-templates/search",
  CREATE_WHATSAPP_TEMPLATE: "/whatsapp-templates",
  WHATSAPP_TEMPLATE_WITH_ID: (id) => `/whatsapp-templates/${id}`,

  // whatsapp templates
  GET_EMAIL_TEMPLATES: "/email-templates/search",
  CREATE_EMAIL_TEMPLATE: "/email-templates",
  EMAIL_TEMPLATE_WITH_ID: (id) => `/email-templates/${id}`,

  // whatsapp campaigns
  GET_WHATSAPP_CAMPAIGNS: "/whatsapp-list-campaigns/search",
  CREATE_WHATSAPP_CAMPAIGN: "/whatsapp-list-campaigns",
  WHATSAPP_CAMPAIGN_WITH_ID: (id) => `/whatsapp-list-campaigns/${id}`,

  // google drive
  GET_FILES_OR_FOLDERS: ({ folder, query }) =>
    `/google-drive/list${folder && `?folder=${folder}`}${
      query && `?q=${query}`
    }`,
  CREATE_NEW_FOLDER: "/google-drive/create-folder",
  DELETE_FILE_OR_FOLDER: (id) => `/google-drive/delete/${id}`,
  UPLOAD_FILES: "/google-drive/upload",
  // user record
  CLOCK_IN: "/time-logs/clockin",
  CLOCK_OUT: "/time-logs/clockout",
  RESET_TIME: "/time-logs/extend-timer",
  GET_USER_TIME_LOG: "/time-logs/search",
  CREATE_USER_TIME_LOG: "/time-logs",
  USER_TIME_LOG_WITH_ID: (id) => `/time-logs/${id}`,
  GET_USER_RECORDS: (userId) => `/users/${userId}/record`,
  UPDATE_USER_RECORDS_COMMENTS: (userId) => `/users/${userId}/record/comments`,
  DOWNLOAD_USER_LOG_CSV: "/time-logs/csv",
  // user stats
  GET_USER_STATS: "/time-logs/stats",

  //user files
  SEARCH_USER_FILES: "/users/files/search",
  CREATE_USER_FILES: "/users/files",
  USER_FILES_ID: (id) => `/users/files/${id}`,

  //raferral
  SEARCH_REFERRALS: "/referee/search",
  CREATE_REFERRALS: "/referee",
  REFARRAL_WITH_ID: (id) => `/referee/${id}`,

  //files
  FILE: "/files",
  FILE_WITH_ID: (id) => `/files/${id}`,
  FILE_STREAM_WITH_ID: (id) => `/files/stream/${id}`,

  // contacts
  CREATE_CONTACTS: "/contacts",
  SEARCH_CONTACTS: "/contacts/search",
  DELETE_SELECTED_CONTACTS: "/contacts",
  UPDATE_MANY_CONTACTS: "/contacts",
  CONTACT_LOGS: (id) => `/contacts/history/${id}`,
  CONTACT_WITH_ID: (id) => `/contacts/${id}`,
  CONTACT_COMMENT_WITH_ID: (id) => `/contacts/comments/${id}`,
  CONTACTS_DOWNLOAD_CSV: "/contacts/csv",
  MERGE_CONTACT: "/contacts/merge-multiple",
  CONTACT_FIND_DUPLICATE: "/contacts/duplicates",
  SEARCH_DUPLICATE_CONTACTS: "/contacts/search/duplicates",
  CONTACTS_AUTO_MERGE: "/contacts/auto-merge",
  SEND_SURVEY_FORM: (id) => `/contacts/send/survey-form/${id}`,

  //referrals
  REFER_CONTACT: "/referee/submit-referral",

  // dashboard
  GET_STATS: "/contacts/get-dashboard-data",
  GET_MARKETING_STATS: `/dashboards/marketing`,
  GET_MARKETING_LEADS: `/dashboards/marketing/leads`,
  GET_PRODUCTION_STATS: `/dashboards/production`,
  GET_PRODUCTION_PRODUCTS: `/dashboards/production/products`,

  // contracts
  CREATE_PRODUCTS: "/products",
  SEARCH_PRODUCTS: "/products/search",
  PRODUCT_WITH_ID: (id) => `/products/${id}`,
  CREATE_PRODUCTION_STAGE: "/product-production-stages",
  STEP_WITH_ID: (id) => `/product-production-stages/${id}`,
  UPDATE_MANY_PRODUCTS: "/products",

  // units
  CREATE_UNITS: "/unit",
  SEARCH_UNITS: "/unit/search",
  UNIT_WITH_ID: (id) => `/unit/${id}`,
  // events
  CREATE_EVENTS: "/events",
  SEARCH_EVENTS: "/events/search",
  EVENT_WITH_ID: (id) => `/events/${id}`,

  // automations
  CREATE_AUTOMATION: "/automations",
  SEARCH_AUTOMATIONS: "/automations/search",
  AUTOMATION_WITH_ID: (id) => `/automations/${id}`,

  // contracts
  CREATE_CONTRACTS: "/contracts",
  SEARCH_CONTRACTS: "/contracts/search",
  CONTRACT_WITH_ID: (contractId) => `/contracts/${contractId}`,
  SEND_CONTRACT_VIA_EMAIL: "/contracts/send-via-email",
  DELETE_SELECTED_CONTRACTS: "/contracts",
  CONTRACT_COMMENT_WITH_ID: (id) => `/contracts/comments/${id}`,
  CONTRACTS_DOWNLOAD_CSV: "/contracts/csv",
  CONTRACTS_PRODUCTS_DOWNLOAD_CSV: "/contracts/products-csv",
  SEARCH_PRODUCTS_ON_CONTRACTS: "/contracts/products/search",
  CONTRACT_PRODUCTION_STAGE_HISTORY_WITH_ID: (contractId) =>
    `/contracts/production-stage-history/${contractId}`,

  // logs
  SEARCH_LOGS: "/logs/search",
  LOG_ACTION: (action) => `/logs/${action}`,

  //misc
  GET_FB_CAMPAIGNS: "/misc/facebook-campaigns",
  GET_FB_ADS: "/misc/facebook-ads",
  GET_ACTIVE_TRAIL_LISTS: "/misc/active-trail-email-lists",
  CREATE_ACTIVE_TRAIL_LISTS: "/misc/create-active-trail-email-list",
  DIAL_PHONE_NUMBER: "/misc/dial-phone-number",
  GET_CALL_RECORDING_STREAM: "/misc/get-call-recording-stream",

  //Appointment
  CREATE_AVAILABILITY: "/appointments",
  CREATE_HOLIDAY: "/appointments/holiday",
  SEARCH_AVAILABILITY: "/appointments/search",
  AVAILABILITY_WITH_ID: (id) => `/appointments/${id}`,
  BOOK_AVAILABILITY: "/appointments",
  GET_UNTRACKED_ZOOM_MEETINGS: "/appointments/get-untracked-zoom-meetings",
  GET_ZOOM_MEET_RECORDINGS: "/appointments/get-zoom-recordings",
  SEARCH_ZOOM_MEET_RECORDINGS: "/appointments/get-all-zoom-recordings-drive",
  DELETE_UNTRACKED_ZOOM_MEET: (id) =>
    `/appointments/delete-untracked-zoom-meetings/${id}`,
  ASSIGN_RECORD_TO_APPOINTMENT:
    "/appointments/assign-zoom-recordings-to-appointment",

  //Tags
  CREATE_TAG: "/tags",
  SEARCH_TAGS: "/tags/list",
  RENAME_TAG: "/tags/rename",
  DELETE_TAG: "/tags/delete",

  //Status
  STATUS_BASE: "/status",
  STATUS_WITH_ID: (id) => `/status/${id}`,

  //filters
  CREATE_FILTER: "/filters",
  UPDATE_MANY: "/filters",
  SEARCH_FILTER: "/filters/search",
  UPDATE_FILTER: (id) => `/filters/${id}`,
  DELETE_FILTER: (id) => `/filters/${id}`,

  //app-configuration
  SEARCH_APPOINTMENT_DEFAULT: "/appointment-defaults/search",
  UPDATE_APPOINTMENT_DEFAULT: (id) => `/appointment-defaults/${id}`,
  SEARCH_APPOINTMENTS_DEFAULT_PAST: "/appointments-default/search/past",
  // inbound source default status
  GET_INBOUND_SOURCE_DEFAULT_STATUS: "/inbound-source-defaults/search",
  UPDATE_INBOUND_SOURCE_DEFAULT_STATUS: (id) =>
    `/inbound-source-defaults/${id}`,

  // appchoices
  GET_APP_CHOICES: "/app-choices/list",
  CREATE_APP_CHOICE: "/app-choices",
  APP_CHOICE_WITH_ID: (id) => `/app-choices/${id}`,
  APP_CHOICE_WITH_ID_AND_VALUE: (id, value) => `/app-choices/${id}/${value}`,

  // whatsapp conversation
  GET_CREATE_CONVERSATIONS: (phone) =>
    `/conversations?phone=${encodeURIComponent(phone)}`,

  GET_CLIENT_TOKEN: `/conversations/token`,
  CREATE_MESSAGE: "/conversations",
  GET_MESSAGES: ({
    phone,
    pageSize = 50,
    incomingPageToken = null,
    outgoingPageToken = null,
  }) =>
    `/conversations/messages?phone=${encodeURIComponent(
      phone
    )}&pageSize=${pageSize}&incomingPageToken=${incomingPageToken}&outgoingPageToken=${outgoingPageToken}`,
  MARK_AS_READ_UNREAD: "/conversations/mark-as-read-unread",
};

export const APP_CHOICES_KEY = {
  ENABLE_AUTO_MERGE: "ENABLE_AUTO_MERGE",
  SHOW_ARCHIVE_USERS: "SHOW_ARCHIVE_USERS",
  SHOW_ARCHIVED_PRODUCTS: "SHOW_ARCHIVED_PRODUCTS",
  SHOW_ARCHIVED_UNITS: "SHOW_ARCHIVED_UNITS",
};

export const USER_RECORD_FILE_TYPES = [
  "Pension",
  "Salary Slip",
  "Salary Invoice",
];

export const USER_STATUS = ["Enable", "Disable"];

export const DEFAULT_PAGE_SIZE = 50;

export const TIME_LOG_TYPE_WORK_LOG = "work-log";
export const TIME_LOG_TYPE_VACATION = "vacation";
export const TIME_LOG_TYPE_SICK_LEAVE = "sick-leave";

export const WHATSAPP_SOURCE_CONTACT_TABLE = "contact_table";
export const WHATSAPP_SOURCE_QUICK_SEND = "quick_send";
export const WHATSAPP_SOURCE_EVENTS = "events";
export const WHATSAPP_SOURCE_AUTOMATIONS = "automations";
export const WHATSAPP_SOURCE_INBOUNDS = "inbounds";

export const INBOUNDS_TO_SHOW = ["Facebook Leadform", "Website"];

export const WHATSAPP_CAMPAIGN_CATEGORY = ["salesperson", "general"];

export const TIME_LOGS_TYPES_OPTIONS = [
  { label: "Work log", value: TIME_LOG_TYPE_WORK_LOG },
  { label: "Vacation", value: TIME_LOG_TYPE_VACATION },
  { label: "Sick leave", value: TIME_LOG_TYPE_SICK_LEAVE },
];

export const STATUS_CATEGORIES = [
  { category: "Webinar", color: "primary" },
  { category: "Irrelevant", color: "danger" },
  { category: "Other", color: "secondary" },
  { category: "Sales", color: "info" },
  { category: "Clients", color: "success" },
];

export const INBOUND_SOURCE = [
  "Manually Added",
  "Facebook Messenger",
  "Facebook Leadform",
  "Facebook Comments",
  "Website",
  "Instagram Messenger",
  "Google organic",
  "Instagram ads",
  "Youtube",
  "Referral",
];

//User Permissions
export const BLOCK = "Block";
export const VIEW_ASSIGNED_EDIT_NONE = "View Assigned | Edit None";
export const VIEW_ASSIGNED_EDIT_ASSIGNED = "View Assigned | Edit Assigned";
export const VIEW_ALL_EDIT_NONE = "View All | Edit None";
export const VIEW_ALL_EDIT_ASSIGNED = "View All | Edit Assigned";
export const VIEW_ALL_EDIT_ALL = "View All | Edit All";

export const specialRolesEnum = [
  BLOCK,
  VIEW_ALL_EDIT_NONE,
  VIEW_ALL_EDIT_ASSIGNED,
  VIEW_ALL_EDIT_ALL,
  VIEW_ASSIGNED_EDIT_NONE,
  VIEW_ASSIGNED_EDIT_ASSIGNED,
];

export const MANAGER_ROLE = "Manager";
export const ADMIN_ROLE = "Admin";
export const SALES_ROLE = "Salesperson";
export const FINANCIAL_STRATEGIST_ROLE = "Financial Strategist";
export const REAL_ESTATE_ANALYST_ROLE = "Real Estate Analyst";
export const ALL_ROLES = [
  ADMIN_ROLE,
  MANAGER_ROLE,
  SALES_ROLE,
  FINANCIAL_STRATEGIST_ROLE,
  REAL_ESTATE_ANALYST_ROLE,
];
export const PAYABLE = "payable";
export const NON_PAYABLE = "non-payable";

export const PAYABLE_MODE = [PAYABLE, NON_PAYABLE];

export const LOGIN_MODE = "login";
export const REGISTER_MODE = "register";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY, HH:mm";
export const DEFAULT_DATE_TIME_FORMAT_NON_MOMENT = "dd/MM/yyyy, HH:mm";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const APP_OPTION = ["crm", "app"];
export const ACTION_OPTION = ["view", "edit"];
export const RULE_OPTION = ["restrict", "permit"];

export const role_to_color_map = {
  [ADMIN_ROLE]: "dark",
  [MANAGER_ROLE]: "primary",
  [REAL_ESTATE_ANALYST_ROLE]: "purple",
  [FINANCIAL_STRATEGIST_ROLE]: "info",
  [SALES_ROLE]: "success",
};

export const LEAD_SOURCE_OPTIONS = [
  "direct",
  "website",
  "facebook",
  "webinar",
  "other",
];

export const SENDER_STATUS_OPTIONS = ["Yes", "No"];

export const updateMultipleContactField = ({
  users,
  user,
  tags,
  translate,
  GROUPPED_STATUS,
  showArchivedUsers,
  groups,
}) => {
  return [
    {
      key: "status",
      label: "Status",
      show: isAdminOrManager(user.role),
      options: GROUPPED_STATUS,
      cellSizeMD: 3,
      type: "grouped-dropdown",
      placeholder: "Please select",
    },
    {
      key: "salesperson",
      label: "Sales person",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...users
          .filter((user) => !user.archived)
          .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
          .map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ...(showArchivedUsers
          ? users
              .filter((user) => user.archived)
              .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
              .map((user) => ({
                label: (
                  <div>
                    <span>{user.name}</span>
                    <Badge variant="warning" className="mx-2">
                      Archived
                    </Badge>
                  </div>
                ),
                value: user._id,
              }))
          : []),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
      showAddOption: true,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...users
          .filter((user) => !user.archived)
          .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
          .map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ...(showArchivedUsers
          ? users
              .filter((user) => user.archived)
              .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
              .map((user) => ({
                label: (
                  <div>
                    <span>{user.name}</span>
                    <Badge variant="warning" className="mx-2">
                      Archived
                    </Badge>
                  </div>
                ),
                value: user._id,
              }))
          : []),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
      showAddOption: true,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...users
          .filter((user) => !user.archived)
          .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
          .map((user) => ({
            label: user.name,
            value: user._id,
          })),
        ...(showArchivedUsers
          ? users
              .filter((user) => user.archived)
              .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
              .map((user) => ({
                label: (
                  <div>
                    <span>{user.name}</span>
                    <Badge variant="warning" className="mx-2">
                      Archived
                    </Badge>
                  </div>
                ),
                value: user._id,
              }))
          : []),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
      showAddOption: true,
    },
    {
      key: "firstName",
      label: "firstName",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "lastName",
      label: "lastName",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "address",
      label: "address",
      type: "input_text",
      show: isAdminOrManager(user.role),
      cellSizeMD: 3,
      required: true,
    },
    {
      key: "tags",
      label: "tags",
      type: "multi-select",
      show: isAdminOrManager(user.role) || user.role === SALES_ROLE,
      options: [
        { label: "Unassign", value: "unassign" },
        ...tags?.map((t) => ({ label: t?.name, value: t?.name })),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
      showAddOption: true,
    },
    {
      key: "leadSource",
      label: "Lead Source",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 3,
      placeholder: "Please select",
      isMultiSelect: false,
    },
    {
      key: "groups",
      label: "Groups",
      type: "multi-select",
      show: isAdminOrManager(user.role),
      options: [
        { label: "Unassign", value: "unassign" },
        ...groups?.map((g) => ({ label: g?.name, value: g?._id })),
      ],
      cellSizeMD: 3,
      placeholder: "Please select",
      valueModifierForServer: (value) =>
        value?.includes("unassign") ? [] : value,
      showAddOption: true,
    },
  ].filter(({ show }) => show);
};

export const newContactField = ({
  users = [],
  tags = [],
  translate,
  referrals = [],
  searchReferral,
  onCreateNewReferralClick,
  user,
  GROUPPED_STATUS,
  showArchivedUsers,
}) => {
  return [
    {
      key: "status",
      label: "Status",
      options: GROUPPED_STATUS,
      cellSizeMD: 2,
      type: "grouped-dropdown",
    },
    {
      key: "firstName",
      label: "First Name",
      cellSizeMD: 2,
    },
    {
      key: "lastName",
      label: "Last Name",
      cellSizeMD: 2,
    },
    {
      key: "spouseFirstName",
      label: "Spouse First Name",
      cellSizeMD: 2,
    },
    {
      key: "spouseLastName",
      label: "Spouse Last Name",
      cellSizeMD: 2,
    },
    { key: "address", label: "Address", cellSizeMD: 2 },
    {
      key: "phoneNumbers",
      label: "Phone Numbers",
      type: "phone",
      cellSizeMD: 4,
      isMultiple: true,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      cellSizeMD: 4,
      isMultiple: true,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse phone numbers",
      type: "phone",
      cellSizeMD: 4,
      isMultiple: true,
    },

    {
      key: "tags",
      label: "Tags",
      type: "multi-select",
      options: tags.map((tag) => ({ label: tag.name, value: tag.name })),
      cellSizeMD: 3,
      isMultiSelect: true,
    },
    {
      key: "referredBy",
      label: "Referral",
      cellSizeMD: 3,
      customField: (values, onChange) => {
        return (
          <AsyncCreatableSelect
            CustomSingleValueComponent={PersonSelectValue}
            CustomOptionComponent={PersonSelectOption}
            defaultItems={referrals?.map((r) => ({
              label: r?.name,
              value: r?._id,
              ...r,
            }))}
            selectedItems={values?.referredBy}
            onChange={(v) => {
              onChange({ key: "referredBy" }, v);
            }}
            onCreateOption={onCreateNewReferralClick}
            loadOptions={searchReferral}
            isClearable
          />
        );
      },
    },
    {
      key: "leadSource",
      label: "Lead source",
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 3,
      type: "multi-select",
      isMultiSelect: false,
      isDisabled: !isAdminOrManager(user.role),
    },
    {
      key: "followupDate",
      label: "Followup Date",
      type: "followUpDate",
      hideTitle: true,
      cellSizeMD: 3,
    },
    {
      key: "salesperson",
      label: "Sales person",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: SALES_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: FINANCIAL_STRATEGIST_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: REAL_ESTATE_ANALYST_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 4,
      required: true,
    },
    {
      key: "comment",
      label: "Comment",
      type: "text-area",
      cellSizeMD: 12,
      as: "textarea",
    },
  ];
};

export const editContactStatusFields = ({
  translate,
  user,
  GROUPPED_STATUS,
}) => {
  return [
    {
      key: "status",
      label: "Status",
      options: GROUPPED_STATUS,
      cellSizeMD: 6,
      type: "grouped-dropdown",
    },
    {
      key: "leadSource",
      label: "Lead source",
      options: LEAD_SOURCE_OPTIONS.map((ls) => ({
        label: translate(ls),
        value: ls,
      })),
      cellSizeMD: 6,
      type: "multi-select",
      isDisabled: !isAdminOrManager(user.role),
      isMultiSelect: false,
    },
    {
      key: "followupDate",
      label: "Followup Date",
      type: "followUpDate",
      hideTitle: true,
      cellSizeMD: 12,
    },
    {
      key: "whatsappSenderStatus",
      label: "Whatsapp Sender Status",
      options: SENDER_STATUS_OPTIONS.map((ls) => ({
        label: translate(ls) || ls,
        value: ls,
      })),
      cellSizeMD: 6,
      type: "multi-select",
      isDisabled: !isAdmin(user.role),
      isMultiSelect: false,
      isClearable: false,
    },
    {
      key: "emailSenderStatus",
      label: "Email Sender Status",
      options: SENDER_STATUS_OPTIONS.map((ls) => ({
        label: translate(ls) || ls,
        value: ls,
      })),
      cellSizeMD: 6,
      type: "multi-select",
      isDisabled: !isAdmin(user.role),
      isMultiSelect: false,
      isClearable: false,
    },
    // {
    //   key: "surveySent",
    //   label: "survey Sent",
    //   hideTitle: true,
    //   type: "boolean",
    //   cellSizeMD: 6,
    //   className: "mt-2",
    // },
    // {
    //   key: "surveyCompleted",
    //   label: "survey completed",
    //   type: "boolean",
    //   hideTitle: true,
    //   cellSizeMD: 6,
    //   className: "mt-2",
    // },
  ];
};

export const editContactAssignedFields = ({ users, translate }) => {
  return [
    {
      key: "salesperson",
      label: "Sales person",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: SALES_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
    {
      key: "financialStrategist",
      label: "Financial Strategist",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: FINANCIAL_STRATEGIST_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
    {
      key: "realEstateAnalyst",
      label: "Real Estate Analyst",
      type: "grouped-dropdown",
      options: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: REAL_ESTATE_ANALYST_ROLE,
        translate,
      }),
      isGroupped: true,
      isMultiSelect: true,
      cellSizeMD: 12,
    },
  ];
};

export const editContactDetailsFields = (tags) => {
  return [
    {
      key: "firstName",
      label: "First Name",
      cellSizeMD: 12,
    },
    {
      key: "lastName",
      label: "Last Name",
      cellSizeMD: 12,
    },
    {
      key: "spouseFirstName",
      label: "Spouse First Name",
      cellSizeMD: 12,
    },
    {
      key: "spouseLastName",
      label: "Spouse Last Name",
      cellSizeMD: 12,
    },
    {
      key: "phoneNumbers",
      label: "Phone Numbers",
      type: "phone",
      cellSizeMD: 12,
      isMultiple: true,
    },
    {
      key: "spousePhoneNumbers",
      label: "Spouse phone numbers",
      type: "phone",
      cellSizeMD: 12,
      isMultiple: true,
    },
    {
      key: "emails",
      label: "Emails",
      type: "email",
      cellSizeMD: 12,
      isMultiple: true,
    },

    {
      key: "tags",
      label: "Tags",
      type: "multi-select",
      options: tags.map((tag) => ({ label: tag.name, value: tag.name })),
      cellSizeMD: 12,
      isMultiSelect: true,
    },
  ];
};

export const commonChartConfig = (isRtl) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      rtl: true,
      labels: {
        padding: 20,
        boxHeight: 6,
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      position: "nearest",
      mode: "index",
      titleAlign: isRtl ? "right" : "left",
      bodyAlign: isRtl ? "right" : "left",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
});

export const editContactQuestionnaireFields = ({ translate }) => {
  return [
    {
      key: "age",
      label: "age",
      cellSizeMD: 12,
      type: "radio",
      col: 2,
      options: [
        { value: "18-30", label: "18-30" },
        { value: "30-45", label: "30-45" },
        { value: "46-70", label: "46-70" },
        { value: "אחר", label: translate("other") },
      ],
    },
    {
      key: "work",
      label: "work",
      cellSizeMD: 12,
      type: "radio",
      col: 2,
      options: [
        { value: "שכיר", label: translate("employee") },
        { value: "עצמאי", label: translate("independent") },
        { value: "בעל חברה", label: translate("company_owner") },
        { value: "לא עובד", label: translate("not_working") },
      ],
    },
    {
      key: "monthlySaving",
      label: "monthly_savings",
      cellSizeMD: 12,
      type: "radio",
      col: 2,
      options: [
        { value: "לא מצליחים לחסוך", label: translate("unable_to_save") },
        {
          value: "עד 6,000 בחודש",
          label: translate("up_to_6000_per_month"),
        },
        { value: "6,000 +", label: translate("above_6000") },
        { value: "אחר", label: translate("other") },
      ],
    },
    {
      key: "availableMoney",
      label: "available_money",
      cellSizeMD: 12,
      type: "radio",
      col: 2,
      options: [
        { value: "עד 300,000", label: translate("up_to_300000") },
        { value: "300,000-700,000", label: "300,000-700,000" },
        { value: "700,000-1,500,000", label: "700,000-1,500,000" },
        { value: "1,500,000+", label: "1,500,000+" },
      ],
    },
    {
      key: "whatApartmentWantToBuy",
      label: "what_apartment_want_to_buy",
      type: "radio",
      col: 2,
      cellSizeMD: 12,
      options: [
        {
          value: "דירה להשקעה",
          label: translate("investment_apartment"),
        },
        {
          value: "דירה למגורים",
          label: translate("residential_apartment"),
        },
        { value: "גם וגם", label: translate("both") },
        {
          value: "לא מעוניינים לרכוש דירה",
          label: translate("not_interested"),
        },
      ],
    },
    {
      key: "haveApartment",
      label: "have_apartment",
      type: "radio",
      cellSizeMD: 12,
      col: 2,
      options: [
        { value: "כן", label: translate("yes") },
        { value: "לא", label: translate("no") },
      ],
    },
    {
      key: "stageInSearch",
      label: "stage_in_search",
      type: "radio",
      cellSizeMD: 12,
      col: 2,
      options: [
        {
          value: "אני בשלב הלימוד וההבנה",
          label: translate("i_am_in_the_stage_of_learning_and_understanding"),
        },
        {
          value: "אני מעוניין לבצע השקעה בנדל״ן באופן מיידי",
          label: translate(
            "i_am_interested_in_making_an_investment_in_real_estate_immediately"
          ),
        },
        {
          value: "אני מתכנן לעשות השקעה בנדל״ן בטווח של השנה הקרובה",
          label: translate(
            "i_plan_to_make_an_investment_in_real_estate_within_the_next_year"
          ),
        },
        {
          value: "יש לי תוכניות להשקיע בנדל״ן בעתיד הרחוק יותר",
          label: translate(
            "i_have_plans_to_invest_in_real_estate_in_the_more_distant_future"
          ),
        },
      ],
    },
    {
      key: "whatService",
      label: "what_service",
      type: "checkbox-group",
      cellSizeMD: 12,
      valuesInSingleArray: true,
      col: 2,
      options: [
        {
          value: "בניית אסטרטגיית השקעות",
          label: translate("investment_strategy"),
        },
        { value: "ליווי ברכישת דירה", label: translate("apartment_purchase") },
        {
          value: "ייעוץ משכנתאות",
          label: translate("mortgage_consulting"),
        },
        { value: "אחר", label: translate("other") },
      ],
    },
    {
      key: "howDoYouKnowUs",
      label: "how_did_you_hear_about_us",
      type: "text",
      cellSizeMD: 12,
    },
  ];
};

export const SEARCH_BOX_VISIBLE_PATH = ["/contacts", "/deals", "/commissions"];

export const EVENT_STATUS = {
  Available: "primary",
  Booked: "success",
};

export const APPOINTMENT_TYPES = [
  "Office Meeting",
  "Zoom Meeting",
  "Phone Call",
];

export const DEAL_VIEWS = [
  {
    name: "board",
    label: "Board View",
  },
  {
    name: "table",
    label: "Table View",
  },
];

export const dealStatusToColor = {
  Published: "primary",
  Signed: "success",
  Opened: "info",
  Declined: "danger",
  "Contract Not Needed": "secondary",
  "Deal Finished": "success",
  Quitted: "secondary",
};

export const DEAL_STATUSES = [
  "Signed",
  "Deal Finished",
  "Published",
  "Sent",
  "Opened",
  "Declined",
  "Contract Not Needed",
  "Quitted",
];

export const PRODUCTION_STAGE_STATUSES = [
  "Deal Won",
  "Deal Finished",
  "Published",
  "Sent",
  "Opened",
  "Declined",
  "Contract Not Needed",
  "Quitted",
];

export const LOG_ACTION_MULTI_UPDATE = "Multi Update Contacts";
export const LOG_ACTION_MULTI_DELETE = "Multi Delete Contacts";
export const LOG_ACTION_AUTO_MERGE = "Auto Merge Contacts";
export const LOG_ACTION_MULTI_TRASH = "Multi Trash Contacts";
export const LOG_ACTION_MULTI_UNTRASH = "Multi Untrash Contacts";

export const LOG_TYPE = [
  LOG_ACTION_MULTI_UPDATE,
  LOG_ACTION_MULTI_DELETE,
  LOG_ACTION_AUTO_MERGE,
  LOG_ACTION_MULTI_TRASH,
  LOG_ACTION_MULTI_UNTRASH,
];

export const LOG_COLLECTIONS = ["Contact", "Contract", "Appointment"];

export const videoMimeTypes = [
  "video/mp4",
  "video/webm",
  "video/ogg",
  "video/x-msvideo",
  "video/quicktime",
  "video/mpeg",
  "video/x-flv",
  "video/x-matroska",
  "video/3gpp",
];

export const commissionTypes = ["Sales", "Referral"];

export const PLACEHOLDER_VALUES_OPTIONS = [
  { label: "First Name", value: "firstName" },
  { label: "Last Name", value: "lastName" },
  { label: "Phone Number", value: "phoneNumbers[0]" }, // Using the first item in the array
  { label: "Email", value: "emails[0]" }, // Using the first item in the array
  { label: "Spouse First Name", value: "spouseFirstName" },
  { label: "Spouse Last Name", value: "spouseLastName" },
  { label: "Spouse Phone Number", value: "spousePhoneNumbers[0]" }, // Using the first item in the array
  { label: "Spouse Email", value: "spouseEmails[0]" }, // Using the first item in the array
  { label: "Address", value: "address" },
  { label: "Follow-up Date", value: "followupDate" },
  { label: "Description", value: "description" },
  { label: "Event name", value: "event.name" },
  { label: "Event date", value: "event.date" },
  { label: "Event time", value: "event.time" },
  { label: "Webinar link", value: "event.webinarLink" },
];

export const TWILLIO_WHATSAPP_NUMBER = "+972559661865";
