import { snakeCase } from "lodash";
import React, { useMemo, useState } from "react";
import { FormCheck, Table } from "react-bootstrap";
import { ArrowClockwise, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import {
  PERMISSIONS,
  checkPermission,
  getPermissionKey,
} from "../../helpers/permissions";
import useLocalization from "../../hooks/useLocalization";
import UnderlineButton from "../common/UnderlineButton";
import AppTable from "../common/app-table/AppTable";

const PAGE_WISE_MODULE = [
  {
    page: "Meetings (Appointments)",
    module: ["appointments"],
    hideHeader: true,
  },
  {
    page: "Dashboard",
    module: ["dashboard"],
    hideHeader: true,
  },
  {
    page: "Referrers",
    module: ["referrers"],
    hideHeader: true,
  },
  {
    page: "Deals",
    module: ["contracts", "contract_advanced_info"],
  },
  {
    page: "Automations",
    module: ["events", "automations", "inbound_source_defaults"],
  },
  {
    page: "Contacts",
    module: ["contacts"],
    hideHeader: true,
  },
  {
    page: "Users",
    module: ["users"],
    hideHeader: true,
  },
  {
    page: "App config",
    module: [
      "app_choice",
      "appointment_defaults",
      "email_templates",
      "filters",
      "status",
      "tags",
      "whatsapp_templates",
    ],
  },
  {
    page: "Products",
    module: ["products"],
    hideHeader: true,
  },
  {
    page: "Logs",
    module: ["logs"],
    hideHeader: true,
  },
  {
    page: "Whatsapp logs",
    module: ["whatsapp_campaigns"],
    hideHeader: true,
  },
];

const PERMISSION_OWNERSHIPS = [
  { label: "All", key: "all" },
  { label: "Assigned", key: "assigned" },
  /* { label: "Specific Users", key: "specificusers" }, */
  { label: "None", key: "none" },
];

const PERMISSION_OPERATIONS = [
  { label: "View", key: "view" },
  { label: "Create", key: "create" },
  { label: "Update", key: "update" },
  { label: "Delete", key: "delete" },
];

const moduleKeyToLabel = {
  app_choice: "control_system_settings",
};

const ModulePermission = ({
  module,
  moduleKey,
  permissions,
  onPermissionsChecked,
  hideHeader,
}) => {
  const { translate } = useLocalization();

  const thisModuleOperations = useMemo(() => {
    const permissionObject = PERMISSIONS[moduleKey];
    const values = Object.values(permissionObject);
    return PERMISSION_OPERATIONS.filter(({ key }) =>
      values.some((v) => v.includes(key))
    );
  }, [moduleKey]);

  const thisModuleOwnerships = useMemo(() => {
    const permissionObject = PERMISSIONS[moduleKey];
    const values = Object.values(permissionObject);
    return [
      ...PERMISSION_OWNERSHIPS.map((o) => o.key).filter((key) =>
        values.some((v) => v.includes(key))
      ),
      "none",
    ];
  }, [moduleKey]);

  return (
    <React.Fragment key={module}>
      {!hideHeader && (
        <tr>
          <td
            colSpan={PERMISSION_OWNERSHIPS.length + 1}
            className="p-0 bg-gray"
          >
            <div className="d-flex p-2 hover-light align-items-center">
              <h6 className="flex-grow-1 mid mb-0 fw-bold">
                {translate(
                  snakeCase(moduleKeyToLabel[moduleKey] || moduleKey)
                ) ||
                  moduleKeyToLabel[moduleKey] ||
                  moduleKey}
              </h6>
            </div>
          </td>
        </tr>
      )}
      {thisModuleOperations.map(({ key: operation, label }) => (
        <tr>
          <td>{label}</td>
          {PERMISSION_OWNERSHIPS.map(({ key: ownership }) => {
            const isValid =
              thisModuleOwnerships.includes(ownership) &&
              !(ownership === "assigned" && operation === "create");

            return (
              <td
                className="hover"
                align="center"
                onClick={() => {
                  isValid && onPermissionsChecked(operation, ownership, module);
                }}
              >
                {isValid && (
                  <FormCheck
                    className="hover"
                    checked={checkPermission({
                      permissions: permissions,
                      operation,
                      ownership,
                      mod: module,
                    })}
                  />
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </React.Fragment>
  );
};

export default function AddEditUserPermissionForm({
  onRevertPermissionClick,
  role,
  permissions,
  onPermissionsChange,
}) {
  const [expandedModules, setExpandedModules] = useState([]);

  const onPermissionsChecked = (operation, ownership, module) => {
    //if view is set to none we clear all permissions related to this module
    if (ownership === "none" && operation === "view") {
      const newPermissions = permissions.filter(
        (p) => !p.permission.includes(module)
      );
      onPermissionsChange(newPermissions);
      return;
    }

    const newPermission = getPermissionKey({
      operation,
      ownership,
      mod: module,
    });

    //remove old permission related to this module and operation
    const newPermissions = permissions.filter(
      (p) =>
        !(p.permission.includes(module) && p.permission.includes(operation))
    );
    if (ownership !== "none") {
      newPermissions.push({ permission: newPermission, assignee: [] });
    }
    onPermissionsChange(newPermissions);
  };

  const onModuleClick = (module) => {
    if (expandedModules.includes(module)) {
      setExpandedModules(expandedModules.filter((m) => m !== module));
    } else {
      setExpandedModules([...expandedModules, module]);
    }
  };

  return (
    <>
      <div className="text-end">
        <UnderlineButton
          className="mb-2"
          fontSize="mid"
          Icon={ArrowClockwise}
          text={`Revert to default (${role})`}
          onClick={onRevertPermissionClick}
        />
      </div>

      <AppTable bordered>
        <thead>
          <tr>
            <th className="bg-dark text-white" style={{ width: 150 }}></th>
            {PERMISSION_OWNERSHIPS.map(({ label }) => (
              <th style={{ width: 80 }} className="bg-dark text-white">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {PAGE_WISE_MODULE.map(({ page, module, hideHeader }, index) => (
            <React.Fragment>
              <tr>
                <td
                  colSpan={PERMISSION_OWNERSHIPS.length + 1}
                  className={`p-0 ${
                    expandedModules.includes(page)
                      ? "bg-secondary text-white"
                      : "bg-gray"
                  }`}
                >
                  <div
                    className="d-flex p-2 hover-light align-items-center"
                    onClick={() => onModuleClick(page)}
                  >
                    <h6 className="flex-grow-1 mid mb-0 fw-bold">{page}</h6>
                    {!expandedModules.includes(module) ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )}
                  </div>
                </td>
              </tr>
              {expandedModules.includes(page) &&
                module.map((moduleKey, index) => {
                  const module = moduleKey.replaceAll("_", "-");
                  return (
                    <ModulePermission
                      hideHeader={hideHeader}
                      module={module} //with dash
                      moduleKey={moduleKey} //with _
                      expandedModules={expandedModules}
                      onModuleClick={onModuleClick}
                      permissions={permissions}
                      onPermissionsChecked={onPermissionsChecked}
                    />
                  );
                })}
            </React.Fragment>
          ))}
        </tbody>
      </AppTable>
    </>
  );
}
