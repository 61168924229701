import React, { useMemo } from "react";
import { FormGroup } from "react-bootstrap";
import { Envelope, PersonCircle, Telephone } from "react-bootstrap-icons";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";
import useContactStatus from "../../hooks/useContactStatus";
import useLocalization from "../../hooks/useLocalization";

const ViewContactButton = ({ contact, onContactChange }) => {
  let { translate } = useLocalization();
  const { openContactPopup } = useContactAndDealPopup();

  const { getContactStatus } = useContactStatus();

  const contactStatus = useMemo(
    () => getContactStatus(contact.status),
    [contact, getContactStatus]
  );
  
  const onViewContactClick = (e) => {
    openContactPopup && openContactPopup(contact?._id);
  };

  return (
    <FormGroup className="mb-2">
      <h6 className="mid mt-1 mb-2">{translate("contact")}</h6>
      <div
        className="border bg-white rounded w-100 p-1 d-flex align-items-center justify-content-between hover"
        onClick={onViewContactClick}
      >
        <div className="w-100">
          <div className="d-flex  justify-content-between">
            <div className="d-flex align-items-center">
              {" "}
              <PersonCircle size={15} />{" "}
              <h6 className="mb-0 mx-1 mid fw-bold">
                {contact?.firstName} {contact?.lastName}
              </h6>
            </div>
            <div
              style={{ fontSize: 10 }}
              className={`bg-${contactStatus.color} px-2 py-0 text-white rounded`}
            >
              {contactStatus.label}
            </div>
          </div>
          <div className="d-flex align-items-center mt-1">
            <Envelope size={15} />{" "}
            <h6 className="mb-0 mx-1 mid">{contact?.emails?.join(", ")}</h6>{" "}
            <h6 className="mb-0 mx-1">|</h6> <Telephone size={15} />
            <h6 className="mb-0 mx-1 mid">
              {contact?.phoneNumbers?.join(", ")}
            </h6>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};

export default ViewContactButton;
