import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import { Plus } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { whatsappTemplateService } from "../../../services/whatsappTemplateService";
import AddTemplateModal from "./AddTemplateModal";
import TemplatesList from "./TemplatesList";
import WhatsappTemplate from "./WhatsappTemplate";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

export default function WhatsappTemplates() {
  const { checkNestedPermission } = useAuth();
  const abortControllerRef = useRef(null);
  const location = useLocation();
  let navigate = useNavigate();
  const { templateId } = useQueryParams();
  const [templates, setTemplates] = useState([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const [activeTemplate, setActiveTemplate] = useState(null);

  const { translate } = useLocalization();
  const [showAddModalMeta, setShowAddModalMeta] = useState(null);

  const fetchTemplates = async () => {
    setFetchingTemplates(true);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    let requestBody = {
      query: searchQuery,
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody, signal);

    if (error === ABORT_ERROR) return;

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setTemplates(results);
    setFetchingTemplates(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, [searchQuery]);

  const handleTemplateClick = (template) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("templateId");
    searchParams.set("templateId", template._id);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const onTemplateDelete = (deletedTemplate) => {
    const { templateId } = deletedTemplate;
    const updatedTemplates = templates.filter((t) => t._id !== templateId);
    setTemplates(updatedTemplates);
    handleTemplateClick(
      updatedTemplates.length > 0 ? updatedTemplates[0] : null
    );
  };

  const onTemplateUpdate = (updatedTemplate) => {
    setTemplates(updateItemsInArray(templates, updatedTemplate));
    handleTemplateClick(updatedTemplate);
  };

  const handleAddTemplate = async (values) => {
    setShowAddModalMeta({ ...showAddModalMeta, showProgress: true });
    const requestBody = values;

    let { response, error } =
      await whatsappTemplateService.createWhatsappTemplate(requestBody);

    if (error) {
      setShowAddModalMeta({ ...showAddModalMeta, showProgress: false });
      toast.error(error);
      return;
    }
    setTemplates([response, ...templates]);
    handleTemplateClick(response);
    setShowAddModalMeta(null);
  };

  useEffect(() => {
    if (templateId) {
      const foundTemplate = templates.find((t) => t._id === templateId);
      if (foundTemplate) setActiveTemplate(foundTemplate);
    } else {
      setActiveTemplate(templates[0]);
    }
  }, [templateId, templates]);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <span>
            {translate("whatsapp_templates")} ({templates.length || "0"})
          </span>
          {checkNestedPermission([
            PERMISSIONS.whatsapp_templates.CREATE_ALL_TEMPLATES,
          ]) && (
            <Button
              size="sm"
              className="d-flex align-items-center px-1 py-0"
              variant="success text-white"
              onClick={() => setShowAddModalMeta({ show: true })}
            >
              <Plus />
              <span className="smallFont">{translate("add")}</span>
            </Button>
          )}
        </Card.Header>
        <Card.Body className="p-1">
          <Row className="m-0">
            <Col sm={12} md={2} className="p-1 border-end border-2">
              <TemplatesList
                templates={templates}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                activeTemplate={activeTemplate}
                onTemplateSelect={handleTemplateClick}
                translate={translate}
              />
            </Col>
            <Col sm={12} md={10} className="p-1">
              {activeTemplate ? (
                <WhatsappTemplate
                  template={activeTemplate}
                  onTemplateDelete={onTemplateDelete}
                  onTemplateUpdate={onTemplateUpdate}
                  allowEdit={checkNestedPermission([
                    PERMISSIONS.whatsapp_templates.UPDATE_ALL_TEMPLATES,
                  ])}
                  allowDelete={checkNestedPermission([
                    PERMISSIONS.whatsapp_templates.DELETE_ALL_TEMPLATES,
                  ])}
                />
              ) : (
                <div className="text-center p-5">
                  <h5 className="fw-bold">No Template Selected!</h5>
                </div>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <AddTemplateModal
        show={Boolean(showAddModalMeta)}
        onHide={() => setShowAddModalMeta(null)}
        showProgress={showAddModalMeta?.showProgress}
        onSubmit={handleAddTemplate}
      />
    </>
  );
}
