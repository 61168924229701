import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { APP_CHOICES_KEY } from "../../../helpers/constants";
import useLocalization from "../../../hooks/useLocalization";
import { appChoiceService } from "../../../services/appChoiceService";
import AlertModal from "../../common/AlertModal";
import CircularProgressBar from "../../common/circular-progress";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const contactMergingSteps = [
  {
    label: "choosing_the_base_contact",
    description: "choosing_the_base_contact_desc",
  },
  {
    label: "combining_lists",
    description: "combining_lists_desc",
  },
  {
    label: "handling_single_value_fields",
    description: "handling_single_value_fields_desc",
  },
  {
    label: "names_and_spouse_names",
    description: "names_and_spouse_names_desc",
  },
  {
    label: "status_and_lead_source",
    description: "status_and_lead_source_desc",
  },
  {
    label: "address",
    description: "address_desc",
  },
  {
    label: "referred_by",
    description: "referred_by_desc",
  },
  {
    label: "follow_up_date",
    description: "follow_up_date_desc",
  },
  {
    label: "survey_sent",
    description: "survey_sent_desc",
  },
  {
    label: "survey_completed",
    description: "survey_completed_desc",
  },
];

const MergeLogics = ({ translate }) => {
  return (
    <div className="mt-1 border rounded py-1">
      {contactMergingSteps.map(({ label, description }, index) => (
        <div className="hover-light px-2 pt-1">
          <h6 className="mb-0 large ">
            <span className="fw-bold">
              {index + 1}. {startCase(translate(label))}
            </span>
            {":"}
          </h6>
          <h6 className="mb-0 mid mt-2">{translate(description)}</h6>
          <hr className="my-2" />
        </div>
      ))}
    </div>
  );
};

const AutoMerge = ({ className }) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();
  const [autoMerge, setAutoMerge] = useState(false);
  const [confirmationModalMeta, setConfirmationModalMeta] = useState(null);
  const [fetching, setFetching] = useState(true);

  const toggleSwitch = (checked) => {
    setConfirmationModalMeta({
      ...autoMerge,
      values: [
        {
          value: checked,
        },
      ],
    });
  };

  const getAutomergeSetting = async () => {
    try {
      setFetching(true);
      const reqBody = {
        keys: "appChoices",
        appChoiceFilter: {
          key: APP_CHOICES_KEY.ENABLE_AUTO_MERGE,
        },
      };
      const { response, error } = await appChoiceService.getAppchoices(reqBody);

      if (error) {
        toast.error(error);
        return;
      }
      const appChoices = response;
      setAutoMerge(
        appChoices.find((a) => a.key === APP_CHOICES_KEY.ENABLE_AUTO_MERGE)
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const handleAutoMerge = async () => {
    try {
      setConfirmationModalMeta({
        ...confirmationModalMeta,
        showProgress: true,
      });

      const { response, error } = await appChoiceService.updateAppchoice(
        confirmationModalMeta
      );

      if (error) {
        setConfirmationModalMeta({
          ...confirmationModalMeta,
          showProgress: false,
        });
        toast.error(error);
        return;
      }
      toast.success("Auto merge setting changed succesfully");
      setConfirmationModalMeta(null);
      setAutoMerge(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (checkNestedPermission([PERMISSIONS.app_choice.VIEW_ALL_APP_CHOICE])) {
      getAutomergeSetting();
    }
  }, []);

  return (
    <Card className={className}>
      <Card.Header>{translate("auto_merge")}</Card.Header>
      <Card.Body className="">
        <div className="d-flex">
          <h6 className="smallFont mb-0 flex-grow-1">
            {translate("auto_merge_info")}
          </h6>
          {fetching ? (
            <CircularProgressBar />
          ) : (
            <Switch
              checked={autoMerge?.values?.[0]?.value}
              onChange={toggleSwitch}
              height={15}
              width={40}
              offHandleColor="#d1e6cc"
              onHandleColor="#d1e6cc"
              disabled={
                !checkNestedPermission([
                  PERMISSIONS.app_choice.UPDATE_ALL_APP_CHOICE,
                ])
              }
            />
          )}
        </div>
        <hr />
        <h6 className="mid mb-0 flex-grow-1 fw-bold">
          {translate("heres_a_detailed_description_megrging_algorigthm")}:
        </h6>
        <MergeLogics translate={translate} />
        <AlertModal
          show={confirmationModalMeta}
          onContinueClick={handleAutoMerge}
          onDismissClick={() => setConfirmationModalMeta(null)}
          onHide={() => setConfirmationModalMeta(null)}
          alertText={translate("confirm_app_config_auto_merge")}
        />
      </Card.Body>
    </Card>
  );
};

export default AutoMerge;
