import { Col, Row } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import AppModal from "../common/AppModal";
import AddEditReferrerForm from "../forms/AddEditReferrerForm";
import BrowseCommissions from "../commissions/BrowseCommissions";
import useAuth from "../../hooks/useAuth";
import { PERMISSIONS } from "../../helpers/permissions";

const AddEditReferrerModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const { translate } = useLocalization();
  const { checkNestedPermission } = useAuth();

  const onFormSubmit = (referrer) => {
    const referrerToSubmit = {
      ...referrer,
    };

    onSubmit && onSubmit(referrerToSubmit);
  };

  return (
    <AppModal
      fullscreen
      show={show}
      onHide={onHide}
      title={
        editMode ? translate(`update_referrer`) : translate(`add_new_referrer`)
      }
      showCloseButton={!showProgress}
      modalBodyClassName="pt-1"
    >
      <div>
        {" "}
        <h6 className="mb-0 m-1">{translate("referrer")}</h6>
        <hr className="my-1" />
        <AddEditReferrerForm
          initialValues={initialValues}
          showProgress={showProgress}
          editMode={editMode}
          onSubmit={onFormSubmit}
        />
        {editMode &&
          checkNestedPermission({
            orPermissionsToCheck: [
              PERMISSIONS.contracts.VIEW_ALL_CONTRACTS,
              PERMISSIONS.contracts.VIEW_ASSIGNED_CONTRACTS,
            ],
            andPermissionsToCheck: [
              PERMISSIONS.contract_advanced_info
                .VIEW_ALL_CONTRACT_ADVANCED_INFO,
            ],
          }) && (
            <>
              <hr className="my-1" />
              <BrowseCommissions
                referredBy={initialValues}
                maxTableHeight="350px"
              />
            </>
          )}
      </div>
    </AppModal>
  );
};

export default AddEditReferrerModal;
