import { QueryBuilderAntD } from "@react-querybuilder/antd";
import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Plus,
  QuestionCircle,
  QuestionCircleFill,
  X,
} from "react-bootstrap-icons";
import {
  defaultRuleProcessorMongoDB,
  formatQuery,
  QueryBuilder as QB,
  ValueEditor,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import { components } from "react-select";
import { getDateObjectWithTimezone } from "../../../helpers/global";
import CustomMultiSelect from "../CustomMultiSelect";
import EditableTagsInput from "../EditableTagsInput";
import useLocalization from "../../../hooks/useLocalization";
import CreatableSelect from "../CreatableSelect";

const operatorDescription = {
  "=": "equals_desc",
  "!=": "not_equals_desc",
  "<": "less_than_desc",
  "<=": "less_than_or_equal_desc",
  ">": "greater_than_desc",
  ">=": "greater_than_or_equal_desc",
  contains: "contains_desc",
  doesNotContain: "does_not_contain_desc",
  beginsWith: "begins_with_desc",
  doesNotBeginWith: "does_not_begin_with_desc",
  endsWith: "ends_with_desc",
  doesNotEndWith: "does_not_end_with_desc",
  null: "is_null_desc",
  notNull: "is_not_null_desc",
  isEmpty: "is_empty_desc",
  isNotEmpty: "is_not_empty_desc",
  between: "between_desc",
  notBetween: "not_between_desc",
  in: "in_desc",
  notIn: "not_in_desc",
  matchesRegex: "matches_regex_desc",
  doesNotMatchRegex: "does_not_match_regex_desc",
};

const ruleProcessor = (rule, options, fields) => {
  const convertDateStringsToISO = (str) => {
    if (typeof str !== "string") {
      return str;
    }

    // Regex pattern to match date in YYYY-MM-DD format
    const dateRegex = /\b\d{4}-\d{2}-\d{2}\b/g;

    // Replace all matching date strings with ISO strings
    return str.replace(dateRegex, (match) => {
      const date = getDateObjectWithTimezone(match);
      if (!isNaN(date)) {
        return date.toISOString();
      }
      return match; // If not a valid date, return the original match
    });
  };

  // Process the rule using the default MongoDB rule processor
  let processedRule = defaultRuleProcessorMongoDB(rule, options);

  // Convert date strings to ISO strings
  processedRule = convertDateStringsToISO(processedRule);
  return processedRule;
};

const RemoveRuleButton = ({ handleOnClick }) => {
  return (
    <X
      style={{ minWidth: 25 }}
      size={25}
      className="hover text-danger"
      onClick={handleOnClick}
    />
  );
};

const AddRuleButton = ({ handleOnClick }) => {
  const { translate } = useLocalization();
  return (
    <Button
      onClick={handleOnClick}
      variant="success"
      size="sm"
      className="px-1 py-0 text-white d-flex align-items-center"
    >
      <Plus size={15} />
      <span className="tiny">{translate("rule")}</span>
    </Button>
  );
};

const AddGroupButton = ({ handleOnClick }) => {
  const { translate } = useLocalization();
  return (
    <Button
      onClick={handleOnClick}
      variant="primary"
      size="sm"
      className="px-1 py-0 text-white d-flex align-items-center"
    >
      <Plus size={15} />
      <span className="tiny">{translate("group")}</span>
    </Button>
  );
};

const CustomValueEditor = (props) => {
  let { operator, values, value, handleOnChange, disabled } = props;

  if ((operator === "in" || operator === "notIn") && !values.length) {
    value = typeof value === "string" ? [] : value;

    return (
      <EditableTagsInput
        tags={value}
        disabled={disabled}
        onTagsChange={handleOnChange}
        maxCharactersForTag={20}
      />
    );
  }

  return (
    <ValueEditor
      {...props}
      className={!values.length && "form-control form-control-sm"}
    />
  );
};

const CustomOperatorSelector = (props) => {
  const { handleOnChange, value, options } = props;
  const { translate } = useLocalization();
  return (
    <div style={{ minWidth: "150px" }}>
      <CustomMultiSelect
        selectedItems={value}
        items={options}
        onChange={handleOnChange}
        isMulti={false}
        CustomOption={(props) => {
          const { data, getValue } = props;
          const selectedValue = getValue()[0].value;

          return (
            <components.Option
              {...props}
              isSelected={selectedValue === props.value}
            >
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="flex-grow-1 text-wrap mb-0 smallFont">
                  {data.label || data.value}
                </h6>
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip" style={{ zIndex: 9999999999 }}>
                      {translate(operatorDescription[data.name])}
                    </Tooltip>
                  }
                >
                  <Button className="p-0" variant="transparent" size="sm">
                    <QuestionCircle className="text-dark" />
                  </Button>
                </OverlayTrigger>
              </div>
            </components.Option>
          );
        }}
      />
    </div>
  );
};

const CustomValueSelector = (props) => {
  const {
    handleOnChange,
    value,
    options,
    field,
    multiple = false,
    testID,
  } = props;

  const grouppedFields = [
    "status",
    "salesperson",
    "realEstateAnalyst",
    "financialStrategist",
  ];

  const isCreatableDropdown = [
    "lastInboundSourceCampaignUrl",
    "inboundSourceCampaignUrl",
  ].includes(field);

  const handleChange = (selectedOption) => {
    handleOnChange(selectedOption);
  };

  return (
    <div
      style={{
        maxWidth: 300,
        minWidth: testID === "fields" ? 160 : 100,
      }}
    >
      {isCreatableDropdown ? (
        <CreatableSelect
          selectedItems={value ? value : []}
          items={options}
          onChange={handleChange}
          isMulti={multiple}
          separator={"OR"}
          maxToShow={100}
        />
      ) : (
        <CustomMultiSelect
          selectedItems={value}
          items={options}
          onChange={handleChange}
          isMulti={multiple}
          isGroupped={grouppedFields.includes(field)}
          separator={"OR"}
          maxToShow={100}
        />
      )}
    </div>
  );
};

const QueryBuilder = ({ fields, disabled, query, onChange }) => {
  const onQueryChange = (query) => {
    onChange({
      filter: query,
      filterParsed: formatQuery(query, {
        format: "mongodb",
        ruleProcessor: (rule, options) => ruleProcessor(rule, options, fields),
      }),
    });
  };

  return (
    <>
      <QueryBuilderAntD>
        <QB
          disabled={disabled}
          fields={fields}
          query={query}
          onQueryChange={onQueryChange}
          controlClassnames={{
            queryBuilder: "bg-light",
            combinators: "smallFont",
            addRule: "bg-success smallFont",
            addGroup: "bg-primary smallFont",
            body: " smallFont",
            fields: "smallFont",
            operators: "",
            value: "",
            ruleGroup: "bg-light",
          }}
          controlElements={{
            removeRuleAction: RemoveRuleButton,
            removeGroupAction: RemoveRuleButton,
            addRuleAction: AddRuleButton,
            addGroupAction: AddGroupButton,
            valueEditor: CustomValueEditor,
            operatorSelector: CustomOperatorSelector,
            valueSelector: CustomValueSelector,
          }}
        />
      </QueryBuilderAntD>
    </>
  );
};

export default QueryBuilder;
