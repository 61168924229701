import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const contractService = {
  getContracts: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_CONTRACTS,
      requestBody,
      signal,
    });

    return { response, error };
  },
  createContract: async (contract, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_CONTRACTS,
      requestBody: contract,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  updatePayment: async (contract, payment, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTRACT_WITH_ID(contract?._id),
      requestBody: payment,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  sendContractViaEmail: async ({ contractId, contactId }, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEND_CONTRACT_VIA_EMAIL,
      requestBody: { contractId, contactId },
      method: "POST",
      signal,
    });

    return { response, error };
  },
  deleteSelectedContract: async (selectedContracts, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.DELETE_SELECTED_CONTRACTS,
      requestBody: selectedContracts,
      method: "DELETE",
      signal,
    });

    return { response, error };
  },
  updateContract: async (contract, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTRACT_WITH_ID(contract?._id),
      requestBody: contract,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  updateContactComment: async (contact, commentRequestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTRACT_COMMENT_WITH_ID(contact._id),
      requestBody: commentRequestBody,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
  deleteContract: async (contract, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTRACT_WITH_ID(contract._id),
      method: "DELETE",
      signal,
    });
    
    return { response, error };
  },
  getProductsOnContracts: async (requestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.SEARCH_PRODUCTS_ON_CONTRACTS,
      method: "POST",
      requestBody,
      signal,
    });
    
    return { response, error };
  },
  updateContractProductionStageHistory: async (contractId, productionStageHisRequestBody, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CONTRACT_PRODUCTION_STAGE_HISTORY_WITH_ID(contractId),
      requestBody: productionStageHisRequestBody,
      method: "PUT",
      signal,
    });

    return { response, error };
  },
};

