import { useContext } from "react";
import { UserContext } from "../context/UserContext";

const useAuth = () => {
  const {
    isUserLoggedIn,
    login,
    logout,
    user,
    onUserChange,
    isLoggingIn,
    loginError,
    viewAsUserMode,
    parentUser,
  } = useContext(UserContext);

  const checkPermission = (permissionArray = [], relation = "OR") => {
    if (!permissionArray) return true;

    if (!Array.isArray(permissionArray)) {
      permissionArray = [permissionArray];
    }

    if (permissionArray?.length === 0) return true;

    const permissions =
      user?.permissions?.permissions?.map((p) => p.permission) || [];
    const isPermissionGranted =
      relation === "OR"
        ? permissionArray.some((p) => permissions.includes(p))
        : permissionArray.every((p) => permissions.includes(p));

    return isPermissionGranted;
  };

  const checkNestedPermission = (permissionsToCheck) => {
    if (!permissionsToCheck) return true;

    if (Array.isArray(permissionsToCheck) && !permissionsToCheck.length)
      return true;

    const orPermissionsToCheck = !Array.isArray(permissionsToCheck)
      ? permissionsToCheck?.orPermissionsToCheck
      : permissionsToCheck;

    const andPermissionsToCheck = !Array.isArray(permissionsToCheck)
      ? permissionsToCheck?.andPermissionsToCheck
      : [];

    const relationBetweenThem = !Array.isArray(permissionsToCheck)
      ? permissionsToCheck?.parentRelation || "AND"
      : "AND";

    const checkAndPermission = checkPermission(andPermissionsToCheck, "AND");
    const checkORPermission = checkPermission(orPermissionsToCheck, "OR");

    return relationBetweenThem === "AND"
      ? checkAndPermission && checkORPermission
      : checkAndPermission || checkORPermission;
  };

  return {
    isUserLoggedIn,
    login,
    logout,
    user,
    onUserChange,
    isLoggingIn,
    loginError,
    viewAsUserMode,
    parentUser,
    checkNestedPermission,
  };
};

export default useAuth;
