import { cloneDeep } from "lodash";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import AlertModal from "../components/common/AlertModal";
import EditContactDetailsModal from "../components/contacts/contact-details/EditContactDetailsModal";
import UpdateDealModal from "../components/deals/UpdateDealModal";
import LogModal from "../components/logs/LogModal";
import { makeRESTApiRequests } from "../helpers/api";
import {
  CONTACT_QS,
  DEAL_QS,
  ENDPOINTS,
  RUJUM_APP_URL,
  editContactAssignedFields,
  editContactDetailsFields,
  editContactQuestionnaireFields,
  editContactStatusFields,
} from "../helpers/constants";
import useAppChoices from "../hooks/useAppChoices";
import useLocalization from "../hooks/useLocalization";
import { contactService } from "../services/contactService";
import { contractService } from "../services/contractService";

import { useLocation, useNavigate } from "react-router-dom";
import CreateNewReferralModal from "../components/refer-contact/CreateNewReferralModal";
import { updateItemsInArray } from "../helpers/global";
import { PERMISSIONS } from "../helpers/permissions";
import useAuth from "../hooks/useAuth";
import useContactStatus from "../hooks/useContactStatus";
import { referralService } from "../services/referralServices";

export const ContactAndDealPopupContext = createContext();

export const ContactAndDealPopupProvider = ({ children }) => {
  let { translate } = useLocalization();
  const { GROUPPED_STATUS } = useContactStatus();
  let location = useLocation();
  let navigate = useNavigate();
  let { user, checkNestedPermission } = useAuth();
  let users = useAppChoices("users");
  let tags = useAppChoices("tags");
  // contact states
  const [updatedContact, setUpdatedContact] = useState(null);
  const [contactDetailsModalMeta, setContactDetailsModalMeta] = useState(null);
  const [activeContact, setActiveContact] = useState(null);
  const [activeContactCopy, setActiveContactCopy] = useState(null);
  const [showChangeLogsModal, setShowChangeLogsModal] = useState(null);

  // contact comment states
  const [updatingContact, setUpdatingContact] = useState(false);
  const [newCommentText, setNewCommentText] = useState("");
  const [commentsUpdating, setCommentsUpdating] = useState(false);
  const [editingComment, setEditingComment] = useState(null);
  const [deletingComment, setDeletingComment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [addExistingContactComment, setAddExistingContactComment] =
    useState(null);

  //merge
  const [mergeConflitInUpdate, setMergeConflitInUpdate] = useState(null);

  // deals state
  const originalProducts = useAppChoices("products");
  const SHOW_ARCHIVED_PRODUCTS = useAppChoices("SHOW_ARCHIVED_PRODUCTS");

  const [updatedDeal, setUpdatedDeal] = useState(null);
  const [dealDetailsModalMeta, setDealDetailsModalMeta] = useState(null);
  const [activeDeal, setActiveDeal] = useState(null);
  const [activeDealCopy, setActiveDealCopy] = useState(null);
  const [deleteDealMeta, setDeleteDealMeta] = useState(null);
  const [updateProductionStageMeta, setUpdateProductionStageMeta] =
    useState(null);
  const [updatingContract, setUpdatingContract] = useState(false);
  const [dealNewCommentText, setDealNewCommentText] = useState();
  const [dealCommentUpdating, setDealCommentUpdating] = useState(false);
  const [editingDealComment, setEditingDealComment] = useState(null);
  const [deletingDealComment, setDeletingDealComment] = useState(null);
  const [updatingProductionStage, setUpdatingProductionStage] = useState(false);
  // referrrals
  const [referrals, setReferrals] = useState([]);
  const [createdReferral, setCreatedReferral] = useState(null);

  const [createNewReferralMeta, setCreateNewReferralMeta] = useState(null);

  //merge state
  const [mergeContactModalMeta, setMergeContactModalMeta] = useState(null);

  const [updatingHistoryDate, setUpdatingHistoryDate] = useState(null);

  const reset = ({ contactId = null, dealId = null }) => {
    // contact state
    setContactDetailsModalMeta(
      contactId ? { contactId, time: new Date() } : null
    );
    setActiveContact(null);
    setActiveContactCopy(null);
    setNewCommentText("");

    // contract state
    setDealDetailsModalMeta(dealId ? { dealId } : null);
    setActiveDeal(null);
    setActiveDealCopy(null);
    setDealNewCommentText("");

    setCreatedReferral(null);

    setUpdatedContact(null);
    setUpdatedDeal(null);
  };

  const openContactPopup = (contactId) => {
    reset({ contactId });
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("view");
    searchParams.set(CONTACT_QS, contactId);
    searchParams.set("view", CONTACT_QS);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const hideContactPopup = () => {
    sessionStorage.removeItem("contactUnsavedInfo");
    reset({});
    setContactDetailsModalMeta(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(CONTACT_QS);
    if (searchParams.has(DEAL_QS)) {
      searchParams.set("view", DEAL_QS);
    } else {
      searchParams.delete("view");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const openDealPopup = (dealId) => {
    reset({ dealId });
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("view");
    searchParams.set(DEAL_QS, dealId);
    searchParams.set("view", DEAL_QS);
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const hideDealPopup = () => {
    sessionStorage.removeItem("dealUnsavedInfo");
    reset({});
    setDealDetailsModalMeta(null);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(DEAL_QS);
    if (searchParams.has(CONTACT_QS)) {
      searchParams.set("view", CONTACT_QS);
    } else {
      searchParams.delete("view");
    }
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const editContactDetailsField = useMemo(
    () => editContactDetailsFields(tags),
    [tags]
  );

  const editContactAssignedField = useMemo(
    () => editContactAssignedFields({ users, translate }),
    [users]
  );

  const editContactStatusField = useMemo(
    () => editContactStatusFields({ translate, user, GROUPPED_STATUS }),
    [translate, user, GROUPPED_STATUS]
  );

  const editContactQuestionnaireField = useMemo(
    () => editContactQuestionnaireFields({ translate }),
    []
  );

  useEffect(() => {
    // keep unsaved contact info
    if (!activeContact) return;
    const infoToSave =
      JSON.parse(sessionStorage.getItem("contactUnsavedInfo")) || {};

    infoToSave.newCommentText = newCommentText;
    sessionStorage.setItem("contactUnsavedInfo", JSON.stringify(infoToSave));
  }, [newCommentText, activeContact]);

  useEffect(() => {
    // keep unsaved deal info
    if (!activeDeal) return;

    const infoToSave =
      JSON.parse(sessionStorage.getItem("dealUnsavedInfo")) || {};

    infoToSave.dealNewCommentText = dealNewCommentText;
    sessionStorage.setItem("dealUnsavedInfo", JSON.stringify(infoToSave));
  }, [dealNewCommentText, activeDeal]);

  useEffect(() => {
    if (checkNestedPermission([PERMISSIONS.referrers.VIEW_ALL_REFERRERS])) {
      fetchReferrals({});
    }
  }, []);

  useEffect(() => {
    if (dealDetailsModalMeta?.dealId) {
      const { dealId } = dealDetailsModalMeta;
      fetchActiveDeal(dealId);
    }
  }, [dealDetailsModalMeta?.dealId]);

  useEffect(() => {
    if (contactDetailsModalMeta?.contactId) {
      const { contactId } = contactDetailsModalMeta;
      fetchActiveContact(contactId);
    }
  }, [contactDetailsModalMeta?.contactId, contactDetailsModalMeta?.time]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contactId =
      searchParams.get(CONTACT_QS) || searchParams.get("contactId");
    const dealId = searchParams.get("deal");
    const view = searchParams.get("view");
    if (dealId && view === "deal") {
      openDealPopup(dealId);
    }
    if (contactId && view === CONTACT_QS) {
      openContactPopup(contactId);
    }
    if (
      !dealId &&
      !contactId &&
      view !== "calendar" &&
      view !== "user-record"
    ) {
      hideDealPopup();
      hideContactPopup();
    }
  }, [location.search]);

  const handleDealChange = (values) => {
    const updatedDeal = { ...activeDeal, ...values };
    sessionStorage.setItem("dealUnsavedInfo", JSON.stringify(updatedDeal));
    setActiveDeal(updatedDeal);
  };

  const onDeleteDealClick = (deal) => {
    setDeleteDealMeta({ deal });
  };

  const handleDealDelete = async () => {
    try {
      let { deal } = deleteDealMeta;
      setDeleteDealMeta({ ...deleteDealMeta, showProgress: true });
      const { response, error } = await contractService.deleteContract(deal);
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success("Deal is deleted successfully");
        hideDealPopup();
        setUpdatedDeal({
          deal: { _id: response?.id },
          action: "delete",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteDealMeta(null);
    }
  };

  const deleteDealComment = async () => {
    const { _id } = deletingDealComment;

    setDealCommentUpdating(true);

    const { response: updatedContract, error } =
      await contractService.updateContactComment(activeDeal, {
        action: "delete",
        commentId: _id,
      });

    setDealCommentUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setDeletingDealComment(null);
    toast.success(`Comment deleted successfully!`);

    let { comments = [] } = updatedContract;
    handleDealChange({ ...activeDeal, comments });
  };

  const onEditingDealCommentSubmit = async () => {
    const { _id, text } = editingDealComment;
    if (!text.trim()) {
      return toast.error("Comment cannot be empty");
    }

    setDealCommentUpdating(true);

    toast.info("Adding comment...");

    const { response: updatedContract, error } =
      await contractService.updateContactComment(activeDeal, {
        action: "update",
        text: text,
        commentId: _id,
      });

    setDealCommentUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setEditingDealComment(null);
    toast.success(`Comment updated successfully!`);
    let { comments = [] } = updatedContract;
    handleDealChange({ ...activeDeal, comments });
  };

  const onDealNewCommentSubmit = async () => {
    if (!dealNewCommentText.trim()) {
      return toast.error("Comment cannot be empty");
    }
    setDealCommentUpdating(true);

    const { response: updatedContract, error } =
      await contractService.updateContactComment(activeDeal, {
        action: "add",
        text: dealNewCommentText,
      });

    setDealCommentUpdating(false);
    if (error) {
      return toast.error(error);
    }
    let { comments = [] } = updatedContract;

    handleDealChange({ ...activeDeal, comments });
    setDealNewCommentText("");
  };

  const onProductionStageUpdate = async (reqBody) => {
    try {
      setUpdatingProductionStage(true);

      const contractUpdatePayload = {
        _id: activeDeal?._id,
        productionStage: updateProductionStageMeta?.newProductionStageId,
        ...(newCommentText.trim && {
          comment: newCommentText,
        }),
      };
      const { response, error } = await contractService.updateContract(
        contractUpdatePayload
      );
      // Handle the response and error
      if (response) {
        setActiveDeal(response);
        setUpdatedDeal({
          deal: response,
          action: "update",
        });
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateProductionStageMeta(null);
      setUpdatingProductionStage(false);
    }
  };

  const onProductionStageHistoryUpdateDate = async (reqBody) => {
    try {
      setUpdatingHistoryDate(reqBody?.stageId);
      const { response, error } =
        await contractService.updateContractProductionStageHistory(
          activeDeal._id,
          reqBody
        );
      // Handle the response and error
      if (response) {
        setActiveDeal(response);
        setUpdatedDeal({
          deal: response,
          action: "update",
        });
        toast.success("Successfully updated date!");
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingHistoryDate(null);
    }
  };

  const onContractDetailSubmit = async () => {
    try {
      setUpdatingContract(true);
      if (dealNewCommentText.trim()) activeDeal.comment = dealNewCommentText;
      const { response, error } = await contractService.updateContract(
        activeDeal
      );
      if (error) {
        return toast.error(error);
      }
      if (response) {
        toast.success("Deal updated successfully");
        hideDealPopup();
        setUpdatedDeal({
          deal: response,
          action: "update",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingContract(false);
    }
  };

  const fetchActiveDeal = async (dealId) => {
    try {
      setDealDetailsModalMeta((prevState) => ({
        ...prevState,
        showProgress: true,
      }));

      let { response, error } = await contractService.getContracts({
        filter: [{ key: "_id", value: dealId }],
      });
      if (response?.results?.length > 0) {
        let deal = response.results[0];
        const dealUnsavedInfo = JSON.parse(
          sessionStorage.getItem("dealUnsavedInfo")
        );
        if (dealUnsavedInfo && dealUnsavedInfo?._id === dealId) {
          const { dealNewCommentText, ...allInfoFields } = dealUnsavedInfo;
          setActiveDeal({ ...deal, ...allInfoFields }); // unsaved data
          setDealNewCommentText(dealNewCommentText);
        } else {
          setActiveDeal(deal);
        }
        setActiveDealCopy(deal);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDealDetailsModalMeta((prevState) => ({
        ...prevState,
        showProgress: false,
      }));
    }
  };

  // on click functions

  const sendToRujum = (contact) => {
    if (!contact?.emails?.length)
      return toast.info("This contact does not have emails");
    window.open(
      `${RUJUM_APP_URL}/admin/manage-users/customer?customerEmails=${contact?.emails?.join(
        ","
      )}`,
      "_blank"
    );
  };

  const onShowChangeLogsClick = () => {
    setShowChangeLogsModal(activeContact._id);
  };

  // functions
  const onContactChange = (contact) => {
    delete contact.__v;
    const updatedContact = cloneDeep(contact);

    const infoToSave = [
      ...editContactDetailsField,
      ...editContactAssignedField,
      ...editContactStatusField,
      ...editContactQuestionnaireField,
      { key: "description" },
      { key: "referredBy" },
      { key: "_id" },
    ].reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: contact[field.key],
      }),
      {}
    );

    infoToSave.newCommentText = newCommentText;
    sessionStorage.setItem("contactUnsavedInfo", JSON.stringify(infoToSave));
    setActiveContact(updatedContact);
  };

  const addComment = async (comment) => {
    if (!comment.trim()) {
      toast.error("Comment cannot be empty");
      return;
    }

    setIsLoading(true);
    const { response: updatedContact, error } =
      await contactService.updateContactComment(activeContact, {
        action: "add",
        text: comment,
      });

    setIsLoading(false);

    if (updatedContact) {
      toast.success("Comment appended to this contact");
      setAddExistingContactComment(null);
    }

    if (error) {
      toast.error(error);
      return;
    }

    onContactChange && onContactChange(updatedContact);
  };

  const deleteComment = async () => {
    const { _id } = deletingComment;

    setCommentsUpdating(true);

    const { response: updatedContact, error } =
      await contactService.updateContactComment(activeContact, {
        action: "delete",
        commentId: _id,
      });

    setCommentsUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setDeletingComment(null);
    toast.success(`Comment deleted successfully!`);
    let { comments = [] } = updatedContact;
    onContactChange({ ...activeContact, comments });
  };

  const onEditingCommentSubmit = async () => {
    const { _id, text } = editingComment;
    if (!text.trim()) {
      return toast.error("Comment cannot be empty");
    }

    setCommentsUpdating(true);

    toast.info("Adding comment...");

    const { response: updatedContact, error } =
      await contactService.updateContactComment(activeContact, {
        action: "update",
        text: text,
        commentId: _id,
      });

    setCommentsUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setEditingComment(null);
    toast.success(`Comment updated successfully!`);
    let { comments = [] } = updatedContact;
    onContactChange({ ...activeContact, comments });
  };

  const onNewCommentSubmit = async () => {
    if (!newCommentText.trim()) {
      return toast.error("Comment cannot be empty");
    }
    setCommentsUpdating(true);

    const { response: updatedContact, error } =
      await contactService.updateContactComment(activeContact, {
        action: "add",
        text: newCommentText,
      });

    setCommentsUpdating(false);
    if (error) {
      return toast.error(error);
    }

    setNewCommentText("");
    let { comments = [] } = updatedContact;
    onContactChange({ ...activeContact, comments });
  };

  const handleUpdateContact = async (newContact = {}) => {
    setUpdatingContact(true);

    let updatedContact = {
      ...activeContact,
      ...[
        ...editContactDetailsField,
        ...editContactAssignedField,
        ...editContactStatusField,
        ...editContactQuestionnaireField,
      ].reduce(
        (acc, field) => ({
          ...acc,
          [field.key]: activeContact[field.key],
        }),
        {}
      ),
      ...newContact,
    };

    let { contracts, phoneCalls, inbounds, ...reqBody } = updatedContact;
    reqBody.comment = newCommentText;

    const { response, error } = await contactService.updateContact(reqBody);

    setUpdatingContact(false);

    if (error) {
      return toast.error(error);
    }

    toast.success(`Contact updated successfully!`);
    hideContactPopup();
    setUpdatedContact({
      contact: response,
      action: "update",
    });
  };

  const makePhoneCall = async (phoneNumber) => {
    if (!phoneNumber) return toast.error("No phone number");
    const { response, error } = await makeRESTApiRequests({
      requestBody: {
        phoneNumber,
      },
      endpoint: ENDPOINTS.DIAL_PHONE_NUMBER,
      method: "POST",
    });

    if (error) {
      toast.error(error);
      return;
    }
    toast.success("Number dialed, please check your phone");
  };

  const fetchActiveContact = async (contactId) => {
    try {
      setContactDetailsModalMeta((prevState) => ({
        ...prevState,
        showProgress: true,
      }));

      const { response, error } = await contactService.getContacts({
        filter: [{ key: "_id", value: contactId }],
      });
      if (response?.results?.length > 0) {
        let contact = response.results[0];
        const contactUnsavedInfo = JSON.parse(
          sessionStorage.getItem("contactUnsavedInfo")
        );
        if (contactUnsavedInfo && contactUnsavedInfo?._id === contactId) {
          const { newCommentText, ...allInfoFields } = contactUnsavedInfo;
          setActiveContact({ ...contact, ...allInfoFields }); // unsaved data
          setNewCommentText(newCommentText);
        } else {
          setActiveContact(contact);
        }
        setActiveContactCopy(contact);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setContactDetailsModalMeta((prevState) => ({
        ...prevState,
        showProgress: false,
      }));
    }
  };

  // referral

  const onCreateNewReferralClick = (name) => {
    setCreateNewReferralMeta({
      data: { name, email: "", phone: "", type: "payable" },
    });
  };

  const fetchReferrals = async () => {
    try {
      let reqBody = {
        pageNumber: 1,
        pageSize: 10,
        sort: { createdAt: -1 },
      };
      let { response, error } = await referralService.getReferrals(reqBody);
      let { results } = response;

      setReferrals(results);
    } catch (error) {
      console.log(error);
    }
  };

  const createNewReferral = async (referral) => {
    try {
      setCreateNewReferralMeta({
        ...createNewReferralMeta,
        showProgress: true,
      });
      let reqBody = { ...referral, phone: referral?.phone || undefined };
      let { response, error } = await referralService.createReferral(reqBody);
      if (error) {
        toast.error(error);
        setCreateNewReferralMeta({
          ...createNewReferralMeta,
          showProgress: false,
        });
        return;
      }
      if (response) {
        let updatedReferrals = updateItemsInArray(referrals, response);
        setReferrals(updatedReferrals);
        setCreateNewReferralMeta(null);
        setCreatedReferral(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const contextObj = {
    openContactPopup,
    hideContactPopup,
    activeContact,
    openDealPopup,
    hideDealPopup,
    activeDeal,
    updatedContact,
    updatedDeal,
    referrals,
    onCreateNewReferralClick,
    createdReferral,
    setMergeConflitInUpdate,
    mergeContactModalMeta,
    setMergeContactModalMeta,
  };

  const [products, setProducts] = useState(originalProducts);

  useEffect(() => {
    if (SHOW_ARCHIVED_PRODUCTS?.[0]?.value === false) {
      setProducts(
        originalProducts.filter(
          (product) =>
            !product.archived ||
            product?._id ===
              (typeof activeDealCopy?.product === "object"
                ? activeDealCopy?.product?._id
                : activeDealCopy?.product)
        )
      );
    } else {
      setProducts(originalProducts);
    }
  }, [originalProducts, activeDealCopy]);

  return (
    <ContactAndDealPopupContext.Provider value={contextObj}>
      {children}
      <EditContactDetailsModal
        show={Boolean(contactDetailsModalMeta)}
        onHide={hideContactPopup}
        loading={contactDetailsModalMeta?.showProgress}
        editContactDetailsField={editContactDetailsField}
        editContactAssignedField={editContactAssignedField}
        editContactStatusField={editContactStatusField}
        editContactQuestionnaireField={editContactQuestionnaireField}
        contact={activeContact}
        originalContact={activeContactCopy}
        updatingContact={updatingContact}
        onChangeLogsClick={onShowChangeLogsClick}
        onContactChange={onContactChange}
        newCommentText={newCommentText}
        setNewCommentText={setNewCommentText}
        commentsUpdating={commentsUpdating}
        deletingComment={deletingComment}
        setDeletingComment={setDeletingComment}
        editingComment={editingComment}
        setEditingComment={setEditingComment}
        deleteComment={deleteComment}
        onNewCommentSubmit={onNewCommentSubmit}
        onEditingCommentSubmit={onEditingCommentSubmit}
        onUpdateContactClick={handleUpdateContact}
        makePhoneCall={makePhoneCall}
        sendToRujum={sendToRujum}
        editable={activeContact?.editable}
        referrals={referrals}
        onCreateNewReferralClick={onCreateNewReferralClick}
        createdReferral={createdReferral}
      />

      <UpdateDealModal
        show={Boolean(dealDetailsModalMeta)}
        onHide={hideDealPopup}
        products={products}
        originalContract={activeDealCopy}
        contract={activeDeal}
        makePhoneCall={makePhoneCall}
        onProductionStageChange={(meta) => setUpdateProductionStageMeta(meta)}
        newCommentText={dealNewCommentText}
        commentsUpdating={dealCommentUpdating}
        deletingComment={deletingDealComment}
        editingComment={editingDealComment}
        setNewCommentText={setDealNewCommentText}
        setDeletingComment={setDeletingDealComment}
        setEditingComment={setEditingDealComment}
        deleteComment={deleteDealComment}
        onNewCommentSubmit={onDealNewCommentSubmit}
        onEditingCommentSubmit={onEditingDealCommentSubmit}
        updatingContract={updatingContract}
        onContractDetailSubmit={onContractDetailSubmit}
        onDealChange={handleDealChange}
        onDeleteDealClick={onDeleteDealClick}
        editable={activeDeal?.editable}
        deletable={activeDeal?.deletable}
        sendToRujum={sendToRujum}
        fetching={dealDetailsModalMeta?.showProgress}
        referrals={referrals}
        onUpdateDate={onProductionStageHistoryUpdateDate}
        updatingHistoryDate={updatingHistoryDate}
      />
      <AlertModal
        show={Boolean(updateProductionStageMeta)}
        onHide={() => setUpdateProductionStageMeta(null)}
        onDismissClick={() => setUpdateProductionStageMeta(null)}
        alertText={translate("are_you_sure_to_change_production_stage")}
        onContinueClick={onProductionStageUpdate}
        showProgress={updatingProductionStage}
      />
      <AlertModal
        show={Boolean(deleteDealMeta)}
        onHide={() => setDeleteDealMeta(null)}
        onDismissClick={() => setDeleteDealMeta(null)}
        alertText={translate("are_you_sure_you_want_to_delete_this_deal")}
        onContinueClick={handleDealDelete}
        showProgress={deleteDealMeta?.showProgress}
      />
      <AlertModal
        onContinueClick={() => addComment(addExistingContactComment)}
        show={Boolean(addExistingContactComment)}
        showProgress={isLoading}
        onDismissClick={() => setAddExistingContactComment(null)}
        alertText={translate(
          "would_you_like_to_append_this_comment_to_an_existing_contact"
        )}
      />
      <CreateNewReferralModal
        show={createNewReferralMeta}
        onHide={() => setCreateNewReferralMeta(null)}
        data={createNewReferralMeta?.data}
        showProgress={createNewReferralMeta?.showProgress}
        onSubmit={createNewReferral}
      />
      <LogModal
        show={showChangeLogsModal}
        onHide={() => setShowChangeLogsModal(null)}
        contactId={showChangeLogsModal}
      />
    </ContactAndDealPopupContext.Provider>
  );
};
