import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { InfoCircleFill, Send } from "react-bootstrap-icons";
import { DEFAULT_DATE_TIME_FORMAT } from "../../../helpers/constants";
import { getSurveyFormLink } from "../../../helpers/global";
import CircularProgressBar from "../../common/circular-progress";
import DataTable from "../../common/data-table/DataTable";

const surveyResponseColumns = [
  {
    key: "respondentEmail",
    label: "Respondent email",
    disableSort: true,
    disableSearch: true,
  },
  {
    key: "responseDateTime",
    label: "Completion time",
    type: "date",
    dateFormat: DEFAULT_DATE_TIME_FORMAT,
    disableSort: true,
    disableSearch: true,
  },
  {
    key: "responseId",
    label: "URl",
    cellRenderer: (r) => (
      <a target="blank" href={getSurveyFormLink(r.responseId)}>
        View
      </a>
    ),
    disableSort: true,
    disableSearch: true,
  },
];

const SurveyField = ({ contact, sendingSurveyForm, sendSurveyForm }) => {
  const responses = useMemo(() => {
    return (
      contact?.surveyResponses?.sort(
        (r1, r2) =>
          new Date(r2.responseDateTime) - new Date(r1.responseDateTime)
      ) || []
    );
  }, [contact]);

  return contact?.surveySent || contact?.surveyCompleted ? (
    <div className="d-flex p-2 border rounded mid">
      {contact?.surveyCompleted ? (
        <div className="d-flex flex-column gap-1 w-100">
          <h6 className="smallFont">
            {" "}
            The survey has been completed. Below are the responses.
          </h6>
          <DataTable
            rowKey={"responseId"}
            columns={surveyResponseColumns}
            data={responses}
            allowFilter={false}
            allowSort={false}
          />
        </div>
      ) : (
        <div className="d-flex gap-3 align-items-center">
          <InfoCircleFill size={20} className="text-info" />
          The survey form has been sent. Once the survey is completed, you will
          see the URL of the form.
        </div>
      )}
    </div>
  ) : (
    <Button
      className="text-white d-flex gap-2 align-items-center"
      size="sm"
      disabled={sendingSurveyForm}
      onClick={sendSurveyForm}
    >
      {sendingSurveyForm ? (
        <>
          <CircularProgressBar variant="light" size={15} /> Sending survey form
        </>
      ) : (
        <>
          <Send />
          Send survey form
        </>
      )}
    </Button>
  );
};

export default SurveyField;
