import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { Badge, Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import * as Yup from "yup";
import useLocalization from "../../hooks/useLocalization";
import CurrencyInput from "../common/CurrencyInput";
import HorizontalProgress from "../common/HorizontalProgress";
import { Eye, EyeSlash, Trash } from "react-bootstrap-icons";
import UserDropdown from "../common/UserDropdown";

// Yup validation schema
const validationSchema = Yup.object().shape({
  description: Yup.string().required("Please provide the product description"),
  price: Yup.number().required("Please provide the product price"),
});

const defaultValues = {
  description: "",
  price: 0,
  assignedTo: [],
};
const AddEditProductForm = ({
  initialValues,
  showProgress,
  editMode,
  onSubmit,
  archiveProduct,
  allowDelete,
  onDeleteProductClick,
}) => {
  const { translate } = useLocalization();
  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Form className="">
            <Row className="m-0">
              <Col xs={6} md={2}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("price")}
                  </FormLabel>

                  <CurrencyInput
                    value={values.price}
                    onChange={(price) => setFieldValue("price", price)}
                  />
                  <ErrorMessage
                    name={"price"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("description")}
                  </FormLabel>

                  <Field
                    name={"description"}
                    className="form-control form-control-sm"
                    rows={2}
                  />
                  <ErrorMessage
                    name={"description"}
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
            </Row>

            {showProgress && (
              <HorizontalProgress text={`${translate("please_wait")}`} />
            )}
            <hr className="my-2" />
            <div className="d-flex align-items-center justify-content-between">
              {editMode && (
                <div className="d-flex justify-content-center align-items-center gap-2">
                  {initialValues?.archived ? (
                    <Badge
                      role="button"
                      bg="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        archiveProduct({ ...initialValues, archived: false });
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Unarchive</span>
                        <Eye size={15} />
                      </div>
                    </Badge>
                  ) : (
                    <Badge
                      role="button"
                      bg="purple"
                      onClick={(e) => {
                        e.preventDefault();
                        archiveProduct({ ...initialValues, archived: true });
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Archive</span>
                        <EyeSlash size={15} />
                      </div>
                    </Badge>
                  )}
                  {allowDelete && (
                    <Badge
                      role="button"
                      bg="danger"
                      onClick={(e) => {
                        onDeleteProductClick(initialValues);
                        e.stopPropagation();
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <span>Delete</span>
                        <Trash size={15} />
                      </div>
                    </Badge>
                  )}
                </div>
              )}
              <Button
                className="mt-2"
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {translate(!editMode ? "add" : "update")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditProductForm;
