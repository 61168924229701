import React from "react";
import CustomMultiSelect from "./CustomMultiSelect";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";
import { GROUPPED_PRODUCTS } from "../../helpers/global";

const ProductDropdown = ({
  selectedProduct,
  onChange,
  height = "25px",
  maxToShow,
  maxItemCustomMessage,
  isMultiSelect = false,
  closeMenuOnSelect = true,
}) => {
  const units = useAppChoices("units");
  const { translate } = useLocalization();
  return (
    <CustomMultiSelect
      isMulti={isMultiSelect}
      selectedItems={selectedProduct}
      items={GROUPPED_PRODUCTS({ products: units, translate })}
      onChange={onChange}
      placeholder={translate("select_product")}
      height={height}
      closeMenuOnSelect={closeMenuOnSelect}
      maxToShow={maxToShow}
      maxItemCustomMessage={maxItemCustomMessage}
      isGroupped={true}
    />
  );
};

export default ProductDropdown;
