import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getInboundTableColumns } from "../../../helpers/dataSheetConstants";
import { miscService } from "../../../services/miscService";
import DataTable from "../../common/data-table/DataTable";
import InboundDetailsModal from "./InboundDetailsModal";

const Inbounds = ({ contact, height = "150px" }) => {
  const [inboundSourceDetailsToShow, setInboundSourceDetailsToShow] =
    useState(null);

  const onDetailsClick = (inbound) => {
    setInboundSourceDetailsToShow(inbound?.sourceDetails);
  };

  const onCampaignClick = async (inbound) => {
    const campaignId = inbound?.sourceDetails?.campaignId;
    const adSetId = inbound?.sourceDetails?.adSetId;
    const adId = inbound?.sourceDetails?.adId;

    if (!campaignId || !adSetId || !adId) {
      return toast.error("No Ad Id found in the source details");
    }

    const { response: ads = [], error } = await miscService.getFacebookAds(
      null,
      {
        campaignId,
        adSetId,
      }
    );

    if (error) {
      return toast.error(error);
    }

    const adPreviewLink = ads.find(
      (a) => a.id === adId
    )?.preview_shareable_link;

    if (!adPreviewLink) {
      return toast.error("Preview link not found");
    }

    window.open(adPreviewLink, "_blank");
  };

  const tableColumns = useMemo(
    () =>
      getInboundTableColumns({
        onDetailsClick,
        onCampaignClick,
        columnToHide: ["contact", "email"],
      }),
    [onDetailsClick]
  );

  const sortedInbounds = useMemo(
    () =>
      contact?.inbounds?.sort(
        (i1, i2) => new Date(i2.date) - new Date(i1.date)
      ) || [],
    [contact]
  );

  return (
    <>
      <DataTable
        maxTableHeight={height}
        rowKey={"_id"}
        columns={tableColumns}
        data={sortedInbounds}
        allowSort={false}
        allowFilter={false}
      />

      {sortedInbounds.length > 0 && (
        <InboundDetailsModal
          show={Boolean(inboundSourceDetailsToShow)}
          onHide={() => setInboundSourceDetailsToShow(null)}
          inbound={inboundSourceDetailsToShow}
        />
      )}
    </>
  );
};

export default Inbounds;
