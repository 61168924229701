import { startCase } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  ArrowRightCircleFill,
  CalendarEventFill,
  CameraVideoFill,
  Diagram2Fill,
  Facebook,
  FunnelFill,
  Globe,
  PersonPlusFill,
  QuestionCircleFill,
} from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../../helpers/api";
import { getLeadsTableColumn } from "../../../../helpers/dataSheetConstants";
import {
  convertToFunnelChartData,
  getDashboardLineCartData,
} from "../../../../helpers/global";
import useLocalization from "../../../../hooks/useLocalization";
import { dashboardService } from "../../../../services/dashboardService";
import DashboardDataNavigator from "../../../common/DashboardDataNavigator";
import DataTable from "../../../common/data-table/DataTable";
import LineChart from "../../../common/graph/LineChart";
import HorizontalTabs from "../../../common/HorizontalTabs";
import Loader from "../../../common/Loader";
import MarketingTable from "../../MarketingTable";
import StatCard from "../../StatCard";
import InboundTable from "../InboundTable";
import FunnelChart from "./FunnelChart";
import { DEFAULT_PAGE_SIZE } from "../../../../helpers/constants";
import useContactAndDealPopup from "../../../../hooks/useContactAndDealPopup";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const getFunnelProgressColumns = () => [
  {
    key: "ratio",
    label: "Ratio %",
    disableSearch: true,
    disableSort: true,
  },
  {
    key: "amount",
    label: "Amount (of change)",
    disableSearch: true,
    disableSort: true,
  },
  {
    key: "status",
    label: "Status",
    disableSearch: true,
    disableSort: true,
  },
];

const MarketingStatBoard = ({
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  dateRange,
  selectedUser,
}) => {
  const abortControllerRef = useRef(null);
  const leadsAbortControllerRef = useRef(null);
  const { openContactPopup } = useContactAndDealPopup();
  const { translate } = useLocalization();
  const [activeStat, setActiveStat] = useState(null);
  const [activeSecondaryStat, setActiveSecondaryStat] = useState("webinar");
  const [leadsData, setLeadsData] = useState([]);
  const [marketingStatsData, setMarketingStats] = useState([]);
  const [initialFetching, setInitialFetching] = useState(false);
  const [navigatingData, setNavigatingData] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);

  const [fetchingFirstPageLeads, setFetchingFirstPageLeads] = useState(false);
  const [fetchingMoreLeads, setFetchingMoreLeads] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(null);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const fetchMarketingStats = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;
    if (isNavigation) {
      setNavigatingData(true);
    } else {
      setInitialFetching(true);
    }

    try {
      const { startDate, endDate } = dateRange;
      const reqBody = { startDate, endDate, salesperson: selectedUser };
      let { response, error } = await dashboardService.getMarketingStats(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;
      if (response) {
        setMarketingStats(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    } finally {
      if (controller === abortControllerRef.current) {
        setInitialFetching(false);
        setNavigatingData(false);
        setIsNavigation(false);
        abortControllerRef.current = null;
      }
    }
  };

  const fetchMarketingLeadsStats = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (leadsAbortControllerRef.current) {
      leadsAbortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch } = toLoadPageInfo;
    const loadingMoreApps = pageToFetch > 1;

    if (loadingMoreApps) {
      setFetchingMoreLeads(true);
    } else {
      setFetchingFirstPageLeads(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    leadsAbortControllerRef.current = controller;
    const { startDate, endDate } = dateRange;
    try {
      const reqBody = {
        startDate,
        endDate,
        leadSource: activeSecondaryStat,
        salesperson: selectedUser,
        pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
        pageNumber: pageToFetch,
      };
      let { response, error } = await dashboardService.getMarketingLeads(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;

      setFetchingMoreLeads(false);
      setFetchingFirstPageLeads(false);

      if (error) {
        return toast.error(error);
      }

      const { pageNumber, totalPages, results = [], resultCount } = response;

      setLoadedPageInfo({ totalPages, pageNumber, resultCount });

      setLeadsData((prev) => {
        const newArray = loadingMoreApps ? [...prev, ...results] : results;

        return newArray;
      });
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    }
  };

  useEffect(() => {
    if (toLoadPageInfo) {
      fetchMarketingLeadsStats();
    }
  }, [toLoadPageInfo]);

  useEffect(() => {
    if (activeStat?.name === "leads") {
      setToLoadPageInfo({ ...initialPageInfo });
    } else {
      setToLoadPageInfo(null);
    }
  }, [activeStat, activeSecondaryStat, dateRange, selectedUser]);

  useEffect(() => {
    fetchMarketingStats();
  }, [dateRange, selectedUser]);

  const loadMoreLeadsData = () => {
    if (
      !loadedPageInfo ||
      fetchingFirstPageLeads ||
      fetchingMoreLeads ||
      loadedPageInfo.pageNumber >= loadedPageInfo.totalPages
    ) {
      return;
    }

    setToLoadPageInfo((prev) => ({
      ...prev,
      pageNumber: loadedPageInfo.pageNumber + 1,
    }));
  };

  const navigateDates = (type, dir) => {
    setIsNavigation(true);
    handleNavigateDate(type, dir);
  };

  const marketingStats = useMemo(() => {
    return [
      {
        name: "inbounds",
        variant: "primary",
        icon: PersonPlusFill,
        data: marketingStatsData?.data?.inbounds?.totalCount,
      },
      {
        name: "leads",
        data: marketingStatsData?.data?.leads?.totalCount,
        variant: "secondary",
        icon: Diagram2Fill,
      },
      {
        name: "events",
        data: "-",
        variant: "info",
        icon: CalendarEventFill,
      },
      {
        name: "funnel",
        data: "-",
        variant: "dark",
        icon: FunnelFill,
      },
    ];
  }, [marketingStatsData]);

  const leadSourceConfig = useMemo(() => {
    const data = marketingStatsData?.data?.[activeStat?.name]?.details;
    if (!data) return [];
    const chartInterval = marketingStatsData?.data?.inbounds?.chartInterval;
    return [
      {
        icon: CameraVideoFill,
        variant: "success",
        label: "Webinar",
        key: "webinar",
        data: data?.webinar?.count,
        chartData: getDashboardLineCartData(
          data?.webinar?.chartData,
          "Webinar",
          chartInterval
        ),
        tableData: data?.webinar?.tableData,
      },
      {
        icon: Globe,
        variant: "primary",
        label: "Website",
        key: "website",
        data: data?.website?.count,
        chartData: getDashboardLineCartData(
          data?.website?.chartData,
          "Website",
          chartInterval
        ),
        tableData: data?.website?.tableData,
      },
      {
        icon: QuestionCircleFill,
        variant: "purple",
        label: "Other",
        key: "other",
        data: data?.other?.count,
        chartData: getDashboardLineCartData(
          data?.other?.chartData,
          "Other",
          chartInterval
        ),
        tableData: data?.other?.tableData,
      },
      {
        icon: ArrowRightCircleFill,
        variant: "warning",
        label: "Direct",
        key: "direct",
        data: data?.direct?.count,
        chartData: getDashboardLineCartData(
          data?.direct?.chartData,
          "Direct",
          chartInterval
        ),
        tableData: data?.direct?.tableData,
      },
      {
        icon: Facebook,
        variant: "info",
        label: "Facebook",
        key: "facebook",
        data: data?.facebook?.count,
        chartData: getDashboardLineCartData(
          data?.facebook?.chartData,
          "Facebook",
          chartInterval
        ),
        tableData: data?.facebook?.tableData,
      },
    ];
  }, [marketingStatsData, activeStat]);

  const activeTabData = useMemo(
    () => leadSourceConfig.find((c) => c.key === activeSecondaryStat),
    [leadSourceConfig, activeSecondaryStat]
  );

  const leadsTableColumns = useMemo(
    () => getLeadsTableColumn({ openContactPopup }),
    [openContactPopup]
  );

  const funnelProgressColumns = useMemo(() => getFunnelProgressColumns(), []);

  const funnelChartData = useMemo(
    () =>
      marketingStatsData?.data?.funnelProgress &&
      convertToFunnelChartData(
        marketingStatsData.data.funnelProgress,
        "Conversion ratio"
      ),
    [marketingStatsData]
  );

  const funnelProgressTableData = useMemo(
    () =>
      (marketingStatsData?.data?.funnelRatios &&
        Object.entries(marketingStatsData.data.funnelRatios).map(
          ([status, ratio], index) => ({
            status: startCase(status),
            ratio,
            amount: marketingStatsData?.data?.funnelProgress[status] ?? 0,
          })
        )) ||
      [],
    [marketingStatsData]
  );

  useEffect(() => {
    setActiveStat(
      !activeStat
        ? marketingStats?.[0]
        : marketingStats?.find((s) => s?.name === activeStat?.name)
    );
  }, [marketingStats]);

  const handleCardClick = (stat) => {
    setActiveStat(stat);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Row className="my-2 mx-1">
        {marketingStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={3} md={3} sm={12} xs={12}>
            <StatCard
              stat={stat}
              active={activeStat?.name === stat?.name}
              fetching={initialFetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>
      <hr className="my-1" />
      {["leads", "inbounds"].includes(activeStat?.name) && (
        <Row className="pt-2 m-0">
          <Col lg={3} md={3} sm={12} xs={12} className="p-0">
            <div className="d-flex flex-column gap-2">
              <HorizontalTabs
                loading={initialFetching}
                tabs={leadSourceConfig}
                onTabChange={setActiveSecondaryStat}
                selectedTab={activeSecondaryStat}
                height={230}
              />
            </div>
          </Col>
          <Col lg={9} md={9} sm={12} xs={12}>
            {" "}
            {initialFetching ? (
              <div className="custom-card">
                <Loader />
              </div>
            ) : (
              <div className="d-flex flex-column gap-2">
                {activeTabData?.chartData && (
                  <LineChart
                    chartData={activeTabData.chartData}
                    chartName={activeSecondaryStat}
                    loading={initialFetching}
                    randerExtraInfo={() => (
                      <DashboardDataNavigator
                        rangeType={rangeType}
                        dateRangeLabel={dateRangeLabel}
                        handleNavigateDate={navigateDates}
                        loading={navigatingData}
                      />
                    )}
                    hideContiniousFalsyDataFromLast
                  />
                )}{" "}
                {activeStat?.name === "leads" && (
                  <div className="custom-card">
                    <h6 className="xxlarge fw-bold">
                      {" "}
                      {startCase(activeSecondaryStat)}
                    </h6>
                    <DataTable
                      columns={leadsTableColumns}
                      data={leadsData}
                      maxTableHeight={`500px`}
                      onRowClick={(contact) => openContactPopup(contact?._id)}
                      rowKey={"_id"}
                      loadingFirstPageData={fetchingFirstPageLeads}
                      loadingMoreData={fetchingMoreLeads}
                      onBottomReached={loadMoreLeadsData}
                      allowSort={false}
                      allowFilter={false}
                    />
                  </div>
                )}{" "}
                {activeStat?.name === "inbounds" && (
                  <InboundTable
                    dateRange={dateRange}
                    leadSource={activeSecondaryStat}
                    users={selectedUser}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
      {activeStat?.name === "events" && <MarketingTable />}
      {activeStat?.name === "funnel" && (
        <Row className="pt-2 m-0">
          <Col lg={3} md={3} sm={12} xs={12} className="px-1">
            {funnelChartData && (
              <FunnelChart
                data={funnelChartData}
                title={"Progress"}
                aspectRatio={1}
                loading={initialFetching}
                containerStyle={{ height: 400, width: "100%" }}
              />
            )}
          </Col>
          <Col lg={9} md={9} sm={12} xs={12} className="px-1">
            {marketingStatsData?.data?.funnelRatios && (
              <div className="custom-card">
                {initialFetching ? (
                  <Loader />
                ) : (
                  <>
                    <h6 className="large fw-bold">Ratios</h6>
                    <DataTable
                      rowKey={"status"}
                      columns={funnelProgressColumns}
                      data={funnelProgressTableData}
                    />
                  </>
                )}
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default MarketingStatBoard;
