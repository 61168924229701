import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";
import { commonChartConfig } from "../../../helpers/constants";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ContentLoader from "react-content-loader";
import { snakeCase } from "lodash";
import { formatCurrency } from "../../../helpers/global";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

const zoomOptions = {
  pan: {
    enabled: true,
    mode: "xy",
    modifierKey: "alt",
    onPanStart: function ({ chart, point }) {
      const area = chart.chartArea;
      if (
        point.x < area.left ||
        point.x > area.right ||
        point.y < area.top ||
        point.y > area.bottom
      ) {
        return false; // abort
      }
    },
  },
  zoom: {
    mode: "xy",
    drag: {
      enabled: true,
      borderColor: "rgb(54, 162, 235)",
      borderWidth: 1,
      backgroundColor: "rgba(54, 162, 235, 0.3)",
    },
    onZoomStart: function ({ chart, point }) {
      const area = chart.chartArea;
      if (
        point.x < area.left ||
        point.x > area.right ||
        point.y < area.top ||
        point.y > area.bottom
      ) {
        return false; // abort
      }
    },
  },
};

const LineChart = ({
  chartData: originalChartData,
  onMouseHover,
  onMouseExit,
  visibleLines = [],
  onVisibleLinesChange,
  shouldFillBetweenLines = false,
  loading,
  chartName,
  randerExtraInfo = null,
  hideContiniousFalsyDataFromLast = false,
}) => {
  const chartRef = useRef(null);
  const { isRtl, translate } = useLocalization();
  const [chartData, setChartData] = useState({ ...originalChartData });

  const removeFalsyValuesFromEnd = (data) => {
    const datasets = data.datasets;

    // Function to find the last non-falsy index in an array
    function findLastNonFalsyIndex(arr) {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i]) {
          return i;
        }
      }
      return -1;
    }

    // Find the last index where there is a non-falsy value across all datasets
    let lastIndex = -1;
    datasets.forEach((dataset) => {
      const lastNonFalsyIndex = findLastNonFalsyIndex(dataset.data);
      if (lastNonFalsyIndex > lastIndex) {
        lastIndex = lastNonFalsyIndex;
      }
    });

    if (lastIndex === -1) {
      // All values are falsy, clear the labels and datasets
      data.datasets.forEach((dataset) => {
        dataset.data = [];
      });
    } else {
      // Trim the labels and datasets to the last index
      data.datasets.forEach((dataset) => {
        dataset.data = dataset.data.slice(0, lastIndex + 1);
      });
    }

    return data;
  };

  useEffect(() => {
    let chartData = originalChartData;
    if (hideContiniousFalsyDataFromLast) {
      chartData = removeFalsyValuesFromEnd(originalChartData);
    }

    const newChartData = {
      ...chartData,
      datasets: [
        {
          label: translate("select_unselect_all"),
          data: [], // no data
        },
        ...chartData.datasets,
      ],
    };

    if (shouldFillBetweenLines && chartData.datasets.length >= 2) {
      const data1 = chartData.datasets[0].data;
      const data2 = chartData.datasets[1].data;

      // Create two arrays to hold the area data
      const areaDataGreater = new Array(data1.length).fill(null);
      const areaDataLesser = new Array(data1.length).fill(null);

      data1.forEach((income, index) => {
        const incomeValue = Number(income);
        const expenseValue = Number(data2[index]);
        if (incomeValue > expenseValue) {
          // Fill in the areaDataGreater array
          areaDataGreater[index] = incomeValue;
        } else {
          // Fill in the areaDataLesser array
          areaDataLesser[index] = expenseValue;
        }
      });

      const ctx = chartRef.current?.ctx;
      if (ctx) {
        const gradientGreen = ctx.createLinearGradient(
          0,
          0,
          0,
          ctx.canvas.clientHeight
        );
        gradientGreen.addColorStop(0, "rgba(30, 200, 0, 0.2)");
        gradientGreen.addColorStop(0.5, "rgba(30, 200, 0, 0.2)");
        gradientGreen.addColorStop(1, "transparent");

        const gradientRed = ctx.createLinearGradient(
          0,
          0,
          0,
          ctx.canvas.clientHeight
        );
        gradientRed.addColorStop(0, "rgba(255, 0, 0, 0.2)");
        gradientRed.addColorStop(0.5, "rgba(255, 0, 0, 0.2)");
        gradientRed.addColorStop(1, "transparent");

        newChartData.datasets.push({
          label: "Area Greater",
          data: areaDataGreater,
          backgroundColor: gradientGreen,
          borderColor: "transparent",
          fill: "-1",
        });

        newChartData.datasets.push({
          label: "Area Lesser",
          data: areaDataLesser,
          backgroundColor: gradientRed,
          borderColor: "transparent",
          fill: "1",
        });
      }
    }

    setChartData(newChartData);
  }, [
    shouldFillBetweenLines,
    originalChartData,
    translate,
    hideContiniousFalsyDataFromLast,
  ]);

  function handleResetZoom() {
    const chart = chartRef.current;
    chart.resetZoom();
  }

  const commonConfig = useMemo(() => commonChartConfig(isRtl), [isRtl]);

  function handleLegendClick(event, legendItem) {
    const chart = chartRef.current;
    if (legendItem.datasetIndex === 0) {
      // assuming the last dataset is the select/unselect all option
      const visible = !chart.data.datasets[0].hidden; // invert visibility of the first dataset
      chart.data.datasets.forEach((dataset) => {
        dataset.hidden = visible;
      });
      chart.update();
      onVisibleLinesChange &&
        onVisibleLinesChange(
          chart.data.datasets.map((dataset) => !dataset.hidden)
        );
    } else {
      // default behaviour
      const dataset = chart.data.datasets[legendItem.datasetIndex];
      dataset.hidden = !dataset.hidden;
      chart.update();
      const updatedVisibility = [...visibleLines];
      updatedVisibility[legendItem.datasetIndex - 1] =
        !updatedVisibility[legendItem.datasetIndex - 1];
      onVisibleLinesChange && onVisibleLinesChange(updatedVisibility);
    }
  }

  return (
    <div style={{ height: 360 }} className="custom-card">
      {loading ? (
        <ContentLoader width={"100%"}>
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="15" />
          <rect x="0" y="18" rx="4" ry="4" width="100%" height="100%" />
        </ContentLoader>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="xxlarge fw-bold flex-grow-1">
              {translate(snakeCase(chartName)) || chartName}
            </h6>
            {randerExtraInfo && randerExtraInfo()}
          </div>

          <div className="d-flex justify-content-between w-100 my-1 px-2 mt-3">
            <h6 className="fw-bold smallFont text-muted">
              {translate("press_alt_to_move")}
            </h6>
            <Button
              varient="primary"
              size="sm"
              className="px-1 py-0"
              onClick={handleResetZoom}
            >
              {translate("reset_zoom")}
            </Button>
          </div>
          <div
            className=""
            onMouseLeave={() => {
              onMouseExit && onMouseExit();
            }}
          >
            <Line
              height={320}
              responsive={true}
              ref={chartRef}
              data={chartData}
              options={{
                ...commonConfig,
                onHover: (event, chartElement) => {
                  if (chartElement[0]) {
                    onMouseHover && onMouseHover(chartElement[0]);
                  }
                },
                plugins: {
                  ...commonConfig.plugins,
                  legend: {
                    ...commonConfig.plugins.legend,
                    labels: {
                      ...commonConfig.plugins.legend.labels,
                      // Use a filter function to exclude certain datasets from the legend
                      filter: function (legendItem, data) {
                        // Exclude datasets with specific labels
                        const label =
                          data.datasets[legendItem.datasetIndex].label;
                        return (
                          label !== "Area Greater" && label !== "Area Lesser"
                        );
                      },
                    },

                    onClick: handleLegendClick,
                  },
                  tooltip: {
                    ...commonConfig.plugins.tooltip,
                    callbacks: {
                      // Custom label callback to modify tooltip labels
                      label: function (context) {
                        let label = context.dataset.label || "";
                        if (label === "Income" || label === "Expense") {
                          const value = context.parsed.y;
                          return `${label}: ${formatCurrency(value)}`;
                        }

                        if (
                          label === "Area Greater" ||
                          label === "Area Lesser"
                        ) {
                          // Exclude 'Area Greater' and 'Area Lesser' from the tooltip
                          return null;
                        }
                        // Default label format
                        return `${label}: ${formatCurrency(context.parsed.y)}`;
                      },
                      // Additional tooltip callback to show the difference
                      afterBody: function (context) {
                        if (!shouldFillBetweenLines) {
                          return null;
                        }
                        // Assuming 'Income' is the first dataset and 'Expense' is the second
                        const incomeValue = context[0].parsed.y;
                        const expenseValue = context[1].parsed.y;
                        const difference = incomeValue - expenseValue;
                        return `Difference: ${formatCurrency(difference)}`;
                      },
                    },
                    filter: function (item, data) {
                      // Filter out 'Area Greater' and 'Area Lesser' from the tooltip items
                      const label = item.dataset.label;
                      return (
                        label !== "Area Greater" && label !== "Area Lesser"
                      );
                    },
                  },
                  zoom: zoomOptions,
                },
                elements: {
                  point: {
                    radius: 1,
                  },
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LineChart;
