import classnames from "classnames";
import { snakeCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CheckLg, PencilFill, Plus, XLg } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../helpers/constants";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "../common/circular-progress";
import UnderlineButton from "../common/UnderlineButton";

const StepCard = React.memo(
  ({
    isStart,
    isEnd,
    isCurrent,
    stage,
    accomplished,
    onProductionStageChange,
    productionStageHistory,
    onUpdateDate,
    updatingHistoryDate,
    initialProductionStage,
    currentProductionStage,
    unit,
  }) => {
    const showMoreInfo = isStart || isEnd || isCurrent;
    const { translate, isRTL } = useLocalization();

    const [showActionButtons, setShowActionButtons] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const initialHistoryUpdateDate = useMemo(() => {
      const filteredStage = productionStageHistory?.find(
        (s) => s?.stage === stage?._id
      );

      return filteredStage?.updateDate
        ? new Date(filteredStage.updateDate)
        : null;
    }, [stage, productionStageHistory]);

    const isThisANewProductionStage = useMemo(() => {
      return (
        initialProductionStage !== currentProductionStage &&
        stage?._id === currentProductionStage
      );
    }, [stage, initialProductionStage, currentProductionStage]);

    const [selectedDate, setSelectedDate] = useState(initialHistoryUpdateDate);

    const handleDateChange = (date) => {
      setShowActionButtons(true);
      setSelectedDate(date);
    };

    const handleAddNewDate = () => {
      setSelectedDate(new Date());
      setShowActionButtons(true);
      setIsDatePickerOpen(true);
    };

    const handleCancleDateEdit = () => {
      setSelectedDate(initialHistoryUpdateDate);
      setShowActionButtons(false);
    };

    const handleDateUpdate = () => {
      onUpdateDate({
        stageId: stage?._id,
        updateDate: selectedDate,
        unit: isThisANewProductionStage ? unit : undefined,
      });
      setShowActionButtons(false);
    };

    const stepClass = classnames({
      "bg-dark-green text-white": accomplished,
      "bg-gray border-dark-green": !accomplished,
      "p-relative border border-light hover": true,
    });

    return (
      <OverlayTrigger
        delay={{ hide: 250, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props} width={1000}>
            <h6 className="mb-0 smallFont">{stage?.step}</h6>
          </Tooltip>
        )}
        placement={"top"}
      >
        <div
          onClick={() => onProductionStageChange(stage?._id)}
          className={stepClass}
          style={{
            height: 22,
            width: 22,
            borderRadius: "50%",
            marginLeft: isStart ? 8 : -10,
          }}
        >
          <div
            className="text-dark"
            style={{
              position: "absolute",
              maxWidth: "120px",
              minWidth: 50,
              right: isEnd ? 0 : undefined,
              left: isStart ? 8 : undefined,
              marginTop: 20,
            }}
          >
            <h6
              style={{ marginBottom: 2 }}
              className="smallFont truncate fw-bold"
            >
              {translate(snakeCase(stage?.step)) || stage?.step || ""}
            </h6>
            {accomplished && (
              <div
                style={{ height: 15 }}
                className="tiny d-flex gap-1 align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {selectedDate ? (
                  <>
                    <DatePicker
                      className={`relative m-0 text-dark tiny truncate`}
                      selected={selectedDate}
                      dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                      onChange={handleDateChange}
                      open={isDatePickerOpen}
                      onClickOutside={() => setIsDatePickerOpen(false)}
                      onInputClick={() => setIsDatePickerOpen(true)}
                      showYearDropdown
                      showMonthDropdown
                      showTimeInput
                      timeIntervals={15}
                      dropdownMode="scroll"
                      timeFormat="HH:mm"
                      clearButtonClassName="text-dark"
                      portalId="root"
                      popperClassName="datepickerPopperClassName"
                      ref={(ref) => {
                        if (ref) {
                          const input = ref.input;
                          if (input) {
                            input.style.setProperty(
                              "background",
                              "transparent",
                              "important"
                            );
                            input.style.setProperty(
                              "width",
                              "85px",
                              "important"
                            );
                            input.style.setProperty(
                              "border",
                              "none",
                              "important"
                            );
                            input.style.setProperty(
                              "color",
                              "#000",
                              "important"
                            );
                            input.style.setProperty(
                              "outline",
                              "none",
                              "important"
                            );
                            input.style.setProperty(
                              "box-shadow",
                              "none",
                              "important"
                            );
                          }
                        }
                      }}
                    />
                    {updatingHistoryDate === stage?._id ? (
                      <CircularProgressBar variant="success" size={15} />
                    ) : showActionButtons ? (
                      <>
                        <CheckLg
                          className="text-success hover-light rounded"
                          size={15}
                          onClick={handleDateUpdate}
                        />{" "}
                        <XLg
                          className="text-danger hover-light rounded"
                          size={12}
                          onClick={handleCancleDateEdit}
                        />
                      </>
                    ) : !isDatePickerOpen ? (
                      <PencilFill
                        className="text-success hover-light rounded"
                        size={12}
                        onClick={() => setIsDatePickerOpen(true)}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <UnderlineButton
                    Icon={Plus}
                    text={"Date"}
                    variant="success"
                    onClick={handleAddNewDate}
                    className="px-0"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </OverlayTrigger>
    );
  }
);

const ProductionStageProgress = ({
  className,
  label,
  productionStages,
  productionStageHistory,
  currentProductionStage,
  initialProductionStage,
  onProductionStageChange,
  disabled,
  updatingHistoryDate,
  onUpdateDate,
  unit,
}) => {
  const [stepPositions, setStepPositions] = useState([]);

  function calculatePositions(productionStages) {
    const numStages = productionStages?.length;
    const step = 100 / (numStages - 1);
    const positions = [];
    for (let i = 0; i < numStages; i++) {
      positions.push(i === 0 ? 0.1 : step * i);
    }
    return positions;
  }

  const percent = useMemo(() => {
    const valuePosition = productionStages?.findIndex(
      (s) => s?._id === currentProductionStage
    );
    return stepPositions[valuePosition] ?? 0;
  }, [currentProductionStage, productionStages, stepPositions]);

  useEffect(() => {
    if (productionStages?.length > 0) {
      setStepPositions(calculatePositions(productionStages));
    } else {
      setStepPositions([]);
    }
  }, [productionStages]);

  const sortedProductionStageHistory = useMemo(() => {
    return [...(productionStageHistory || [])].sort(
      (a, b) => new Date(a.updateDate) - new Date(b.updateDate)
    );
  }, [productionStageHistory]);

  return (
    <div className={`${className}`} style={{ height: "65px" }}>
      <h6 className="mid mb-2">{label}</h6>
      <ProgressBar
        percent={percent}
        filledBackground="#28a745"
        stepPositions={stepPositions}
        height={20}
        disabled={disabled}
      >
        {stepPositions?.map((_, index) => {
          let stage = productionStages?.[index];
          return (
            <Step key={index}>
              {({ accomplished }) => (
                <StepCard
                  isStart={index === 0}
                  isEnd={stepPositions?.length - 1 === index}
                  isCurrent={stage?._id === currentProductionStage}
                  stage={stage}
                  initialProductionStage={initialProductionStage}
                  currentProductionStage={currentProductionStage}
                  unit={unit}
                  productionStageHistory={sortedProductionStageHistory}
                  accomplished={accomplished}
                  onProductionStageChange={(change) => {
                    if (!disabled) onProductionStageChange(change);
                  }}
                  updatingHistoryDate={updatingHistoryDate}
                  onUpdateDate={onUpdateDate}
                />
              )}
            </Step>
          );
        })}
      </ProgressBar>
    </div>
  );
};

export default ProductionStageProgress;
