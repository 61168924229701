import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";
import AddEditFilterModal from "./AddEditFilterModal";
import { Eye, GripVertical, Pencil, Plus, Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { filterServices } from "../../../services/filterServices";
import AlertModal from "../../common/AlertModal";
import { filterFields } from "../../../helpers/filterConstants";
import useAppChoices from "../../../hooks/useAppChoices";
import { reorder, updateItemsInArray } from "../../../helpers/global";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CircularProgressBar from "../../common/circular-progress";
import useContactStatus from "../../../hooks/useContactStatus";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const Filter = ({
  filter,
  index,
  onEditFilterClick,
  onDeleteFilterClick,
  disabled,
}) => {
  const { checkNestedPermission } = useAuth();
  return (
    <Draggable
      isDragDisabled={disabled}
      draggableId={filter?._id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`border rounded p-2 d-flex justify-content-between mt-2 hover ${
            snapshot.isDragging ? "bg-primary-light" : ""
          }`}
        >
          <div className="d-flex">
            {!disabled && <GripVertical />}
            <h6 className="smallFont mb-0 mx-1">{filter.name}</h6>
          </div>
          <div className="d-flex">
            {!checkNestedPermission([
              PERMISSIONS.filters.UPDATE_ALL_FILTERS,
            ]) ? (
              <Eye
                className={"text-info"}
                size={13}
                onClick={() => onEditFilterClick(filter)}
              />
            ) : (
              <Pencil
                className={"text-success"}
                size={13}
                onClick={() => !disabled && onEditFilterClick(filter)}
              />
            )}{" "}
            {checkNestedPermission([
              PERMISSIONS.filters.DELETE_ALL_FILTERS,
            ]) && (
              <Trash
                className={`ms-2 text-danger`}
                size={13}
                onClick={() => !disabled && onDeleteFilterClick(filter)}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

const CompoundFilter = ({
  className = "",
  filterData,
  setFilterData,
  tags,
}) => {
  const { checkNestedPermission } = useAuth();
  const { translate } = useLocalization();
  const [addEditFilterMeta, setAddEditFilterMeta] = useState(null);
  const [filterDeleteModalMeta, setFilterDeleteModalMeta] = useState(null);
  const [updatingFilterOrder, setUpdatingFilterOrder] = useState(false);
  const users = useAppChoices("users");
  const groups = useAppChoices("groups");
  const inboundCampaignUrlOptions = useAppChoices("inboundCampaignUrlOptions");
  const { GROUPPED_STATUS } = useContactStatus();
  const fields = useMemo(
    () =>
      filterFields({
        tags,
        users,
        groups,
        translate,
        inboundCampaignUrlOptions,
        GROUPPED_STATUS,
      }),
    [
      tags,
      users,
      groups,
      filterFields,
      inboundCampaignUrlOptions,
      GROUPPED_STATUS,
    ]
  );

  const onFilterSubmit = async (formData) => {
    const { mode } = addEditFilterMeta;

    try {
      setAddEditFilterMeta({ ...addEditFilterMeta, showProgress: true });

      const { response, error } =
        mode === "Add"
          ? await filterServices.createFilter(formData)
          : await filterServices.updateFilter(formData);
      if (response) {
        setFilterData(
          mode === "Add"
            ? [...filterData, response]
            : updateItemsInArray(filterData, [response])
        );
      }
      if (error) {
        toast.error(error);
      }
      setAddEditFilterMeta(null);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFilter = async (formData) => {
    const { filter } = filterDeleteModalMeta;
    setFilterDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    try {
      const { response, error } = await filterServices.deleteFilter(filter);

      setFilterDeleteModalMeta(null);
      if (error) {
        return toast.error(error);
      }

      setFilterData((filters) => filters.filter((p) => p._id !== filter._id));
      toast.success("Successfully deleted filter");
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const oldData = [...filterData];
    const newOrderedData = reorder(
      filterData,
      result.source.index,
      result.destination.index
    );
    setFilterData(newOrderedData);
    try {
      setUpdatingFilterOrder(true);
      const { response, error } = await filterServices.updateMultiple(
        newOrderedData.map((o, index) => ({
          _id: o._id,
          filterUpdates: { index: index },
        }))
      );

      if (error) {
        setFilterData(oldData);
        return toast.error(error);
      }
      if (response) {
        toast.success("Successfully updated filters");
      }
      setUpdatingFilterOrder(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card className={className}>
        <Card.Header className="d-flex justify-content-between">
          {translate("filter")}{" "}
          <div className="d-flex">
            {updatingFilterOrder && (
              <span className="mx-1 text-dark">
                <CircularProgressBar size={12} />
                <span className="mx-1 smallFont">
                  {translate("please_wait")}
                </span>
              </span>
            )}
            {checkNestedPermission([
              PERMISSIONS.filters.CREATE_ALL_FILTERS,
            ]) && (
              <Button
                size="sm"
                className="d-flex align-items-center px-1 py-0"
                variant="success text-white"
                disabled={updatingFilterOrder}
                onClick={() =>
                  setAddEditFilterMeta({
                    initialValues: {
                      name: "",
                      filter: {
                        combinator: "and",
                        rules: [
                          {
                            id: new Date().getTime(),
                            field: "status",
                            operator: "in",
                            value: "New - Unattended",
                          },
                        ],
                      },
                    },
                    mode: "Add",
                  })
                }
              >
                <Plus />
                <span className="smallFont">{translate("add")} </span>
              </Button>
            )}
          </div>
        </Card.Header>
        <Card.Body className="px-2 pt-0 pb-2">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {filterData?.map((filter, index) => {
                    return (
                      <Filter
                        key={filter?._id}
                        index={index}
                        filter={filter}
                        disabled={
                          updatingFilterOrder ||
                          !checkNestedPermission([
                            PERMISSIONS.filters.UPDATE_ALL_FILTERS,
                          ])
                        }
                        onDeleteFilterClick={(filter) =>
                          setFilterDeleteModalMeta({ filter })
                        }
                        onEditFilterClick={(filter) =>
                          setAddEditFilterMeta({
                            initialValues: filter,
                            mode: "Edit",
                          })
                        }
                      />
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Card.Body>
      </Card>
      <AddEditFilterModal
        show={Boolean(addEditFilterMeta)}
        initialValues={addEditFilterMeta?.initialValues}
        fields={fields}
        editMode={addEditFilterMeta?.editMode}
        onHide={() => setAddEditFilterMeta(null)}
        showProgress={addEditFilterMeta?.showProgress}
        onSubmit={onFilterSubmit}
      />
      <AlertModal
        show={Boolean(filterDeleteModalMeta)}
        onHide={() => setFilterDeleteModalMeta(false)}
        onDismissClick={() => setFilterDeleteModalMeta(false)}
        alertText={translate("are_you_sure_you_want_to_delete_filter")}
        showProgress={filterDeleteModalMeta?.showProgress}
        onContinueClick={deleteFilter}
      />
    </>
  );
};

export default CompoundFilter;
