import React from "react";
import { PERMISSIONS } from "../../helpers/permissions";
import useActiveTrailAndFacebookCampaign from "../../hooks/useActiveTrailAndFacebookCampaign";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import Loader from "../common/Loader";
import BrowseAutomations from "./automation/BrowseAutomations";
import BrowseEvents from "./events/BrowseEvents";
import InboundSourceDefaultStatus from "./inbound-source-status/InboundSourceDefaultStatus";

const AutomationAndEvents = () => {
  const { checkNestedPermission } = useAuth();
  const { fetchingActiveTrailAndCampaigns } =
    useActiveTrailAndFacebookCampaign();
  const whatsappTemplates = useAppChoices("whatsappTemplates");

  return fetchingActiveTrailAndCampaigns ? (
    <div className="px-3">
      <Loader />
    </div>
  ) : (
    <div className="pb-5 position-relative">
      {checkNestedPermission([
        PERMISSIONS.automations.VIEW_ALL_AUTOMATIONS,
      ]) && <BrowseAutomations whatsappTemplates={whatsappTemplates} />}
      {checkNestedPermission([PERMISSIONS.events.VIEW_ALL_EVENTS]) && (
        <BrowseEvents whatsappTemplates={whatsappTemplates} />
      )}
      {checkNestedPermission([
        PERMISSIONS.inbound_source_defaults.VIEW_ALL_INBOUND_SOURCE_DEFAULTS,
      ]) && (
        <InboundSourceDefaultStatus whatsappTemplates={whatsappTemplates} />
      )}
    </div>
  );
};

export default AutomationAndEvents;
