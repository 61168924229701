import { CopyOutlined } from "@ant-design/icons";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, FormControl } from "react-bootstrap";
import {
  ArrowsCollapse,
  ArrowsExpand,
  BlockquoteRight,
  BoxArrowInDownRight,
  CalendarDate,
  CardList,
  Chat,
  ChatQuote,
  Check,
  FileEarmarkText,
  FileText,
  Folder,
  ListCheck,
  PatchCheck,
  PersonCheck,
  PersonRaisedHand,
  Telephone,
  Whatsapp,
  XLg,
} from "react-bootstrap-icons";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import GoogleMailIcon from "../../../assets/GoogleMailIcon.png";
import PhoneCallIcon from "../../../assets/PhoneCallIcon.png";
import RujumAppIcon from "../../../assets/RujumAppIcon.png";
import WhatsAppIcon from "../../../assets/WhatsAppIcon.png";
import { useFollowUpDate } from "../../../context/FollowUpDateContext";
import {
  BROADCAST_TYPES,
  useWebSocket,
} from "../../../context/WebSocketContext";
import { ABORT_ERROR } from "../../../helpers/api";
import { isAdminOrManager } from "../../../helpers/session";
import useAuth from "../../../hooks/useAuth";
import useContactAndDealPopup from "../../../hooks/useContactAndDealPopup";
import useLocalization from "../../../hooks/useLocalization";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import useWhatsappConversation from "../../../hooks/useWhatsappConversation";
import { contactService } from "../../../services/contactService";
import { referralService } from "../../../services/referralServices";
import AlertModal from "../../common/AlertModal";
import AppModal from "../../common/AppModal";
import AsyncCreatableSelect from "../../common/AsyncCreatableSelect";
import Comments from "../../common/Comments";
import PersonSelectOption from "../../common/PersonSelectOption";
import PersonSelectValue from "../../common/PersonSelectValue";
import UnderlineButton from "../../common/UnderlineButton";
import CircularProgressBar from "../../common/circular-progress";
import OverViewColumns from "../../common/overview-columns/OverViewColumns";
import MiniBrowseDeals from "../../deals/MiniBrowseDeals";
import Activity from "../activity/Activity";
import Inbounds from "../inbounds/Inbounds";
import PhoneCalls from "../phoneCalls/PhoneCalls";
import SalesPersonWhatsappTemplates from "../whatsapp-campaign-salesperson/SalesPersonWhatsappTemplates";
import AppointmentModal from "./AppointmentModal";
import SurveyField from "./SurveyField";

const DETAILS_SECTION = "details";
const ASSIGNED_SECTION = "assigned_to";
const STATUS_SECTION = "status";
const DESCRIPTION_SECTION = "description";
const COMMENTS_SECTION = "comments";
const ACTIVITY_SECTION = "activity";
const INBONUDS_SECTION = "inbounds";
const WHATSAPP_SECTION = "whatsapp_messages";
const DEALS_SECTION = "deals";
const PHONE_CALL_SECTION = "phone_calls";
const QUESTIONNAIRE_SECTION = "questionnaire";

const SECTION_GROUPS = {
  FIRST: [DETAILS_SECTION, ASSIGNED_SECTION],
  SECOND: [
    STATUS_SECTION,
    DESCRIPTION_SECTION,
    COMMENTS_SECTION,
    ACTIVITY_SECTION,
  ],
  THIRD: [
    INBONUDS_SECTION,
    WHATSAPP_SECTION,
    PHONE_CALL_SECTION,
    DEALS_SECTION,
    QUESTIONNAIRE_SECTION,
  ],
};

const getSectionGroup = (section) => {
  for (const [group, sections] of Object.entries(SECTION_GROUPS)) {
    if (sections.includes(section)) {
      return group;
    }
  }
  return null;
};

const Section = ({
  style,
  className = "",
  bodyClassName = "",
  Icon,
  title,
  children,
  noTranslate = false,
  extraHeaderContent,
  toggleExpandSection,
  expandKey,
  isExpanded,
  hide,
}) => {
  const { translate } = useLocalization();

  return (
    <div
      style={{ ...style, display: hide ? "none" : "flex" }}
      className={`flex-column custom-card border border-light rounded-0 p-0 ${className} `}
    >
      <div
        style={{ minHeight: 30 }}
        className="d-flex justify-content-between align-items-center pb-0 border-bottom bg-gray px-2"
      >
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center gap-2">
            <Icon />
            <h6 className="mid fw-bold mb-0">
              {noTranslate ? title : translate(title)}
            </h6>
          </div>
        </div>{" "}
        {isExpanded ? (
          <ArrowsCollapse
            className="hover"
            onClick={() => toggleExpandSection(expandKey)}
          />
        ) : (
          <ArrowsExpand
            className="hover"
            onClick={() => toggleExpandSection(expandKey)}
          />
        )}
        {extraHeaderContent && extraHeaderContent()}
      </div>
      <div className={`overflow-auto flex-grow-1 px-1 ${bodyClassName}`}>
        {children}
      </div>
    </div>
  );
};

const EditContactDetailsModal = ({
  show,
  onHide,
  contact,
  originalContact,
  deleteComment,
  onNewCommentSubmit,
  onEditingCommentSubmit,
  onUpdateContactClick,
  makePhoneCall,
  onContactChange,
  onChangeLogsClick,
  updatingContact,
  newCommentText,
  setNewCommentText,
  commentsUpdating,
  editingComment,
  setEditingComment,
  deletingComment,
  setDeletingComment,
  editContactDetailsField,
  editContactAssignedField,
  editContactStatusField,
  editable = true,
  loading = false,
  sendToRujum,
  onCreateNewReferralClick,
  referrals = [],
  createdReferral,
  editContactQuestionnaireField,
}) => {
  const { message, clearMessage } = useWebSocket();

  const { mergeContactModalMeta, setMergeContactModalMeta } =
    useContactAndDealPopup();
  const { translate } = useLocalization();
  const { screenWidth } = useScreenWidth();
  const { user, checkNestedPermission } = useAuth();
  const { followupDateChangeInfo } = useFollowUpDate();
  const abortControllerRef = useRef(null);

  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);
  const [duplicateContacts, setDuplicateContacts] = useState([]);

  const [sendingSurveyForm, setSendingSurveyForm] = useState(false);

  const { openWhatsappChatScreen } = useWhatsappConversation();
  const mobileScreen = useMemo(() => screenWidth < 800, [screenWidth]);

  const [expandedSection, setExpandedSection] = useState(null);

  const isContactModified = useMemo(() => {
    delete originalContact?.__v;
    return !_.isEqual(contact, originalContact);
  }, [contact, originalContact]);

  useEffect(() => {
    if (message && Boolean(show)) {
      let { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTACT_UPDATE:
          if (payload?._id === contact?._id) {
            toast.info(
              "This contact was updated by someone. You are viewing latest changes."
            );
            onContactChange({ ...payload });
          }
          break;

        case BROADCAST_TYPES.CONTACT_DELETE:
          if (payload?._id === contact?._id) {
            toast.info("This contact has been deleted by someone else.");
            onHide();
          }
          break;
      }
    }
  }, [message, show]);

  const onSaveClick = async () => {
    onUpdateContactClick();
  };

  const sendSurveyForm = async () => {
    try {
      setSendingSurveyForm(true);
      const { response, error } = await contactService.sendSurveyForm(
        contact?._id
      );
      if (error) {
        toast.error(error);
        return;
      }
      const { surveySent } = response;
      onContactChange({ ...contact, surveySent });
    } catch (error) {
      console.log(error);
    } finally {
      setSendingSurveyForm(false);
    }
  };

  const getDuplicateContacts = async () => {
    const { response, error } = await contactService.getDuplicateContacts({
      contactIds: [contact?._id],
    });
    if (error) {
      return toast.error(error);
    }
    if (response.length) {
      setDuplicateContacts(response[0]?.duplicates);
    }
  };

  const searchReferral = async (query) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      let reqBody = {
        query,
      };

      const controller = new AbortController();
      const { signal } = controller;

      abortControllerRef.current = controller;

      let { response, error } = await referralService.getReferrals(
        reqBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      let { results = [] } = response;
      return results?.map((r) => ({ label: r?.name, value: r?._id, ...r }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (createdReferral) {
      onContactChange({ ...contact, referredBy: createdReferral });
    }
  }, [createdReferral]);

  useEffect(() => {
    if (contact?._id && show) {
      getDuplicateContacts();
    } else {
      setDuplicateContacts([]);
    }
    setExpandedSection(null);
  }, [contact?._id, show]);

  useEffect(() => {
    if (followupDateChangeInfo) {
      const { contact: updatedContact, followupDate } = followupDateChangeInfo;
      if (updatedContact?._id === contact?._id)
        onContactChange({ ...contact, followupDate });
    }
  }, [followupDateChangeInfo]);

  const toggleExpandSection = (expandKey) => {
    setExpandedSection((pre) =>
      pre ? (expandKey === pre ? null : expandKey) : expandKey
    );
  };

  const isSectionExpanded = useCallback(
    (key) => {
      return key == expandedSection;
    },
    [expandedSection]
  );

  const isSectionHidden = useCallback(
    (section) => {
      if (!expandedSection || section === expandedSection) return false;

      const expandedGroup = getSectionGroup(expandedSection);
      const sectionGroup = getSectionGroup(section);

      return expandedGroup && expandedGroup === sectionGroup;
    },
    [expandedSection]
  );

  return (
    <AppModal
      show={show}
      onHide={onHide}
      fullscreen
      hideHeader
      modalBodyClassName="p-0 high-zIndex"
    >
      <div className="d-flex w-100 justify-content-between py-1 px-2 high-zIndex">
        {contact ? (
          <div className="d-flex align-items-center gap-2">
            {[
              {
                label: "change_logs",
                iconVariant: "primary",
                Icon: FileText,
                onClick: () => onChangeLogsClick(),
                disabled: false,
              },
              {
                label: "merge_contacts",
                iconVariant: "purple",
                Icon: CopyOutlined,
                onClick: () =>
                  setMergeContactModalMeta({
                    duplicateContacts: duplicateContacts,
                    baseContact: contact,
                  }),
                disabled: false,
                hide:
                  !duplicateContacts?.length || !isAdminOrManager(user?.role),
              },

              {
                label: "Chat",
                iconVariant: "success",
                Icon: Chat,
                onClick: () => openWhatsappChatScreen(contact),
                disabled: false,
              },
              {
                label: "call",
                iconVariant: "success",
                imgSrc: PhoneCallIcon,
                onClick: () => makePhoneCall(contact.phoneNumbers?.[0]),
                disabled: false,
              },
              {
                label: "whatsapp",
                url: `https://wa.me/+972${contact.phoneNumbers?.[0]}`,
                iconVariant: "success",
                imgSrc: WhatsAppIcon,
              },
              {
                label: "email",
                url: `https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=${contact.emails?.[0]}`,
                iconVariant: "primary",
                imgSrc: GoogleMailIcon,
              },
              {
                label: "set_appointment",
                iconVariant: "info",
                Icon: CalendarDate,
                onClick: () => setAppointmentModalMeta({}),
                disabled: false,
              },
              {
                label: "send_to_rujum",
                iconVariant: "purple",
                imgSrc: RujumAppIcon,
                onClick: () => sendToRujum(contact),
                disabled: false,
              },
            ].map(
              ({
                label,
                url,
                iconVariant,
                Icon,
                imgSrc,
                onClick,
                disabled,
                hide,
              }) =>
                !hide && (
                  <Button
                    className="p-1 d-flex justify-content-between border-light align-items-center"
                    key={`${label}-${url}`}
                    variant={"light-gray"}
                    href={url}
                    target="_blank"
                    onClick={onClick}
                    disabled={disabled}
                  >
                    {imgSrc ? (
                      <img
                        size={13}
                        src={imgSrc}
                        height={20}
                        width={20}
                        alt="icon"
                      />
                    ) : (
                      <Icon className={`text-${iconVariant}`} />
                    )}

                    <span className="smallFont mx-1">
                      {translate(label) || label}{" "}
                    </span>
                  </Button>
                )
            )}
          </div>
        ) : (
          <div />
        )}

        <div className="d-flex align-items-center gap-2">
          <Button
            variant="danger"
            className="text-white"
            size="sm"
            disabled={updatingContact}
            onClick={onHide}
          >
            <span className="py-1">
              {translate("close")} <XLg size={12} />
            </span>
          </Button>
          {editable && !loading && Boolean(contact) && (
            <Button
              variant={
                isContactModified || newCommentText ? "success" : "secondary"
              }
              className="text-white"
              size="sm"
              disabled={
                updatingContact || (!isContactModified && !newCommentText)
              }
              onClick={onSaveClick}
            >
              {updatingContact ? (
                <span className="mx-1 p-1 text-white">
                  <CircularProgressBar size={12} />
                  <span className="mx-1 smallFont">
                    {translate("please_wait")}
                  </span>
                </span>
              ) : (
                <span className="py-1">
                  {translate("save")} <Check size={15} />
                </span>
              )}
            </Button>
          )}
        </div>
      </div>
      {loading || !Boolean(contact) ? (
        <ContentLoader className="p-2 w-50">
          {" "}
          <rect x="0" y="0" rx="4" ry="4" width="100%" height="15px" />
          <rect x="0" y="20" rx="4" ry="4" width="90%" height="15px" />
          <rect x="0" y="40" rx="4" ry="4" width="80%" height="15px" />
        </ContentLoader>
      ) : (
        <>
          {" "}
          {contact && (
            <div
              className="position-relative d-flex flex-wrap bg-secondary"
              style={{
                height: `calc(100vh - 40px)`,
              }}
            >
              <div
                style={{
                  padding: 1,
                  width: "18%",
                }}
                className="d-flex h-100 flex-column "
              >
                {/* details */}
                <Section
                  Icon={BlockquoteRight}
                  style={{
                    height: isSectionExpanded(DETAILS_SECTION) ? "100%" : "75%",
                  }}
                  title={"details"}
                  bodyClassName="bg-light"
                  toggleExpandSection={toggleExpandSection}
                  expandKey={DETAILS_SECTION}
                  isExpanded={isSectionExpanded(DETAILS_SECTION)}
                  hide={isSectionHidden(DETAILS_SECTION)}
                >
                  {" "}
                  <OverViewColumns
                    parentContainerClassName="mid m-0 p-0"
                    inputCellStyle={{ fontSize: 14 }}
                    cellSizeMD={12}
                    data={contact}
                    onDataChange={(newData) => {
                      onContactChange({ ...contact, ...newData });
                    }}
                    fields={editContactDetailsField}
                    isUpdating={updatingContact}
                    alwaysInEditMode={false}
                    editable={editable}
                  />
                </Section>

                {/* Assigned to */}
                <Section
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(ASSIGNED_SECTION)
                      ? "100%"
                      : "25%",
                  }}
                  Icon={PersonCheck}
                  title={"assigned_to"}
                  bodyClassName="bg-light"
                  toggleExpandSection={toggleExpandSection}
                  expandKey={ASSIGNED_SECTION}
                  isExpanded={isSectionExpanded("assigned_to")}
                  hide={isSectionHidden(ASSIGNED_SECTION)}
                >
                  {isAdminOrManager(user?.role) && (
                    <OverViewColumns
                      parentContainerClassName="mid m-0 p-0"
                      cellSizeMD={12}
                      data={{
                        salesperson:
                          contact?.salesperson?.map((s) => s?._id || s) || [],
                        financialStrategist:
                          contact?.financialStrategist?.map(
                            (s) => s?._id || s
                          ) || [],
                        realEstateAnalyst:
                          contact?.realEstateAnalyst?.map((s) => s?._id || s) ||
                          [],
                      }}
                      onDataChange={(newData) => {
                        onContactChange({ ...contact, ...newData });
                      }}
                      fields={editContactAssignedField}
                      isUpdating={updatingContact}
                      alwaysInEditMode={false}
                      editable={editable}
                    />
                  )}

                  <div className="p-1">
                    <h6 className="smallFont">Referral</h6>{" "}
                    <AsyncCreatableSelect
                      CustomSingleValueComponent={PersonSelectValue}
                      CustomOptionComponent={PersonSelectOption}
                      defaultItems={referrals?.map((r) => ({
                        label: r?.name,
                        value: r?._id,
                        ...r,
                      }))}
                      selectedItems={contact?.referredBy}
                      onChange={(v) => {
                        onContactChange({ ...contact, referredBy: v });
                      }}
                      onCreateOption={onCreateNewReferralClick}
                      loadOptions={searchReferral}
                      isClearable
                    />{" "}
                  </div>
                </Section>
              </div>

              <div
                style={{
                  padding: 1,
                  width: "42%",
                }}
                className="d-flex flex-column h-100 "
              >
                {/* status */}
                <Section
                  style={{
                    height: isSectionExpanded("status") ? "100%" : "23%",
                  }}
                  Icon={PatchCheck}
                  title={"status"}
                  bodyClassName="bg-light pt-1"
                  toggleExpandSection={toggleExpandSection}
                  expandKey={STATUS_SECTION}
                  isExpanded={isSectionExpanded("status")}
                  hide={isSectionHidden(STATUS_SECTION)}
                >
                  <OverViewColumns
                    parentContainerClassName="mid m-0 p-0"
                    inputCellStyle={{ fontSize: 14 }}
                    cellSizeMD={12}
                    data={contact}
                    onDataChange={(newData) => {
                      onContactChange({ ...contact, ...newData });
                    }}
                    fields={editContactStatusField}
                    isUpdating={updatingContact}
                    alwaysInEditMode={false}
                    editable={editable}
                  />
                  <div className="px-1 my-1">
                    <h6 className="mid">Survey</h6>
                    <SurveyField
                      contact={contact}
                      sendSurveyForm={sendSurveyForm}
                      sendingSurveyForm={sendingSurveyForm}
                    />
                  </div>
                </Section>

                {/* Description */}
                <Section
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(DESCRIPTION_SECTION)
                      ? "100%"
                      : "13%",
                  }}
                  Icon={CardList}
                  className=" "
                  title={translate("description")}
                  noTranslate
                  bodyClassName="bg-light py-1"
                  toggleExpandSection={toggleExpandSection}
                  expandKey={DESCRIPTION_SECTION}
                  isExpanded={isSectionExpanded(DESCRIPTION_SECTION)}
                  hide={isSectionHidden(DESCRIPTION_SECTION)}
                >
                  <FormControl
                    value={contact?.description}
                    onChange={(e) =>
                      onContactChange({
                        ...contact,
                        description: e.target.value,
                      })
                    }
                    size="sm"
                    placeholder="Type your description here..."
                    as={"textarea"}
                    rows={2}
                  />
                </Section>

                {/* Comments */}
                <Section
                  toggleExpandSection={toggleExpandSection}
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(COMMENTS_SECTION)
                      ? "100%"
                      : "40%",
                  }}
                  Icon={ChatQuote}
                  // className="h-50"
                  title={translate("comments", {
                    count: contact?.comments?.length,
                  })}
                  noTranslate
                  bodyClassName="bg-light"
                  expandKey={COMMENTS_SECTION}
                  isExpanded={isSectionExpanded(COMMENTS_SECTION)}
                  hide={isSectionHidden(COMMENTS_SECTION)}
                >
                  {" "}
                  <div className="overflow-auto flex-grow-1 p-1">
                    <Comments
                      comments={contact.comments}
                      text={newCommentText}
                      onTextChange={setNewCommentText}
                      onCommentSubmit={onNewCommentSubmit}
                      commentsUpdating={commentsUpdating}
                      editable={editable}
                      editingComment={editingComment}
                      onEditingCommentUpdate={(text) =>
                        setEditingComment({ ...editingComment, text })
                      }
                      onCommentEditClick={(comment) =>
                        setEditingComment({ ...comment })
                      }
                      onCommentEditCancelClick={() => setEditingComment(null)}
                      onCommentEditSaveClick={onEditingCommentSubmit}
                      onCommentDeleteClick={(comment) =>
                        setDeletingComment({ ...comment })
                      }
                    />
                  </div>
                </Section>

                {/* Activity */}
                <Section
                  toggleExpandSection={toggleExpandSection}
                  isExpanded={isSectionExpanded(ACTIVITY_SECTION)}
                  expandKey={ACTIVITY_SECTION}
                  hide={isSectionHidden(ACTIVITY_SECTION)}
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(ACTIVITY_SECTION)
                      ? "100%"
                      : "26%",
                  }}
                  Icon={ListCheck}
                  title={"activity"}
                  extraHeaderContent={
                    contact?.driveFolder
                      ? () => {
                          return (
                            <UnderlineButton
                              iconSize={15}
                              fontSize="smallFont"
                              Icon={Folder}
                              text={"Folder"}
                              target={"_blank"}
                              href={`https://drive.google.com/drive/folders/${contact?.driveFolder}`}
                            />
                          );
                        }
                      : null
                  }
                >
                  <Activity contact={contact} />
                </Section>
              </div>
              <div
                style={{
                  padding: 1,
                  width: "40%",
                }}
                className="d-flex flex-column h-100 "
              >
                {/* Inbounds */}
                <Section
                  isExpanded={isSectionExpanded(INBONUDS_SECTION)}
                  toggleExpandSection={toggleExpandSection}
                  expandKey={INBONUDS_SECTION}
                  hide={isSectionHidden(INBONUDS_SECTION)}
                  Icon={BoxArrowInDownRight}
                  title={translate(
                    contact?.inbounds?.length
                      ? "inbounds"
                      : "inbounds_no_count",
                    {
                      count: contact?.inbounds?.length,
                    }
                  )}
                  noTranslate
                  style={{
                    height: isSectionExpanded(INBONUDS_SECTION)
                      ? "100%"
                      : "23%",
                  }}
                >
                  {" "}
                  <Inbounds
                    contact={contact}
                    height={expandedSection === "inbounds" ? "100vh" : "150px"}
                  />
                </Section>

                {/* whatsApp-message */}
                <Section
                  toggleExpandSection={toggleExpandSection}
                  isExpanded={isSectionExpanded(WHATSAPP_SECTION)}
                  expandKey={WHATSAPP_SECTION}
                  hide={isSectionHidden(WHATSAPP_SECTION)}
                  noTranslate
                  Icon={Whatsapp}
                  title={translate("whatsapp_messages")}
                  bodyClassName="d-flex gap-1"
                  style={{
                    height: isSectionExpanded(WHATSAPP_SECTION)
                      ? "100%"
                      : "13%",
                    marginTop: 2,
                  }}
                >
                  <SalesPersonWhatsappTemplates contact={contact} />
                </Section>

                {/* Deals */}
                <Section
                  toggleExpandSection={toggleExpandSection}
                  isExpanded={isSectionExpanded(DEALS_SECTION)}
                  expandKey={DEALS_SECTION}
                  hide={isSectionHidden(DEALS_SECTION)}
                  noTranslate
                  Icon={FileEarmarkText}
                  title={translate(
                    contact?.contracts?.length ? "deals_count" : "deals",
                    {
                      count: contact?.contracts?.length,
                    }
                  )}
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(DEALS_SECTION) ? "100%" : "40%",
                  }}
                >
                  <MiniBrowseDeals
                    contact={contact}
                    editable={editable}
                    onDealsChange={(contracts) => {
                      onContactChange({ ...contact, contracts });
                    }}
                  />
                </Section>

                {/* Phone Calls */}
                <Section
                  toggleExpandSection={toggleExpandSection}
                  expandKey={PHONE_CALL_SECTION}
                  isExpanded={isSectionExpanded(PHONE_CALL_SECTION)}
                  hide={isSectionHidden(PHONE_CALL_SECTION)}
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(PHONE_CALL_SECTION)
                      ? "100%"
                      : "26%",
                  }}
                  title={translate(
                    contact?.phoneCalls?.length
                      ? "phone_calls_count"
                      : "phone_calls",
                    {
                      count: contact?.phoneCalls?.length,
                    }
                  )}
                  Icon={Telephone}
                  noTranslate
                >
                  <PhoneCalls contact={contact} columnToHide={["callType"]} />
                </Section>

                {/* questionnaire */}
                <Section
                  title={"questionnaire"}
                  Icon={PersonRaisedHand}
                  bodyClassName="p-0"
                  style={{
                    marginTop: 2,
                    height: isSectionExpanded(QUESTIONNAIRE_SECTION)
                      ? "100%"
                      : "",
                  }}
                  toggleExpandSection={toggleExpandSection}
                  expandKey={QUESTIONNAIRE_SECTION}
                  isExpanded={isSectionExpanded(QUESTIONNAIRE_SECTION)}
                  hide={isSectionHidden(QUESTIONNAIRE_SECTION)}
                >
                  {isSectionExpanded(QUESTIONNAIRE_SECTION) && (
                    <OverViewColumns
                      parentContainerClassName="mid m-0 p-0"
                      inputCellStyle={{ fontSize: 14 }}
                      cellSizeMD={12}
                      data={contact}
                      onDataChange={(newData) => {
                        onContactChange({ ...contact, ...newData });
                      }}
                      fields={editContactQuestionnaireField}
                      isUpdating={updatingContact}
                      alwaysInEditMode={false}
                      editable={editable}
                    />
                  )}
                </Section>
              </div>
            </div>
          )}
        </>
      )}
      {contact?.comments?.length > 0 && (
        <AlertModal
          show={Boolean(deletingComment)}
          onHide={() => setDeletingComment(null)}
          onContinueClick={deleteComment}
          onDismissClick={() => setDeletingComment(null)}
          alertText={translate("are_you_sure_delete_comment")}
          showProgress={commentsUpdating}
        />
      )}
      <AppointmentModal
        show={Boolean(appointmentModalMeta)}
        onHide={() => setAppointmentModalMeta(null)}
        contact={contact}
        specificContactMode
      />
    </AppModal>
  );
};

export default EditContactDetailsModal;
