import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/Header";
import NotFound from "../components/common/NotFound";
import { ActiveTrailAndFacebookCampaignProvider } from "../context/ActiveTrailAndFacebookCampaignContext";
import { ApointmentCalendarProvider } from "../context/ApointmentCalendarContext";
import { AppChoicesProvider } from "../context/AppChoicesContext";
import { AppointmentProvider } from "../context/AppointmentContext";
import { ContactAndDealPopupProvider } from "../context/ContactAndDealPopupContext";
import { UsersProvider } from "../context/UsersContext";
import { WebSocketProvider } from "../context/WebSocketContext";
import { WhatsappConversationProvider } from "../context/WhatsappConversationContext";
import useAuth from "../hooks/useAuth";
import { FollowUpDateProvider } from "../context/FollowUpDateContext";

const ProtectedRouteLayout = ({ headerVisible = true, roles, permissions }) => {
  const { user, checkNestedPermission } = useAuth();

  if (
    (roles?.length > 0 && !roles.includes(user?.role)) ||
    !checkNestedPermission(permissions)
  ) {
    return (
      <>
        {headerVisible && <Header />}
        <NotFound />
      </>
    );
  }

  return (
    <>
      {headerVisible && <Header />}
      <AppChoicesProvider>
        <AppointmentProvider>
          <WebSocketProvider>
            <UsersProvider>
              <FollowUpDateProvider>
                <WhatsappConversationProvider>
                  <ApointmentCalendarProvider>
                    <ContactAndDealPopupProvider>
                      <ActiveTrailAndFacebookCampaignProvider>
                        <Outlet />
                      </ActiveTrailAndFacebookCampaignProvider>
                    </ContactAndDealPopupProvider>
                  </ApointmentCalendarProvider>
                </WhatsappConversationProvider>
              </FollowUpDateProvider>
            </UsersProvider>
          </WebSocketProvider>
        </AppointmentProvider>
      </AppChoicesProvider>
    </>
  );
};

export default ProtectedRouteLayout;
