import React, { createContext, useContext, useState } from "react";

export const FollowUpDateContext = createContext();

export const FollowUpDateProvider = ({ children }) => {
  const [followupDateChangeInfo, setFollowupDateChangeInfo] = useState(null); // tracks follow up date change from apointemnt calendar

  const contextObj = {
    followupDateChangeInfo,
    setFollowupDateChangeInfo,
  };

  return (
    <FollowUpDateContext.Provider value={contextObj}>
      {children}
    </FollowUpDateContext.Provider>
  );
};

export const useFollowUpDate = () => {
  const { followupDateChangeInfo, setFollowupDateChangeInfo } =
    useContext(FollowUpDateContext);

  return { followupDateChangeInfo, setFollowupDateChangeInfo };
};
