import React, { useMemo, useState } from "react";
import { Badge, Form, ListGroup } from "react-bootstrap";

const statusColors = {
  Draft: "secondary",
  "Pending Approval": "info",
  Approved: "success",
  Rejected: "danger",
};

const TemplatesList = ({
  searchQuery,
  setSearchQuery,
  templates = [],
  activeTemplate,
  onTemplateSelect,
  translate,
}) => {
  return (
    <div className="">
      <Form.Control
        type="text"
        placeholder={translate("quick_search")}
        className="form-control form-control-sm mb-1"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        size="sm"
      />
      <ListGroup>
        {templates.map((item, index) => (
          <ListGroup.Item
            style={{ fontSize: 14 }}
            className={`py-1 `}
            action
            active={item._id === activeTemplate?._id}
            onClick={() => onTemplateSelect && onTemplateSelect(item)}
            key={item["_id"]}
          >
            <h6
              className={`smallFont fw-bold text-${
                item._id === activeTemplate?._id ? "white" : "dark"
              } mb-0`}
            >
              {item.templateName}
            </h6>
            <Badge
              bg={statusColors[item.status]}
              className="smallest"
              style={{ fontSize: 10 }}
            >
              {item.status}
            </Badge>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default TemplatesList;
