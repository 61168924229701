import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, FormCheck } from "react-bootstrap";
import {
  ArrowLeftCircleFill,
  ArrowRightCircleFill,
  ExclamationTriangle,
  GripVertical,
  Pencil,
  PencilFill,
  Plus,
  Trash,
  X,
  XCircle,
} from "react-bootstrap-icons";
import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import { SALES_ROLE } from "../../helpers/constants";
import { filterFields } from "../../helpers/filterConstants";
import { updateItemsInArray } from "../../helpers/global";
import { isAdminOrManager } from "../../helpers/session";
import useAppChoices from "../../hooks/useAppChoices";
import useAuth from "../../hooks/useAuth";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import useLocalization from "../../hooks/useLocalization";
import CircularProgressBar from "../common/circular-progress";
import AddEditFilterForm from "../forms/AddEditFilterForm";
import UpdateMultipleContactCard from "./contact-details/UpdateMultipleContactCard";
import { PERMISSIONS } from "../../helpers/permissions";
import useContactStatus from "../../hooks/useContactStatus";

export const defaultCustomFilterData = {
  name: "",
  filter: {
    combinator: "and",
    rules: [
      {
        id: new Date().getTime(),
        field: "status",
        operator: "in",
        value: "New - Unattended",
      },
    ],
  },
  filterParsed: '{"status":{"$in":["New - Unattended"]}}',
};
const FilterCard = ({
  isSelected,
  isLocked,
  disabled,
  filterName,
  filter,
  isEditing,
  customFilterMeta,
  onInternalFilterSelect,
  onFilterLock,
  onEditFilterClick,
  onDeleteFilterClick,
}) => {
  const { user } = useAuth();
  const role = useMemo(() => user.role, [user]);
  const { isRTL } = useLocalization();
  return (
    <div
      style={{
        minHeight: "30px",
        margin: 2,
      }}
      className={`border p-1 d-flex align-items-center hover ${
        isSelected || isLocked ? "bg-dark text-white" : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          if (Boolean(customFilterMeta) && onEditFilterClick) {
            onEditFilterClick(filter);
          }
          onInternalFilterSelect(
            filter,
            isLocked ? "unlock" : isSelected ? "unselect" : "select"
          );
        }
      }}
    >
      {!disabled && filter !== null && isEditing && <GripVertical />}
      {!isEditing && Boolean(filter) && !isRTL && (
        <FormCheck
          size={"sm"}
          style={{ fontSize: 12 }}
          onClick={(e) => {
            e.stopPropagation();
            onFilterLock(filter, e.target.checked ? "select" : "remove");
          }}
          checked={isLocked}
        />
      )}
      <h6
        className={`fw-bold flex-grow-1 mid mx-1 truncate mb-0 ${
          disabled && "text-secondary"
        }`}
      >
        {filterName}
      </h6>
      {!isEditing && Boolean(filter) && isRTL && (
        <FormCheck
          size={"sm"}
          style={{ fontSize: 12 }}
          onClick={(e) => {
            e.stopPropagation();
            onFilterLock(filter, e.target.checked ? "select" : "remove");
          }}
          checked={isLocked}
        />
      )}
      <div className="d-flex">
        {onEditFilterClick && isEditing && isAdminOrManager(user?.role) && (
          <Pencil
            className={`mx-1 ${disabled && "text-secondary"}`}
            size={12}
            onClick={(e) => {
              e.stopPropagation();
              !disabled && onEditFilterClick(filter);
            }}
          />
        )}
        {onDeleteFilterClick && isEditing && isAdminOrManager(user?.role) && (
          <Trash
            className={`mx-1 ${disabled && "text-secondary"}`}
            size={12}
            onClick={(e) => {
              e.stopPropagation();
              !disabled && onDeleteFilterClick(filter);
            }}
          />
        )}
      </div>
    </div>
  );
};

const FilterSideBar = ({
  show,
  onHide,
  disabled,
  filterData = [],
  activeFilter,
  updatingFilterOrder,
  onFilterSelect,
  onCustomFilterSubmit,
  onCustomFilterApply,
  customFilterMeta,
  setCustomFilterMeta,
  setFilterDeleteModalMeta,
  allTags,
  onDragEnd,
  setIsEditing,
  isEditing,
  height,
  width,
  onUpdateManyContactSubmit,
  editMultipleContactMeta,
}) => {
  const { translate, isRTL } = useLocalization();
  const users = useAppChoices("users");
  const groups = useAppChoices("groups");
  const inboundCampaignUrlOptions = useAppChoices("inboundCampaignUrlOptions");
  const [lockedFilters, setLockedFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const { user, checkNestedPermission } = useAuth();
  const { GROUPPED_STATUS } = useContactStatus();

  const fields = useMemo(
    () =>
      filterFields({
        tags: allTags,
        users,
        groups,
        translate,
        inboundCampaignUrlOptions,
        GROUPPED_STATUS,
      }),
    [
      allTags,
      users,
      groups,
      filterFields,
      inboundCampaignUrlOptions,
      GROUPPED_STATUS,
    ]
  );

  const onFilterLock = (filter, action) => {
    if (action === "remove") {
      return setLockedFilters(
        lockedFilters?.filter((f) => f?._id !== filter?._id)
      );
    }
    if (action === "select") {
      return setLockedFilters([...lockedFilters, filter]);
    }
    if (filter === null) {
      return setLockedFilters([]);
    }
  };

  const onInternalFilterSelect = (filter, action) => {
    if (filter === null) {
      setSelectedFilter(null);
      setLockedFilters([]);
    } else if (action === "unlock") {
      setSelectedFilter(filter);
      setLockedFilters(lockedFilters?.filter((f) => f?._id !== filter?._id));
    } else if (action === "unselect") {
      setSelectedFilter(null);
    } else if (action === "select") {
      setSelectedFilter(filter);
    } else {
      setSelectedFilter(null);
    }
  };

  useDebouncedEffect(
    () => {
      let filters = [];
      if (Boolean(selectedFilter)) {
        filters = updateItemsInArray(lockedFilters, selectedFilter, "_id");
      } else {
        filters = lockedFilters;
      }
      onFilterSelect(filters);
    },
    [lockedFilters],
    800
  );

  useEffect(() => {
    let filters = [];
    if (Boolean(selectedFilter)) {
      filters = updateItemsInArray(lockedFilters, selectedFilter, "_id");
    }
    onFilterSelect(filters);
  }, [selectedFilter]);

  return (
    show && (
      <div
        style={{ minWidth: width, height }}
        className={`p-2 border bg-white overflow-auto`}
      >
        <div className="d-flex justify-content-end">
          {isRTL ? (
            <ArrowRightCircleFill
              size={18}
              onClick={onHide}
              className="hover"
            />
          ) : (
            <ArrowLeftCircleFill size={18} onClick={onHide} className="hover" />
          )}
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <h6 className="smallFont fw-bold mb-0">
            {updatingFilterOrder && (
              <CircularProgressBar className="mx-1" size={12} />
            )}
            {translate(
              updatingFilterOrder ? "updating_filter" : "saved_filters"
            )}
          </h6>
        </div>
        <hr className="my-1" />
        <div className="d-flex">
          <div
            style={
              customFilterMeta
                ? { minWidth: 200, maxWidth: 200 }
                : { width: customFilterMeta ? "250px" : "100%" }
            }
          >
            <div className="">
              <GridContextProvider onChange={onDragEnd}>
                <GridDropZone
                  id="items"
                  boxesPerRow={1}
                  rowHeight={35}
                  disableDrag={disabled || !isEditing}
                  style={{
                    height: filterData.length * 35 + 40,
                  }}
                >
                  <GridItem>
                    <FilterCard
                      filter={null}
                      isEditing={isEditing}
                      customFilterMeta={customFilterMeta}
                      disabled={disabled}
                      filterName={translate("all")}
                      isSelected={activeFilter?.length === 0}
                      isLocked={false}
                      onInternalFilterSelect={onInternalFilterSelect}
                      onFilterLock={onFilterLock}
                    />
                  </GridItem>
                  {filterData.map((filter) => (
                    <GridItem key={filter?._id}>
                      <FilterCard
                        filter={filter}
                        filterName={filter.name}
                        customFilterMeta={customFilterMeta}
                        disabled={disabled}
                        isEditing={isEditing}
                        isSelected={selectedFilter?._id === filter?._id}
                        isLocked={lockedFilters?.some(
                          (f) => f?._id === filter?._id
                        )}
                        onInternalFilterSelect={onInternalFilterSelect}
                        onFilterLock={onFilterLock}
                        onEditFilterClick={(filter) =>
                          setCustomFilterMeta({
                            initialValues: filter,
                            mode: "Edit",
                          })
                        }
                        onDeleteFilterClick={(filter) =>
                          setFilterDeleteModalMeta({ filter })
                        }
                      />
                    </GridItem>
                  ))}
                </GridDropZone>
              </GridContextProvider>
            </div>

            <div className="d-flex">
              {checkNestedPermission([
                PERMISSIONS.filters.UPDATE_ALL_FILTERS,
              ]) && (
                <Button
                  size="sm"
                  style={{ fontSize: "10px" }}
                  className={`text-white px-1 py-0 d-flex align-items-center rounded-2 ${
                    !isRTL ? "me-auto" : "ms-auto"
                  }`}
                  variant={!isEditing ? "success" : "danger"}
                  disabled={disabled}
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {isEditing ? (
                    <X size={12} className="mx-1" />
                  ) : (
                    <PencilFill size={8} className="mx-1" />
                  )}
                  <span className="smallFont">
                    {translate(!isEditing ? "edit" : "cancel")}
                  </span>
                </Button>
              )}

              {!customFilterMeta &&
                checkNestedPermission([
                  PERMISSIONS.filters.CREATE_ALL_FILTERS,
                ]) && (
                  <Button
                    className="text-white px-1 py-0 d-flex align-items-center"
                    variant={customFilterMeta ? "danger" : "primary"}
                    style={{ fontSize: "10px" }}
                    disabled={disabled}
                    onClick={() =>
                      customFilterMeta
                        ? setCustomFilterMeta(null)
                        : setCustomFilterMeta({
                            mode: "Custom",
                            initialValues: defaultCustomFilterData,
                          })
                    }
                  >
                    {<Plus />}
                    <span className="smallFont">{translate("new")}</span>
                  </Button>
                )}
            </div>
          </div>

          {customFilterMeta && (
            <div className="flex-grow-1 p-2 px-3">
              <div className="d-flex justify-content-between align-items-center mb-2 ">
                <h6 className="smallFont fw-bold mb-0">
                  {translate(
                    customFilterMeta.mode !== "Edit"
                      ? "custom_filters"
                      : "editing_filter",
                    { filterName: customFilterMeta?.initialValues?.name }
                  )}
                </h6>
                <div className="d-flex">
                  <Button
                    className="text-white px-1 py-0 mx-1 d-flex align-items-center"
                    variant={"danger"}
                    style={{ fontSize: "10px" }}
                    disabled={disabled}
                    onClick={() => setCustomFilterMeta(null)}
                  >
                    {<XCircle />}
                    <span className="smallFont mx-1">
                      {translate("close_filters", {
                        mode: customFilterMeta?.mode,
                      })}
                    </span>
                  </Button>
                </div>
              </div>
              {Boolean(customFilterMeta) && (
                <AddEditFilterForm
                  fields={fields}
                  disabled={disabled}
                  initialValues={customFilterMeta?.initialValues}
                  showProgress={customFilterMeta?.showProgress}
                  onSubmit={onCustomFilterSubmit}
                  showSaveAndApplyButton={isAdminOrManager(user?.role)}
                />
              )}
            </div>
          )}
        </div>
        {(isAdminOrManager(user?.role) || user?.role === SALES_ROLE) && (
          <>
            <hr className="my-1" />
            <h6 className="smallFont fw-bold mt-2">
              {translate("edit_multiple_contacts_field")}
            </h6>
            {user?.specialRoles?.crm?.edit?.toLowerCase() === "assigned" ||
              (checkNestedPermission(
                PERMISSIONS.contacts.UPDATE_ASSIGNED_CONTACTS
              ) && (
                <Alert variant="warning" className="p-1">
                  <h6 className="smallFont text-muted mb-0">
                    <ExclamationTriangle />
                    <span className="mx-1">
                      You can only update contacts which are assigned to you.
                      All of the unassinged contacts would be ignored.
                    </span>
                  </h6>
                </Alert>
              ))}
            <UpdateMultipleContactCard
              updatingField={editMultipleContactMeta?.updatingField}
              onSaveFieldValue={(contactUpdates) =>
                onUpdateManyContactSubmit(contactUpdates)
              }
            />
          </>
        )}
      </div>
    )
  );
};

export default FilterSideBar;
