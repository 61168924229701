import React, { useEffect, useMemo, useState } from "react";
import { FormCheck, FormControl } from "react-bootstrap";
import useLocalization from "../../../hooks/useLocalization";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import moment from "moment";

const REPEAT_EVERY_OPTIONS = [
  { label: "day", value: "daily" },
  { label: "week", value: "weekly" },
  { label: "month", value: "monthly" },
];

const DAYS_OF_WEEK = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];

const generateLabelForMonthlyRepeatOn = (date, option) => {
  const dayOfMonth = moment(date).date(); // 17
  const dayOfWeek = moment(date).format("dddd"); // 'Tuesday'
  const occurrence = Math.ceil(moment(date).date() / 7); // Determine if it's the 1st, 2nd, 3rd, or 4th Tuesday, etc.

  if (option === "dayOfMonth") {
    return `Monthly on day ${dayOfMonth}`;
  } else if (option === "dayOfWeek") {
    const ordinalMap = ["first", "second", "third", "fourth", "fifth"];
    return `Monthly on the ${ordinalMap[occurrence - 1]} ${dayOfWeek}`;
  }
};

const errorField = (errors, fieldName) => {
  return errors[fieldName] ? (
    <h6 className="mb-0 tiny text-danger">{errors[fieldName]}</h6>
  ) : null;
};

const CustomRecurranceField = ({ date, errors, recurrenceInfo, onChange }) => {
  const repeatOptions = useMemo(
    () =>
      REPEAT_EVERY_OPTIONS.map(({ label, value }) => ({
        label: `${label}${recurrenceInfo?.interval > 1 ? `s` : ""}`,
        value: value,
      })),
    [recurrenceInfo]
  );

  const repeatOnOptionsForMonth = useMemo(() => {
    const momentDate = moment(date);
    return ["dayOfMonth", "dayOfWeek"].map((o) => ({
      label: generateLabelForMonthlyRepeatOn(momentDate, o),
      value: o,
    }));
  }, [date]);

  const handleFrequencyChange = (selected) => {
    let updatedRecurrenceInfo = { ...recurrenceInfo, frequency: selected };

    // Clear properties not related to the selected frequency
    if (selected === "daily") {
      const occurrences = 30;
      const endDate = moment(date).add(occurrences, "day").format("YYYY-MM-DD");
      updatedRecurrenceInfo = {
        ...updatedRecurrenceInfo,
        endDate,
        occurrences,
      };
      delete updatedRecurrenceInfo.daysOfWeek;
      delete updatedRecurrenceInfo.repeatOn;
    } else if (selected === "weekly") {
      const occurrences = 13;
      const endDate = moment(date)
        .add(occurrences, "week")
        .format("YYYY-MM-DD");
      updatedRecurrenceInfo = {
        ...updatedRecurrenceInfo,
        endDate,
        occurrences,
        daysOfWeek: [moment(date).format("dd").toUpperCase()],
      };
      delete updatedRecurrenceInfo.repeatOn;
    } else if (selected === "monthly") {
      const occurrences = 12;
      const endDate = moment(date)
        .add(occurrences, "month")
        .format("YYYY-MM-DD");
      updatedRecurrenceInfo = {
        ...updatedRecurrenceInfo,
        endDate,
        occurrences,
        repeatOn: "dayOfMonth",
      };
      delete updatedRecurrenceInfo.daysOfWeek;
    }

    onChange(updatedRecurrenceInfo);
  };

  return (
    <div className="d-flex flex-column gap-3">
      {/* Repeat every section */}
      <div className="d-flex gap-4 flex-wrap align-items-center">
        <h6 className="smallFont fw-bold mb-0">Repeat every</h6>
        <FormControl
          type="number"
          size="sm"
          style={{ width: 80 }}
          value={recurrenceInfo?.interval || 1}
          onChange={(e) => {
            let interval = e.target.value;
            if (interval <= 0) return;
            onChange({
              ...recurrenceInfo,
              interval: parseInt(interval, 10),
            });
          }}
        />
        <CustomMultiSelect
          items={repeatOptions}
          selectedItems={recurrenceInfo?.frequency}
          isMulti={false}
          height="28px"
          onChange={handleFrequencyChange}
        />
        <div className="d-flex flex-column gap-2">
          {errorField(errors, "interval")} {errorField(errors, "frequency")}
        </div>
      </div>

      {/* Weekly recurrence - choose days */}
      {recurrenceInfo?.frequency === "weekly" && (
        <div className="d-flex flex-column gap-2">
          <h6 className="smallFont fw-bold mb-0">Repeat on</h6>
          <div className="d-flex align-items-center gap-2">
            {DAYS_OF_WEEK.map((day) => (
              <div
                key={day}
                className={`smallFont hover  d-flex align-items-center justify-content-center ${
                  recurrenceInfo?.daysOfWeek?.includes(day)
                    ? "bg-dark text-white"
                    : "bg-gray text-dark"
                }`}
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                  cursor: "pointer",
                }}
                onClick={() =>
                  onChange({
                    ...recurrenceInfo,
                    daysOfWeek: recurrenceInfo?.daysOfWeek?.includes(day)
                      ? recurrenceInfo.daysOfWeek.filter((d) => d !== day)
                      : [...(recurrenceInfo?.daysOfWeek || []), day],
                  })
                }
              >
                {day[0]}
              </div>
            ))}
          </div>{" "}
          {errorField(errors, "daysOfWeek")}
        </div>
      )}

      {/* Monthly recurrence - repeat on day of month or day of week */}
      {recurrenceInfo?.frequency === "monthly" && (
        <div className="d-flex flex-column gap-2 w-50">
          <h6 className="smallFont fw-bold mb-0">Repeat on</h6>
          <CustomMultiSelect
            isMulti={false}
            items={repeatOnOptionsForMonth}
            selectedItems={recurrenceInfo.repeatOn}
            onChange={(repeatOn) => onChange({ ...recurrenceInfo, repeatOn })}
            closeMenuOnSelect
            placeholder="Select one..."
          />
          {errorField(errors, "repeatOn")}
        </div>
      )}

      {/* Ends section */}
      <div className="d-flex flex-column gap-2">
        <h6 className="smallFont fw-bold mb-0">Ends</h6>
        <div className="d-flex align-items-center">
          <FormCheck
            className="hover w-25"
            type="radio"
            id="end-type-end-date"
            checked={recurrenceInfo.selectedEndsOnType === "endDate"}
            onChange={() =>
              onChange({
                ...recurrenceInfo,
                selectedEndsOnType: "endDate",
              })
            }
            label={
              <h6 id="end-type-end-date" className="smallFont hover mb-0">
                On
              </h6>
            }
          />
          <FormControl
            className="w-50"
            type="date"
            size="sm"
            value={recurrenceInfo?.endDate || ""}
            onChange={(e) =>
              onChange({ ...recurrenceInfo, endDate: e.target.value })
            }
          />
        </div>
        <div className="d-flex align-items-center">
          <FormCheck
            className="hover w-25"
            type="radio"
            id="end-type-occurrences"
            checked={recurrenceInfo.selectedEndsOnType === "occurrences"}
            onChange={() =>
              onChange({
                ...recurrenceInfo,
                selectedEndsOnType: "occurrences",
              })
            }
            label={
              <h6 id="end-type-occurrences" className="smallFont hover mb-0">
                After
              </h6>
            }
          />
          <FormControl
            style={{ width: 80 }}
            type="number"
            size="sm"
            value={recurrenceInfo?.occurrences || ""}
            onChange={(e) => {
              let occurrences = e.target.value;
              if (occurrences <= 0) return;
              onChange({
                ...recurrenceInfo,
                occurrences: parseInt(occurrences, 10),
              });
            }}
          />
          <h6 className="mb-0 smallFont mx-2">
            Occurrence{recurrenceInfo?.occurrences > 1 ? "s" : ""}
          </h6>
        </div>{" "}
        <div className="d-flex align-items-center">
          <FormCheck
            className="hover w-25"
            type="radio"
            id="end-type-end-date"
            checked={recurrenceInfo.selectedEndsOnType === "never"}
            onChange={() =>
              onChange({
                ...recurrenceInfo,
                selectedEndsOnType: "never",
              })
            }
            label={
              <h6 id="end-type-end-date" className="smallFont hover mb-0">
                Never
              </h6>
            }
          />
        </div>
        {errorField(errors, "endDate")} {errorField(errors, "occurrences")}
      </div>
    </div>
  );
};

const ApointmentRecurranceField = ({
  date,
  errors,
  recurrenceInfo = null,
  onChange,
  translate,
}) => {
  const isCustomRecurrance = useMemo(
    () => typeof recurrenceInfo === "object" && recurrenceInfo !== null,
    [recurrenceInfo]
  );

  const DEFAULT_REPEAT_OPTIONS = useMemo(() => {
    const occurrences = 30;
    const endDate = moment(date).add(occurrences, "day").format("YYYY-MM-DD");
    return {
      frequency: "daily",
      interval: 1,
      occurrences,
      endDate,
      selectedEndsOnType: "endDate",
    };
  }, [date]);

  const handleOptionChange = (option) => {
    if (option === "doNotRepeat") {
      onChange(null);
    } else if (option === "custom") {
      onChange(DEFAULT_REPEAT_OPTIONS);
    }
  };

  return (
    <div>
      {/* Repeat options */}
      <div className="d-flex align-items-center gap-2">
        <FormCheck
          className="hover"
          type="radio"
          name="repeat"
          id="appointment-do-not-repeat"
          checked={recurrenceInfo === null}
          onChange={() => handleOptionChange("doNotRepeat")}
        />
        <h6
          className="smallFont mb-0 hover"
          onClick={() => handleOptionChange("doNotRepeat")}
        >
          {translate("do_not_repeat")}
        </h6>
        <FormCheck
          className="hover"
          type="radio"
          name="repeat"
          id="appointment-custom-recurrance"
          checked={isCustomRecurrance}
          onChange={() => handleOptionChange("custom")}
        />
        <h6
          className="smallFont mb-0 hover"
          onClick={() => handleOptionChange("custom")}
        >
          {translate("repeat")}
        </h6>
      </div>

      {/* Custom recurrence form */}
      {isCustomRecurrance && (
        <div className="border rounded mt-2 p-2">
          <CustomRecurranceField
            date={date}
            errors={errors}
            recurrenceInfo={recurrenceInfo}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default ApointmentRecurranceField;
