import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AUTOMATION_CAMPAIGN_TRIGGER_OPTIONS,
  CAMPAIGN_TRIGGER_OPTIONS,
} from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import AppTable from "../../common/app-table/AppTable";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import WhatsappTemplateDropdownOption from "../../common/WhatsappTemplateDropdownOption";
import { DEFAULT_DATE_TIME_FORMAT_NON_MOMENT } from "../../../helpers/constants";
import useAuth from "../../../hooks/useAuth";
import { PERMISSIONS } from "../../../helpers/permissions";

const WhatsappTemplateTable = ({
  showDatetimeTrigger,
  whatsappTemplates,
  values = [],
  errors = [],
  onChange,
  triggerOptions = ["on_signup", "date_time"],
}) => {
  const { translate, isRTL } = useLocalization();
  const { checkNestedPermission } = useAuth();

  const canViewWhatsappCampaigns = useMemo(
    () =>
      checkNestedPermission([
        PERMISSIONS.whatsapp_campaigns.VIEW_ALL_CAMPAIGNS,
      ]),
    []
  );

  const canEditWhatsappCampaigns = useMemo(
    () =>
      checkNestedPermission([
        PERMISSIONS.whatsapp_campaigns.CREATE_ALL_CAMPAIGNS,
        PERMISSIONS.whatsapp_campaigns.DELETE_ALL_CAMPAIGNS,
        PERMISSIONS.whatsapp_campaigns.UPDATE_ALL_CAMPAIGNS,
      ]),
    []
  );

  const newTemplate = useMemo(
    () => ({
      template: whatsappTemplates?.[0]?._id,
      trigger: triggerOptions[0],
    }),
    [whatsappTemplates]
  );

  const handleTemplateChange = (index, field, value) => {
    const updatedValues = values.map((item, i) =>
      i === index
        ? {
            ...item,
            [field]: value,
            ...(field === "trigger" && value === "date_time"
              ? { dateTime: new Date() }
              : {}),
          }
        : item
    );
    onChange(updatedValues);
  };

  const handleRemoveTemplate = (index) => {
    const updatedValues = values.filter((_, i) => i !== index);
    onChange(updatedValues);
  };

  if (!canViewWhatsappCampaigns) {
    return (
      <h6 className="text-center large p-2 border">
        You are not allowed to view/update whatsapp campaigns
      </h6>
    );
  }

  return (
    <div>
      <AppTable striped>
        <thead>
          <tr>
            <th className="bg-dark text-center text-white">
              {translate("message_template")}
            </th>
            <th
              style={{ width: 200 }}
              className="bg-dark text-center text-white"
            >
              {translate("trigger")}
            </th>
            {showDatetimeTrigger && (
              <th
                style={{ width: 200 }}
                className="bg-dark text-center text-white"
              >
                {translate("date_time")}
              </th>
            )}
            {canEditWhatsappCampaigns && (
              <th
                style={{ width: 50 }}
                className="bg-dark text-center text-white"
              >
                {translate("action")}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {values.length ? (
            values.map((template, index) => {
              const scheduledCampaign =
                template?.trigger === CAMPAIGN_TRIGGER_OPTIONS.DATE_TIME;

              const scheduledInFuture = scheduledCampaign
                ? new Date(template.dateTime).getTime() > Date.now()
                : false;

              const editable =
                !template?._id ||
                (checkNestedPermission([
                  PERMISSIONS.whatsapp_campaigns.UPDATE_ALL_CAMPAIGNS,
                ]) &&
                  (template?.trigger === "on_signup" || scheduledInFuture));

              return (
                <tr key={index}>
                  <td align="center" className="p-0">
                    <CustomMultiSelect
                      disabled={!editable}
                      items={whatsappTemplates.map((t) => ({
                        label: t.templateName,
                        value: t._id,
                        messageBody: t?.messageBody,
                      }))}
                      selectedItems={template["template"]}
                      onChange={(v) =>
                        handleTemplateChange(index, "template", v)
                      }
                      isMulti={false}
                      closeMenuOnSelect
                      CustomSingleValueComponent={(props) => (
                        <WhatsappTemplateDropdownOption
                          {...props}
                          whatsappTemplates={whatsappTemplates}
                        />
                      )}
                      CustomOption={(props) => (
                        <WhatsappTemplateDropdownOption
                          {...props}
                          isOption
                          whatsappTemplates={whatsappTemplates}
                        />
                      )}
                    />
                    {errors[index]?.template && (
                      <h6 className="mb-0 tiny text-danger">
                        {errors[index].template}
                      </h6>
                    )}
                  </td>
                  <td align="center" className="p-0">
                    {showDatetimeTrigger ? (
                      <CustomMultiSelect
                        disabled={!editable}
                        items={triggerOptions.map((k) => ({
                          label: translate(k),
                          value: k,
                        }))}
                        isMulti={false}
                        selectedItems={template["trigger"]}
                        onChange={(v) =>
                          handleTemplateChange(index, "trigger", v)
                        }
                        closeMenuOnSelect
                      />
                    ) : (
                      <h6 className="smallFont mb-0 mt-2">
                        {template["trigger"]
                          ? translate(template["trigger"])
                          : "-"}
                      </h6>
                    )}
                  </td>
                  {showDatetimeTrigger && (
                    <td align="center" className="p-0">
                      {template["trigger"] === "date_time" ? (
                        <DatePicker
                          wrapperClassName="w-100"
                          className={`${
                            isRTL ? "px-4" : ""
                          }   mb-0 text-dark px-2 py-1 form-control form-control-sm`}
                          selected={
                            template["dateTime"]
                              ? new Date(template["dateTime"])
                              : new Date()
                          }
                          dateFormat={DEFAULT_DATE_TIME_FORMAT_NON_MOMENT}
                          onChange={(e) =>
                            handleTemplateChange(
                              index,
                              "dateTime",
                              e.toISOString()
                            )
                          }
                          openToDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          showTimeInput
                          dropdownMode="scroll"
                          timeFormat="HH:mm"
                          popperClassName="datepickerPopperClassName"
                          clearButtonClassName="text-dark"
                          portalId="root"
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                  {(!template._id ||
                    checkNestedPermission([
                      PERMISSIONS.whatsapp_campaigns.DELETE_ALL_CAMPAIGNS,
                    ])) && (
                    <td align="center" className="p-0">
                      {editable && (
                        <Button
                          size="sm"
                          variant="outline-danger"
                          className="px-1 py-0 mt-1"
                          onClick={() => handleRemoveTemplate(index)}
                        >
                          <Trash />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={showDatetimeTrigger ? 4 : 3} align="center">
                {translate("nothing_to_show")}
              </td>
            </tr>
          )}
          {checkNestedPermission([
            PERMISSIONS.whatsapp_campaigns.CREATE_ALL_CAMPAIGNS,
          ]) && (
            <tr>
              <td colSpan={showDatetimeTrigger ? 4 : 3} align="right">
                <Button
                  size="sm"
                  variant="primary"
                  className="d-flex align-items-center gap-1 text-white"
                  onClick={() => onChange([...values, newTemplate])}
                >
                  <Plus />{" "}
                  <h6 className="mb-0 smallFont">{translate("new")}</h6>
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
    </div>
  );
};

export default WhatsappTemplateTable;
