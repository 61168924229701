import { defaultOperators, toFullOption } from "react-querybuilder";
import {
  ALL_ROLES,
  FINANCIAL_STRATEGIST_ROLE,
  INBOUND_SOURCE,
  REAL_ESTATE_ANALYST_ROLE,
  SALES_ROLE,
} from "./constants";
import { snakeCase } from "lodash";
import { GROUPPED_USER_BASED_ON_ROLE } from "./global";

const inboundSourceOptions = (translate) =>
  INBOUND_SOURCE.map((source) => ({
    value: source,
    label: translate(snakeCase(source)) || source,
  }));

export const validator = (r) => !!r.value;

const personOperators = (op) =>
  op.name === "in" || op.name === "notIn" || op.name === "null";

const operators = {
  text: [
    "=",
    "contains",
    "beginsWith",
    "endsWith",
    "doesNotContain",
    "doesNotBeginWith",
    "doesNotEndWith",
    "in",
    "notIn",
  ],
  date: ["<=", ">=", "between", "null", "notNull"],
};

export const filterFields = ({
  tags,
  groups,
  users,
  translate,
  inboundCampaignUrlOptions,
  GROUPPED_STATUS,
}) => {
  let localizedDefaultOperators = defaultOperators.map((operator) => ({
    ...operator,
    label: translate(snakeCase(operator.label)),
  }));

  return [
    {
      name: "firstName",
      label: translate("first_name"),
      placeholder: "Enter first name",
    },
    {
      name: "lastName",
      label: translate("last_name"),
      placeholder: "Enter last name",
    },
    {
      name: "status",
      label: translate("status"),
      valueEditorType: "multiselect",
      values: GROUPPED_STATUS,
      defaultValue: "New - Unattended",
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
      optionsGrouped: true,
    },
    {
      name: "comments.name",
      label: translate("commented_by"),
      valueEditorType: "multiselect",
      values: users.map((user) => ({ value: user.name, label: user.name })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn" || op.name === "null"
      ),
    },
    {
      name: "groups",
      label: translate("groups"),
      valueEditorType: "multiselect",
      values: groups.map((group) => ({ value: group._id, label: group.name })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn" || op.name === "null"
      ),
    },
    {
      name: "salesperson",
      label: translate("sales_person"),
      valueEditorType: "multiselect",
      values: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: SALES_ROLE,
        translate,
      }),
      operators: localizedDefaultOperators.filter(personOperators),
    },

    {
      name: "financialStrategist",
      label: translate("financial_strategist"),
      valueEditorType: "multiselect",
      values: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: FINANCIAL_STRATEGIST_ROLE,
        translate,
      }),
      operators: localizedDefaultOperators.filter(personOperators),
    },
    {
      name: "realEstateAnalyst",
      label: translate("real_estate_analyst"),
      valueEditorType: "multiselect",
      values: GROUPPED_USER_BASED_ON_ROLE({
        users,
        priotize: REAL_ESTATE_ANALYST_ROLE,
        translate,
      }),
      operators: localizedDefaultOperators.filter(personOperators),
    },
    {
      name: "followupDate",
      label: translate("followup_date"),
      inputType: "date",
    },
    {
      name: "phoneNumbers",
      label: translate("phone_numbers"),
      inputType: "text",
    },
    {
      name: "emails",
      label: translate("emails"),
    },
    {
      name: "tags",
      label: translate("tags"),
      values: tags?.map((tag) => ({ value: tag.name, label: tag.name })),
      valueEditorType: "multiselect",
      defaultValue: tags?.[0]?.name,
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "createdAt",
      label: translate("added_on"),
      inputType: "date",
    },
    {
      name: "lastInboundDate",
      label: translate("last_inbound_date"),
      inputType: "date",
    },
    {
      name: "lastInboundSourceType",
      label: translate("last_inbound_source_type"),
      valueEditorType: "multiselect",
      values: inboundSourceOptions(translate),
      defaultValue: INBOUND_SOURCE[0],
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "lastInboundSourceCampaignUrl",
      label: translate("last_inbound_source_campaign_url"),
      valueEditorType: "multiselect",
      values: inboundCampaignUrlOptions.map((data) => ({
        value: data,
        label: data,
      })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "inboundDate",
      label: translate("inbound_date"),
      inputType: "date",
    },
    {
      name: "inboundSourceType",
      label: translate("inbound_source_type"),
      valueEditorType: "multiselect",
      values: inboundSourceOptions(translate),
      defaultValue: INBOUND_SOURCE[0],
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "inboundSourceCampaignUrl",
      label: translate("inbound_source_campaign_url"),
      valueEditorType: "multiselect",
      values: inboundCampaignUrlOptions.map((data) => ({
        value: data,
        label: data,
      })),
      operators: localizedDefaultOperators.filter(
        (op) => op.name === "in" || op.name === "notIn"
      ),
    },
    {
      name: "spouseFirstName",
      label: translate("spouse_first_name"),
      placeholder: "Enter spouse first name",
      validator,
    },
    {
      name: "spouseLastName",
      label: translate("spouse_last_name"),
      placeholder: "Enter spouse last name",
      validator,
    },
  ].map((option) => {
    if (!option.operators) {
      option.operators = localizedDefaultOperators.filter(({ value }) =>
        operators[option.inputType || "text"].includes(value)
      );
    }
    return toFullOption(option);
  });
};
