import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import UserAndDateRangePicker from "../common/UserAndDateRangePicker";
import moment from "moment";
import { getDashboardNewMeetingColumns } from "../../helpers/dataSheetConstants";
import { availabilityService } from "../../services/availabilityService";
import { ABORT_ERROR } from "../../helpers/api";
import { toast } from "react-toastify";
import {
  mergeAppAndGoogleAppointments,
  mergeArrays,
} from "../../helpers/global";
import DataTable from "../common/data-table/DataTable";
import useAppChoices from "../../hooks/useAppChoices";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import useApointmetnCalendar from "../../hooks/useApointmetnCalendar";
import { DEFAULT_PAGE_SIZE } from "../../helpers/constants";
import useAuth from "../../hooks/useAuth";
import useLocalization from "../../hooks/useLocalization";

const defaultSortOption = { key: "startDateTime", order: "desc" };

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};
const defaultDateRange = {
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("month").toDate(),
  key: "selection",
  rangeType: "Month",
};

const Meetings = ({}) => {
  const abortControllerRef = useRef(null);
  const appointmentTypes = useAppChoices("appointmentDefaults");
  const users = useAppChoices("users");
  const { user } = useAuth();
  const { screenHeight } = useScreenWidth();
  const { openApointmentCalendar } = useApointmetnCalendar();

  const [selectedUser, setSelectedUser] = useState([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [appointments, setAppointments] = useState([]);
  const [tableFilterValues, setTableFilterValues] = useState([]);
  const [tableSortOption, setTableSortOption] = useState(defaultSortOption);
  const [fetchingFirstPageLogs, setFetchingFirstPageLogs] = useState(false);
  const [fetchingMoreMeetings, setFetchingMoreMeetings] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();
  const { translate } = useLocalization();

  const newMeetingColumns = useMemo(
    () => getDashboardNewMeetingColumns({ appointmentTypes, users }),
    [appointmentTypes, users]
  );

  function handleOnDateChange(ranges) {
    setDateRange(ranges?.selection);
  }

  const fetchAppointments = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch } = toLoadPageInfo;
    const loadingMoreApps = pageToFetch > 1;

    if (loadingMoreApps) {
      setFetchingMoreMeetings(true);
    } else {
      setFetchingFirstPageLogs(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
      tableSort: tableSortOption,
      tableFilters: tableFilterValues,
      returnGoogleEvents: false,
      filter: {
        participants: selectedUser?.length ? selectedUser : undefined,
        startDateTime: dateRange?.startDate || undefined,
        endDateTime: dateRange?.endDate || undefined,
      },
    };

    const { response, error } = await availabilityService.search(
      requestBody,
      signal
    );
    if (error === ABORT_ERROR) return;

    setFetchingMoreMeetings(false);
    setFetchingFirstPageLogs(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [], resultCount } = response;

    setLoadedPageInfo({ totalPages, pageNumber, resultCount });

    const mergedResponse = mergeAppAndGoogleAppointments(results);

    setAppointments((prevAppointments) => {
      const newArray = loadingMoreApps
        ? [...prevAppointments, ...mergedResponse]
        : mergedResponse;

      return newArray;
    });
  };

  const loadMoreData = () => {
    if (!loadedPageInfo || fetchingFirstPageLogs || fetchingMoreMeetings)
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
      isNotInitialFetch: true,
    });
  };

  const onAppointmentClick = (appointment) => {
    openApointmentCalendar({
      apointmentId: appointment._id,
      newTab: false,
    });
  };

  useEffect(() => {
    fetchAppointments();
  }, [toLoadPageInfo]);

  useDebouncedEffect(
    () => {
      setToLoadPageInfo({ ...initialPageInfo, scrollHeight: 0 });
    },
    [selectedUser, dateRange, tableFilterValues, tableSortOption],
    200
  );

  useEffect(() => {
    if (!selectedUser?.length && user) setSelectedUser([user._id]);
  }, [user]);

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
        <h6 className="">
          {translate("meetings")} ({loadedPageInfo?.resultCount || 0})
        </h6>
        <UserAndDateRangePicker
          dateRange={dateRange}
          onDateRangeChange={handleOnDateChange}
          selectedUser={selectedUser}
          onUserChange={(users) => setSelectedUser(users)}
          hideProductFilter
        />
      </div>
      <DataTable
        rowKey={"_id"}
        maxTableHeight={screenHeight - 120}
        sortOptions={tableSortOption}
        onSortChange={setTableSortOption}
        filterValues={tableFilterValues}
        onFilterValuesChange={setTableFilterValues}
        bottomOffset={300}
        onBottomReached={loadMoreData}
        loadingFirstPageData={fetchingFirstPageLogs}
        loadingMoreData={fetchingMoreMeetings}
        columns={newMeetingColumns}
        data={appointments}
        onRowClick={onAppointmentClick}
      />
    </div>
  );
};

export default Meetings;
