import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Calendar4Week, Cart3 } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../../helpers/constants";
import { getProductionDashboardProductsTable } from "../../../helpers/dataSheetConstants";
import { getDashboardLineCartData } from "../../../helpers/global";
import useContactAndDealPopup from "../../../hooks/useContactAndDealPopup";
import useLocalization from "../../../hooks/useLocalization";
import { dashboardService } from "../../../services/dashboardService";
import DashboardDataNavigator from "../../common/DashboardDataNavigator";
import DataTable from "../../common/data-table/DataTable";
import LineChart from "../../common/graph/LineChart";
import Loader from "../../common/Loader";
import StatCard from "../StatCard";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const ProductionStatBoard = ({
  rangeType,
  dateRangeLabel,
  handleNavigateDate,
  dateRange,
  selectedUser,
  products,
}) => {
  const productsAbortControllerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const { openDealPopup, openContactPopup } = useContactAndDealPopup();
  const { translate } = useLocalization();
  const [data, setData] = useState(null);
  const [productsTableData, setProductsTableData] = useState([]);
  const [activeStat, setActiveStat] = useState(null);
  const [initialFetching, setInitialFetching] = useState(false);
  const [navigatingData, setNavigatingData] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);

  const [fetchingFirstPageMeetings, setFetchingFirstPageProducts] =
    useState(false);
  const [fetchingMoreProducts, setFetchingMoreProducts] = useState(false);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(null);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const productionStats = useMemo(() => {
    return [
      {
        name: "Product Finished Production",
        data: data?.productFinishedProduction,
        variant: "primary",
        icon: Calendar4Week,
        chartData: getDashboardLineCartData(
          data?.productFinishedProduction?.chartData,
          "Product Finished Production",
          data?.productFinishedProduction?.chartInterval
        ),
      },
      {
        name: "Product In Production",
        data: data?.productInProduction,
        variant: "secondary",
        icon: Cart3,
        chartData: getDashboardLineCartData(
          data?.productInProduction?.chartData,
          "Product In Production",
          data?.productInProduction?.chartInterval
        ),
      },
    ];
  }, [data]);

  const fetchProductionStats = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;
    if (isNavigation) {
      setNavigatingData(true);
    } else {
      setInitialFetching(true);
    }
    try {
      const reqBody = {
        dateFilter: dateRange,
        productsFilter: products,
        salesperson: selectedUser,
      };
      let { response, error } = await dashboardService.getProductionStats(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;
      if (response) {
        setData(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    } finally {
      if (controller === abortControllerRef.current) {
        setInitialFetching(false);
        setNavigatingData(false);
        setIsNavigation(false);
        abortControllerRef.current = null;
      }
    }
  };

  const fetchProductionProductsData = async () => {
    if (!toLoadPageInfo) {
      return;
    }
    // If an old API call is in progress, abort it
    if (productsAbortControllerRef.current) {
      productsAbortControllerRef.current.abort();
    }

    const { pageNumber: pageToFetch } = toLoadPageInfo;
    const loadingMoreProducts = pageToFetch > 1;

    if (loadingMoreProducts) {
      setFetchingMoreProducts(true);
    } else {
      setFetchingFirstPageProducts(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    productsAbortControllerRef.current = controller;
    const { startDate, endDate } = dateRange;
    try {
      const reqBody = {
        startDate,
        endDate,
        productsFilter: products,
        salesperson: selectedUser,
        productionStageCompleted:
          activeStat.name === "Product Finished Production",
        pageSize: toLoadPageInfo?.pageSize || DEFAULT_PAGE_SIZE,
        pageNumber: pageToFetch,
      };
      let { response, error } = await dashboardService.getProductionProducts(
        reqBody,
        signal
      );
      if (error === ABORT_ERROR) return;

      setFetchingMoreProducts(false);
      setFetchingFirstPageProducts(false);

      if (error) {
        return toast.error(error);
      }

      const { pageNumber, totalPages, results = [], resultCount } = response;

      setLoadedPageInfo({ totalPages, pageNumber, resultCount });

      setProductsTableData((prev) => {
        const newArray = loadingMoreProducts ? [...prev, ...results] : results;
        return newArray;
      });
    } catch (error) {
      console.error("Error fetching marketing stats:", error);
    }
  };

  useEffect(() => {
    fetchProductionProductsData();
  }, [toLoadPageInfo]);

  useEffect(() => {
    fetchProductionStats();
  }, [dateRange, selectedUser, products]);

  useEffect(() => {
    setProductsTableData([]);
    setToLoadPageInfo(() => ({ ...initialPageInfo }));
  }, [activeStat, dateRange, selectedUser, products]);

  const loadMoreLeadsData = () => {
    if (
      !productsTableData.length ||
      !loadedPageInfo ||
      fetchingFirstPageMeetings ||
      fetchingMoreProducts ||
      loadedPageInfo.pageNumber >= loadedPageInfo.totalPages
    ) {
      return;
    }

    setToLoadPageInfo((prev) => ({
      ...prev,
      pageNumber: loadedPageInfo.pageNumber + 1,
    }));
  };

  const onContactClick = (contact) => {
    openContactPopup(contact?._id);
  };

  const productTableColumns = useMemo(
    () =>
      getProductionDashboardProductsTable({
        translate,
        onContactClick,
        activeStat,
      }),
    [activeStat]
  );

  const handleCardClick = (stat) => {
    setActiveStat(stat);
  };

  const navigateDates = (type, dir) => {
    setIsNavigation(true);
    handleNavigateDate(type, dir);
  };

  useEffect(() => {
    setActiveStat(
      !activeStat
        ? productionStats?.[0]
        : productionStats?.find((s) => s?.name === activeStat?.name)
    );
  }, [productionStats]);

  return (
    <div className="d-flex flex-column h-100">
      <Row className="my-2 mx-1">
        {productionStats?.map((stat, index) => (
          <Col className="p-1" key={index} lg={4} md={4} sm={4} xs={6}>
            <StatCard
              stat={stat}
              active={activeStat?.name === stat?.name}
              fetching={initialFetching}
              onClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>
      <Row className="m-0">
        {activeStat?.chartData && (
          <Col className="" lg={12} md={12} sm={12} xs={12}>
            {" "}
            <LineChart
              chartData={activeStat?.chartData}
              chartName={activeStat?.name}
              loading={initialFetching}
              randerExtraInfo={() => (
                <DashboardDataNavigator
                  rangeType={rangeType}
                  dateRangeLabel={dateRangeLabel}
                  handleNavigateDate={navigateDates}
                  loading={navigatingData}
                />
              )}
              hideContiniousFalsyDataFromLast
            />
          </Col>
        )}{" "}
        <Col className="" lg={12} md={12} sm={12} xs={12}>
          <div className="custom-card mt-2 px-2">
            {initialFetching ? (
              <Loader />
            ) : (
              <>
                <h6 className="xxlarge fw-bold">Products</h6>
                <DataTable
                  columns={productTableColumns}
                  data={productsTableData}
                  maxTableHeight={`500px`}
                  rowKey={"_id"}
                  onRowClick={(product) =>
                    openDealPopup(product?.contract?._id)
                  }
                  loadingFirstPageData={fetchingFirstPageMeetings}
                  loadingMoreData={fetchingMoreProducts}
                  onBottomReached={loadMoreLeadsData}
                  allowSort={false}
                  allowFilter={false}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProductionStatBoard;
