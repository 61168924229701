import React from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import useLocalization from "../../hooks/useLocalization";

const TimeIntervalInWeek = ({
  className,
  disabled,
  initialDate,
  onChange,
  style,
}) => {
  const { translate } = useLocalization();

  function getDateWithInterval(date, interval) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const threeDays = 3 * oneDay; // milliseconds in a week
    const threeWeeks = 21 * oneDay; // assuming a month is 30 days

    // Convert inputDate to a JavaScript Date object if it's not already
    const currentDate = new Date(date);

    // Calculate the new date based on the interval
    switch (interval) {
      case "1Day":
        return new Date(currentDate.getTime() + oneDay);
      case "3Days":
        return new Date(currentDate.getTime() + threeDays);
      case "3Weeks":
        return new Date(currentDate.getTime() + threeWeeks);

      default:
        return new Date();
    }
  }

  const onIntervalChange = (interval) => {
    if (disabled) return;
    const newDate = getDateWithInterval(initialDate, interval);
    onChange(newDate);
  };

  return (
    <div
      className={`d-flex align-items-center  hover ${className}`}
      style={style}
    >
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange("1Day")}
      >
        <Plus size={15} />{" "}
        <span className="tiny mb-0">{translate("one_day")}</span>
      </Button>
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange("3Days")}
      >
        <Plus size={15} />{" "}
        <span className="tiny mb-0">{translate("three_days")}</span>
      </Button>
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange("3Weeks")}
      >
        <Plus size={15} />{" "}
        <span className="tiny mb-0">{translate("three_weeks")}</span>
      </Button>
    </div>
  );
};

export default TimeIntervalInWeek;
