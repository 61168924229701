import React, { useMemo, useRef, useState, useEffect } from "react";
import { getPhoneCallTableColumns } from "../../../helpers/dataSheetConstants";
import DataTable from "../../common/data-table/DataTable";
import CallDetailModal from "./CallDetailModal";
import { useScreenWidth } from "../../../hooks/useScreenWidth";

const PhoneCalls = ({ contact = [], columnToHide }) => {
  const containerRef = useRef();
  const { screenWidth } = useScreenWidth();
  const { phoneCalls } = contact;
  const [callDetailModalMeta, setCallDetailModalMeta] = useState(null);
  const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });

  const onPlayClick = (callInfo) => {
    setCallDetailModalMeta(callInfo);
  };

  const tableColumns = useMemo(
    () => getPhoneCallTableColumns(onPlayClick, columnToHide),
    [onPlayClick, columnToHide]
  );

  useEffect(() => {
    if (containerRef.current) {
      const { x, y } = containerRef.current.getBoundingClientRect();
      setContainerPosition({ x, y });
    }
  }, [containerRef]);

  const offSet = useMemo(
    () => ({
      x: screenWidth < 800 ? 10 : screenWidth,
      y: containerPosition.y - 50,
      xOffSet: 10,
      yOffSet: 10,
    }),
    [screenWidth, containerPosition]
  );

  return (
    <div
      ref={containerRef}
      className=""
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <DataTable
        maxTableHeight={`100vh`}
        rowKey={"_id"}
        columns={tableColumns}
        data={phoneCalls}
        allowSort={false}
        allowFilter={false}
      />
      {callDetailModalMeta && (
        <CallDetailModal
          show={Boolean(callDetailModalMeta)}
          onHide={() => setCallDetailModalMeta(null)}
          callInfo={callDetailModalMeta}
          offSet={offSet}
        />
      )}
    </div>
  );
};

export default PhoneCalls;
