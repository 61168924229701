import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import SendWhatsappCampaignForm from "../forms/SendWhatsappCampaignForm";
import AppModal from "./AppModal";
import Loader from "./Loader";
import useAppChoices from "../../hooks/useAppChoices";
import useLocalization from "../../hooks/useLocalization";

const CreateWhatsappCampaignModal = ({
  show,
  onHide,
  showProgress,
  onSubmit,
  allowOnSignUp = false,
  loading = false,
  editableSource = false,
  events = [],
  automations = [],
  inboundSource = [],
  initialValues,
  editMode,
}) => {
  const whatsappTemplates = useAppChoices("whatsappTemplates");
  const { translate } = useLocalization();

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={translate(
        editMode ? "update_whatsapp_campaign" : "create_whatsapp_campaign"
      )}
      modalBodyClassName="p-1"
    >
      <div className="px-2 py-1">
        {loading ? (
          <Loader />
        ) : (
          <SendWhatsappCampaignForm
            editableSource={editableSource}
            showProgress={showProgress}
            whatsappTemplates={whatsappTemplates}
            allowOnSignUp={allowOnSignUp}
            onSubmit={onSubmit}
            events={events}
            automations={automations}
            inboundSource={inboundSource}
            initialValues={initialValues}
            submitButtonText={translate(editMode ? "update" : "create")}
          />
        )}
      </div>
    </AppModal>
  );
};

export default CreateWhatsappCampaignModal;
